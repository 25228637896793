import styled, { css } from "styled-components";
import React, {
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  useContext
} from "react";
import { Color, Size } from "../types";
import { AppContext } from "./AppContext";
import Loader from "./Loader";

export enum ButtonType {
  button = "button",
  link = "link"
}

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  block?: boolean;
  color?: Color;
  isLoading?: boolean;
  outline?: boolean;
  disabled?: boolean;
  shadow?: boolean;
  size?: Size;
  type?: ButtonType | "submit";
}

export const StyledButton = styled.button<
  ButtonProps & { $mobileSize?: boolean; $type?: ButtonType | "submit" }
>`
  align-items: center;
  background: var(--${(props) => props.color});
  border: none;
  border-radius: 0.25rem;
  color: var(--white);
  cursor: pointer;
  display: flex;
  // font-family: "Source Sans 3", Impact, "Franklin Gothic Bold", sans-serif;
  font-weight: 500;
  height: max-content;
  justify-content: center;
  // text-transform: uppercase;

  &:hover {
    opacity: 0.9;
  }

  @media (max-width: 800px) {
    // Whatever was using this width should create a targetted style
    // width: 300px;
  }

  ${(props) =>
    props.outline &&
    css`
      background-color: transparent;
      border: 1px solid var(--${props.color});
      color: var(--${props.color});
    `};

  ${(props) =>
    props.size === Size.sm &&
    css`
      border-radius: 2px;
      font-size: 0.75rem;
      line-height: 1.25rem;
      padding: 0.5rem 0.75rem;
    `}

  ${(props) =>
    props.size === Size.md &&
    css`
      font-size: 1rem;
      line-height: 1.5rem;
      padding: 0.75rem 1rem;
    `}
  
  ${(props) =>
    props.size === Size.lg &&
    css`
      font-size: 1.25rem;
      line-height: 1.75rem;
      padding: 1rem 1.25rem;
    `}

  ${(props) =>
    props.shadow &&
    css`
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
      -webkit-appearance: none;
    `};

  ${(props) =>
    props.block &&
    !props.$mobileSize &&
    css`
      padding-left: 6rem;
      padding-right: 6rem;
    `}

  ${(props) =>
    props.$type === ButtonType.link &&
    css`
      align-items: center;
      border-radius: 0.25rem;
      background-color: transparent;
      color: var(--primary);
      display: flex;
      font-weight: 500;
      text-decoration: none;
      text-transform: capitalize;

      &:hover {
        color: var(--white);
        background-color: var(--quinary);
        // text-decoration: underline;
      }
    `};

  ${(props) =>
    props.disabled &&
    css`
      background-color: var(--gray);
      cursor: no-drop;
    `};
`;

const Button = forwardRef(
  (
    { children, disabled, isLoading, type, onClick, ...props }: ButtonProps,
    ref: ForwardedRef<any>
  ) => {
    const { mobileSize } = useContext(AppContext);

    const handleClick = (e: any) => {
      if (!disabled && onClick) {
        onClick(e);
      }
    };

    return (
      <StyledButton
        {...props}
        ref={ref}
        disabled={disabled || isLoading}
        onClick={handleClick}
        $type={type}
        type={type === "submit" ? type : undefined}
        $mobileSize={mobileSize}
      >
        {isLoading ? <Loader /> : children}
      </StyledButton>
    );
  }
);

Button.displayName = "Button";
Button.defaultProps = {
  color: Color.quinary,
  size: Size.md
};

export default Button;
