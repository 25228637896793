/* eslint-disable camelcase */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import styled, { css, createGlobalStyle } from "styled-components";
import { v4 as uuidV4 } from "uuid";
import { Auth } from "aws-amplify";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify/dist/react-toastify.umd";

import {
  GoogleMap,
  Marker,
  useLoadScript,
  InfoWindow
} from "@react-google-maps/api";
import { PopupButton } from "@typeform/embed-react";
import { Helmet } from "react-helmet"; // Add this import
import useSWR from "swr";
import Tooltip from "../../components/Tooltip"; // Add this import
import Button, { StyledButton } from "../../components/Button";
import AnalyticsModal from "../Analytics/components/AnalyticsModal";
import { AppContext } from "../../components/AppContext";
import Flex from "../../components/Flex";
import InlineInput from "../../components/InlineInput";
import Metric, { StyledMetric } from "../../components/Metric";
import Image from "../../components/Image";
import Section from "../../components/Section";
import { StyledSelect } from "../../components/Select";
import Text from "../../components/Text";
import { Color } from "../../types";
import StackedBarChart from "../../components/charts/StackedBarChart";
import Arrows from "../../components/Arrows";
import useCookies from "../../hooks/useCookies";
import Row from "../../components/Row";
import Column from "../../components/Column";
import useLocalStorage from "../../hooks/useLocalStorage"; // Add this import
import { AnalyticsBox } from "../Analytics/components/AnalyticsBoxes";
import Header from "../../components/Header";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableLink,
  TableRow
} from "../../components/Table";
import Banner from "../../components/Banner";
import Popover, { Placement } from "../../components/Popover";
import Accordion from "../../components/Accordion";
import { currencyNotation } from "../../utils/numberFormatters";
import { Colors } from "../../components/GlobalStyle";

// Add this near the top of the file with other imports/utilities
const fetcher = async (url: string) => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

const formatter = (val: number, options = {}) =>
  new Intl.NumberFormat("en-us", {
    maximumFractionDigits: (options as any).style === "percent" ? 1 : 0,
    ...options
  }).format(val);

const PropertyImage = styled.img`
  height: 192px;
  width: 167px;
`;

const Address = styled.div`
  display: contents;
  // font-family: "Source Sans 3", Impact, "Franklin Gothic Bold", sans-serif;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.48;

  .address-1 {
    color: var(--primary);
  }
  .address-2 {
    color: var(--primary);
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const StyledMyPropertiesLink = styled(Link)`
  align-items: center;
  color: var(--primary);
  cursor: pointer;
  display: flex;
  font-weight: 500;
  font-size: 1.25rem;
  gap: 0.25rem;
  text-decoration: underline;
  margin-right: 1rem;
`;
const Chip = styled.div<{ alternate?: boolean; noBorders?: boolean }>`
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1 1 auto;
  font-size: 1rem;
  gap: 0.25rem;
  background: var(--white);
  padding: 0.5rem 0.5rem;
  // border: 1px solid var(--lightPurple);
  border-radius: 0.25rem;
  // box-shadow: 0 10px 32px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1.4rem 6rem -2rem rgba(108, 129, 171, 0.3);
  ${(props) =>
    props.alternate &&
    css`
      background-color: var(--lightPurple);
      justify-content: space-between;
      border: 1px solid var(--primary);
    `};

  ${(props) =>
    props.noBorders &&
    css`
      border: 1px solid transparent;
    `};

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 0.625rem;
  }
`;

const ChipText = styled(Text)`
  align-items: center;
  display: flex;
  flex: 1 1 50%;

  &:first-of-type {
    border-right: 1px solid var(--primary);
  }
  &:last-of-type {
    border-left: 1px solid var(--primary);
    justify-content: flex-end;
  }
`;

const ChipRow = styled(Row)`
  @media (max-width: 800px) {
    flex-direction: row;
    flex-wrap: wrap;

    & > ${Chip} {
      flex: 1 1 10%;
    }
  }
`;

const MetricRow = styled(Row)`
  @media (max-width: 800px) {
    flex-direction: row;
    flex-wrap: wrap;

    & > ${StyledMetric} {
      flex: 1 1 45%;
    }
  }
`;

const FinancingCallout = styled.div`
  background-color: var(--lightBlue);
  box-shadow: 4px 4px 32px 0px rgba(47, 53, 75, 0.1);
  border-radius: 0.25rem;
  overflow: hidden;
  display: flex;
  gap: 4rem;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  margin: 1.5rem 0;

  .firstText {
    color: var(--white);
    font-weight: 600;
  }

  .secondText {
    color: var(--black);
    font-weight: 600;
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
    padding: 0.75rem;

    .secondText {
      text-align: center;
      width: 100%;
    }
  }
`;

const StyledPopupButton = styled(PopupButton)`
  background: var(--linearGradient);
  color: var(--white);
  padding: 0.5rem 1rem;
  border: 0;
  font-weight: 700;
  width: initial;
  text-decoration: underline;
  display: initial;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  box-shadow: 0 8px 16px rgb(0 0 0 / 40%);
  border-radius: 0.25rem;
  margin: 1rem 0 2rem;

  @media (max-width: 720px) {
    width: 100%;
    display: block;
    margin: 1rem auto;
  }
`;

const SectionTitle = styled.h2<{ color?: string }>`
  color: ${(props) => props.color || "var(--primary)"};
  font-weight: 400;
  font-size: 1.375rem;
  line-height: 1.5;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
`;

const Grid = styled(Flex)`
  align-items: stretch;
  padding: 0 0;
  width: 100%;

  ${StyledButton},
  ${StyledSelect},
  ${Section} {
    button {
      &:nth-child(5) {
        background-color: var(--white);
        color: var(--primary);
      }
    }
    margin: 0.5rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  ${Section} {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1.5rem;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 2rem;

    ${StyledSelect},
    ${Section} {
      flex: 1 1 auto;
      margin: 0.5rem 0;
      button {
        &:nth-child(5) {
          background-color: var(--white);
          color: var(--primary);
        }
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0rem;

    ${StyledButton},
    ${StyledSelect},
    ${Section} {
      margin: 0.3125rem 0;
      width: 100%;
    }
  }
`;

// const PlaceholderTooltip = (
//   <>
//   <Text weight={300} style={{ display: "block", fontSize: "14px" }}>
//     This is a placeholder tooltip
//   </Text>
//   <Text weight={300} style={{ display: "block", fontSize: "14px" }}>
//     This is a placeholder tooltip
//   </Text>
//   <Text weight={300} style={{ display: "block", fontSize: "14px" }}>
//     This is a placeholder tooltip
//   </Text>
//   </>
// );

const ArrowsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  top: calc(100vh - 133px);
  right: 2rem;
`;

const InputField = styled.div`
  width: 45px;
  margin-right: 0px;
  padding: 2px;
  padding-left: 10px;
  font-size: 2rem;
  color: var(--primary);
  font-weight: 500;
`;

const StyledShareButton = styled.div`
  align-items: center;
  color: var(--primary);
  cursor: pointer;
  display: flex;
  font-weight: 500;
  font-size: 1.25rem;
  gap: 0.25rem;
  text-decoration: underline;
`;

const StyledSaveButton = styled.div`
  align-items: center;
  color: var(--sixth);
  cursor: pointer;
  display: flex;
  font-weight: 500;
  font-size: 1.25rem;
  gap: 0.25rem;
  text-decoration: underline;
`;

const ShareButton = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const copyUrl = () => {
    navigator.clipboard.writeText(window.location.href);
    setIsOpen(true);

    setTimeout(() => setIsOpen(false), 1000);
  };
  return (
    <>
      <StyledShareButton id="share-button" onClick={copyUrl}>
        Share
        <svg
          width="23"
          height="21"
          viewBox="0 0 23 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.43334 1.5C8.85676 1.5 9.20001 1.83579 9.20001 2.25C9.20001 2.61819 8.9288 2.92441 8.57115 2.98792L8.43334 3H3.83334C2.6378 3 1.65531 3.89233 1.54386 5.03331L1.53333 5.25V17.25C1.53333 18.4195 2.44549 19.3807 3.61183 19.4897L3.83334 19.5H16.1C17.2955 19.5 18.278 18.6076 18.3895 17.4667L18.4 17.25V15.75C18.4 15.3358 18.7433 15 19.1667 15C19.543 15 19.8561 15.2654 19.921 15.6152L19.9334 15.75V17.25C19.9334 19.2382 18.3516 20.8651 16.3521 20.992L16.1 21H3.83334C1.80093 21 0.137941 19.4527 0.0081538 17.4966L0 17.25V5.25C0 3.26178 1.58169 1.63494 3.5813 1.50798L3.83334 1.5H8.43334ZM14.379 0.866732C14.379 0.195054 15.0872 -0.187381 15.6307 0.0926736L15.7443 0.162961L15.8389 0.242476L22.7344 6.87425C23.0205 7.14935 23.0755 7.57271 22.8992 7.90475L22.8223 8.02452L22.7346 8.12268L15.839 14.7565C15.3556 15.2216 14.5899 14.9739 14.4152 14.3867L14.3871 14.2558L14.379 14.1324V10.9892L13.9839 11.024C13.6022 11.0639 13.2229 11.1221 12.8457 11.1986C10.4939 11.676 8.22554 12.8668 6.03001 14.7846C5.43288 15.3063 4.50903 14.8131 4.60724 14.0251C5.35283 8.04322 8.41346 4.67901 13.6292 4.12138L14.0346 4.08402L14.379 4.06136V0.866732ZM15.9123 2.41656V5.46384L14.147 5.58C11.734 5.76908 9.95495 6.59139 8.68358 7.93278C7.65774 9.01512 6.8769 10.5261 6.41967 12.5333C8.59014 10.942 10.8826 9.94481 13.3008 9.59697L13.8337 9.53104L15.9123 9.34835V12.5823L21.1965 7.49858L15.9123 2.41656Z"
            fill="var(--primary)"
          />
        </svg>
      </StyledShareButton>
      <Popover isOpen={isOpen} placement={Placement.top} target="share-button">
        Link copied to clipboard
      </Popover>
    </>
  );
};

const SaveButton = ({
  allowSave,
  alreadySaved,
  data,
  onRejectSave,
  activeUserId
}: {
  allowSave?: boolean;
  alreadySaved?: boolean;
  data: Record<string, any>;
  onRejectSave?(): void;
  activeUserId?: string | null;
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isSaved, setIsSaved] = React.useState(false);

  const saveUrl = async () => {
    if (allowSave) {
      const newSavedAnalysis = {
        userId: activeUserId,
        url: window.location.pathname + window.location.search,
        ...data
      };
      setIsOpen(true);
      setIsSaved(true);
      try {
        const response = await fetch(
          "https://zmura59afa.execute-api.us-west-2.amazonaws.com/v1/airbnb-estimate/saved-analysis",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(newSavedAnalysis)
          }
        );
        if (response.ok) {
          const result = await response.json();
        } else {
          throw new Error("Market not supported");
        }
      } catch (error) {
        console.error("Unable to save analysis:", error);
        throw error;
      }
      setTimeout(() => setIsOpen(false), 1000);
    } else {
      onRejectSave?.();
    }
  };

  React.useEffect(() => {
    setIsSaved(!!alreadySaved);
  }, [alreadySaved]);

  return (
    <>
      <StyledSaveButton
        id="save-button"
        onClick={isSaved ? undefined : saveUrl}
      >
        {isSaved ? "Analysis Saved" : "Save Analysis"}
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill={isSaved ? undefined : "none"}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath={isSaved ? undefined : "url(#clip0_2533_326)"}>
            <path
              d="M18.3846 3.63239C17.3373 2.58512 15.9508 2.01272 14.4711 2.01272C12.9913 2.01272 11.6006 2.58936 10.5533 3.63663L10.0064 4.18359L9.45092 3.62815C8.40365 2.58088 7.0087 2 5.52895 2C4.05344 2 2.66273 2.57664 1.61969 3.61967C0.57242 4.66694 -0.00421676 6.05766 2.32169e-05 7.53741C2.32169e-05 9.01716 0.5809 10.4036 1.62817 11.4509L9.59084 19.4136C9.70108 19.5238 9.84948 19.5832 9.99364 19.5832C10.1378 19.5832 10.2862 19.5281 10.3964 19.4178L18.3761 11.4679C19.4233 10.4206 20 9.02988 20 7.55013C20.0042 6.07037 19.4318 4.67966 18.3846 3.63239ZM17.5705 10.658L9.99364 18.2052L2.43377 10.6453C1.60273 9.81427 1.14482 8.71188 1.14482 7.53741C1.14482 6.36293 1.59849 5.26054 2.42953 4.43374C3.25632 3.60695 4.35872 3.14903 5.52895 3.14903C6.70342 3.14903 7.81005 3.60695 8.64109 4.43798L9.59932 5.39622C9.82404 5.62094 10.1844 5.62094 10.4092 5.39622L11.3589 4.44646C12.1899 3.61543 13.2966 3.15751 14.4668 3.15751C15.637 3.15751 16.7394 3.61543 17.5705 4.44222C18.4015 5.27326 18.8552 6.37565 18.8552 7.55013C18.8594 8.7246 18.4015 9.82699 17.5705 10.658Z"
              fill="#695979"
            />
          </g>
          <defs>
            <clipPath id="clip0_2533_326">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </StyledSaveButton>
      <Popover isOpen={isOpen} placement={Placement.top} target="save-button">
        Link copied to clipboard
      </Popover>
    </>
  );
};

const libraries: any[] = ["places"];

const zestimateFetcher = async (url: string) => {
  const res = await fetch(url, {
    headers: { Authorization: "Bearer ead6ccd69158e8d9ecb4364935fdbd6c" }
  });

  if (!res.ok) {
    const error = new Error("An error occurred while fetching the data.");
    // Attach extra info to the error object.
    (error as any).info = await res.json();
    (error as any).status = res.status;
    throw error;
  }

  return res.json();
};

// Add the map styling configuration at the top of the file
const simplifiedMapStyle = [
  {
    featureType: "road",
    elementType: "labels",
    stylers: [{ visibility: "off" }]
  },
  {
    featureType: "poi",
    stylers: [{ visibility: "off" }]
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [{ visibility: "off" }]
  },
  {
    featureType: "landscape",
    stylers: [{ color: "#FFFBF3" }]
  },
  {
    featureType: "water",
    stylers: [{ color: "#EEEFF8" }]
  }
];

// Add this new styled component near other styled components
const MapMarker = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--sixth);
  border: 2px solid white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

// Add these styled components near other styled components
const MapInfoCard = styled.div`
  background: white;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  min-width: 200px;
  cursor: pointer;

  &:hover {
    background: var(--lightPurple);
  }
`;

const InfoCardTitle = styled.div`
  font-weight: 500;
  color: var(--primary);
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
`;

const InfoCardStat = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;

  span:first-child {
    color: var(--quinary);
  }

  span:last-child {
    font-weight: 500;
  }
`;

// Add new interface for property data
interface PropertyData {
  bedrooms: number;
  fullBaths: number;
  accommodates: number;
}

const StrCalculatorBody = ({
  calcState: propCalcState,
  address: propAddress
}: {
  calcState?: any;
  address?: string;
}) => {
  const history = useHistory();
  const location = useLocation();
  const {
    mobileSize,
    user,
    setOpenContactModal,
    calcLat,
    calcLng,
    address,
    setAddress
  } = useContext(AppContext);
  const { getAllCookies, getCookie, setCookie } = useCookies();
  // Add this near the top of your component, with other state declarations
  const [hasSeenModal, setHasSeenModal] = useLocalStorage(
    "hasSeenContactModal",
    false
  );
  const [showAnalyticsModal, setShowAnalyticsModal] = useState(false);
  // Add this near the top of your component, with other state declarations
  const [userId, setUserId] = useState<string | null>(null);
  const periodRef = useRef<Record<string, any>>({});

  const [showInstructions, setShowInstructions] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showAllComps, setShowAllComps] = useState(false);
  const [unsupportedMarket, setUnsupportedMarket] = useState(false);
  const [alreadySaved, setAlreadySaved] = React.useState(false);

  const [{ average_daily_rate, revenue, occupancy_rate, revpar }, setStats] =
    useState({
      average_daily_rate: undefined,
      revenue: undefined,
      gross_yield: undefined,
      occupancy_rate: undefined,
      property_tax_rate: undefined,
      revpar: undefined
    });

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_PLACES_API_KEY || "",
    libraries
  });

  // Move the selectedMarker state inside the component
  const [selectedMarker, setSelectedMarker] = useState<any>(null);

  const onRejectSave = () => {
    setShowAnalyticsModal(true);
  };

  const toggleShowAllComps = () => {
    setShowAllComps(!showAllComps);
  };

  const handleAnalyticsModal = () => {
    if (!hasSeenModal) {
      setShowAnalyticsModal(true);
      setHasSeenModal(true);
    }
  };

  const [
    {
      downPayment,
      closingCosts,
      renovationCosts,
      furnishingCosts,
      purchasePrice,
      interestRate,
      homeAppreciation,
      mortgageTerm,
      occupancyRate,
      netAdr,
      revenueGrowth,
      grossRevenue1,
      otaFee,
      PMI,
      lodgingTax,
      propertyManagement,
      maintenance,
      cleaningFees,
      strLicensing,
      homeownersInsurance,
      propertyTax,
      annualUtilities,
      hoa
    },
    setProfitLoss
  ] = useState<any>({
    downPayment: 100000,
    closingCosts: 0.01,
    renovationCosts: 20000,
    purchasePrice: 500000,
    interestRate: 0.07,
    homeAppreciation: 0.05,
    mortgageTerm: 30,
    furnishingCosts: 20000,
    occupancyRate: 0.5,
    netAdr: 300,
    revenueGrowth: 0.015,
    grossRevenue1: 0.5 * 350 * 365,
    otaFee: 0.03,
    PMI: 0,
    lodgingTax: 0,
    propertyManagement: 0.1,
    maintenance: 0.01,
    cleaningFees: 0.0,
    strLicensing: 200,
    homeownersInsurance: 2500, // 0.5% of initial purchasePrice
    propertyTax: 0.01, // 1% of purchasePrice
    annualUtilities: 3750, // 0.75% of initial purchasePrice
    hoa: 0.0
  });

  const [{ beds, baths, accommodates, price }, setCalcState] = useState<any>(
    propCalcState || {
      beds: 3,
      baths: 2,
      accommodates: 8,
      price: 500000
    }
  );

  const [comps, setComps] = useState([]);
  const visibleComps = showAllComps ? comps : comps.slice(0, 5);

  // Add new state for property details
  const [propertyData, setPropertyData] = useState<PropertyData>({
    bedrooms: 3,
    fullBaths: 2,
    accommodates: 8
  });

  // Existing zestimates API call
  const { data: zestimates, error: zestimateError } = useSWR<
    Record<string, any>
  >(
    address
      ? [
          "zestimates",
          address,
          `https://api.bridgedataoutput.com/api/v2/zestimates_v2/zestimates?address=${encodeURIComponent(
            address.replace(", USA", "")
          )}&limit=1`
        ]
      : null,
    ([_, __, url]: [string, string, string]) => zestimateFetcher(url),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 0
    }
  );

  // New property details API call
  const { data: propertyDetails, error: propertyError } = useSWR<
    Record<string, any>
  >(
    address
      ? [
          "parcels",
          address,
          `https://api.bridgedataoutput.com/api/v2/pub/parcels?address=${encodeURIComponent(
            address.replace(", USA", "")
          )}&access_token=8bbcfd9d7429caa51274a8a90bcdc22d`
        ]
      : null,
    ([_, __, url]: [string, string, string]) => fetcher(url),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 0,
      onSuccess: (data) => {
        if (data?.bundle?.[0]?.building?.[0]) {
          const buildingData = data.bundle[0].building[0];
          setPropertyData({
            bedrooms: buildingData.bedrooms || 3,
            fullBaths: buildingData.fullBaths || 2,
            accommodates: buildingData.bedrooms * 2 + 2
          });

          // Update calcState with the new values
          setCalcState((prevState: any) => ({
            ...prevState,
            beds: buildingData.bedrooms || prevState.beds,
            baths: buildingData.fullBaths || prevState.baths,
            accommodates: buildingData.bedrooms * 2 + 2
          }));
        }
      }
    }
  );

  const zestimate = React.useMemo(
    () => zestimates?.bundle?.[0]?.zestimate,
    [zestimates]
  );

  useEffect(() => {
    if (zestimate) {
      setProfitLoss((prevState: any) => ({
        ...prevState,
        purchasePrice: zestimate,
        downPayment: zestimate * 0.2, // Update down payment to 20% of new price
        homeownersInsurance: zestimate * 0.005, // Update to 0.5% of new price
        propertyTax: 0.01, // Keep at 1% (this is already a percentage)
        annualUtilities: zestimate * 0.0075 // Update to 0.75% of new price
      }));
    }
  }, [zestimate]);

  useEffect(() => {
    if (zestimateError) {
      // Send toast to user on error
      toast(zestimateError.info.bundle.message, { type: "error" });
    }
  }, [zestimateError]);

  useEffect(() => {
    if (propAddress) {
      setAddress(propAddress);
      const saved = localStorage.getItem("saved-analysis");
      let savedAnalysis = [];

      if (saved && saved !== "undefined") {
        savedAnalysis = JSON.parse(saved);
      }

      const isAnalysisSaved = !!savedAnalysis.find(
        (analysis: any) =>
          analysis.url === window.location.pathname + window.location.search
      );
      setAlreadySaved(isAnalysisSaved);
    }
  }, [propAddress]);

  const getPrediction = () => {
    const cookies: Record<string, string> = {};
    getAllCookies().forEach(([name, val]) => {
      cookies[name] = val;
    });

    if (showInstructions) {
      setShowInstructions(false);
    }

    const fetchPrediction = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          "https://zmura59afa.execute-api.us-west-2.amazonaws.com/v1/airbnb-estimate",
          {
            method: "POST",
            body: JSON.stringify({
              latitude: calcLat,
              longitude: calcLng,
              bedrooms: propertyData.bedrooms || beds,
              bathrooms: propertyData.fullBaths || baths,
              address,
              userId,
              ...cookies
            })
          }
        );

        if (response.ok) {
          const prediction = await response.json();
          setLoading(false);
          setStats({
            ...prediction.averages.annual_revenue,
            revenue: prediction.averages.avg_annual_revenue,
            average_daily_rate: prediction.averages.avg_daily_rate,
            occupancy_rate: prediction.averages.avg_occupancy_rate,
            revpar:
              prediction.averages.avg_daily_rate *
              (0.01 * prediction.averages.avg_occupancy_rate)
          });
          setComps(prediction.comps);
        } else {
          setLoading(false);
          setUnsupportedMarket(true);
        }
      } catch (error) {
        console.error("Error fetching prediction:", error);
        setLoading(false);
        setUnsupportedMarket(true);
      }
    };

    fetchPrediction();
  };

  const loanAmount = useMemo(
    () => purchasePrice - downPayment,
    [purchasePrice, downPayment]
  );

  const mortgagePayment = useMemo(() => {
    const monthlyInterestRate = interestRate / 12;
    return (
      loanAmount *
      ((monthlyInterestRate * (1 + monthlyInterestRate) ** 360) /
        ((1 + monthlyInterestRate) ** 360 - 1))
    );
  }, [loanAmount, interestRate]);

  const grossRevenue5 = useMemo(
    () => grossRevenue1 * (1 + revenueGrowth) ** 4,
    [grossRevenue1, revenueGrowth]
  );
  const handleJoinAIWaitlistSubmit = async (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(email)) {
      alert("Please use a correct email address!");
      return;
    }

    try {
      const requestOptions = {
        method: "POST",
        body: JSON.stringify({
          email,
          sourcePage: `${window.location.pathname}`
        })
      };
      await fetch(
        "https://cbehz6zbjl.execute-api.us-west-2.amazonaws.com/v1/contacts",
        requestOptions
      );
      alert("Thank you for subscribing!");
    } catch (error) {
      console.error("Error submitting email:", error);
      alert("An error occurred. Please try again later.");
    }
  };
  const calculateLoanDataAfterMonth = (month: number) => {
    let remainingLoanAmount = loanAmount;
    let totalPrincipalPaid = 0;
    let totalInterestPaid = 0;
    for (let i = 0; i < month; i++) {
      const interestPaid = (remainingLoanAmount * interestRate) / 12;
      const principalPaid = mortgagePayment - interestPaid;
      remainingLoanAmount -= principalPaid;
      totalPrincipalPaid += principalPaid;
      totalInterestPaid += interestPaid;
    }

    return {
      remainingLoanAmount,
      totalPrincipalPaid,
      totalInterestPaid
    };
  };

  const feesFromRevenue = useCallback(
    (revenueLocal: number) =>
      propertyManagement * revenueLocal +
      lodgingTax * revenueLocal +
      cleaningFees * revenueLocal +
      otaFee * revenueLocal,
    [propertyManagement, lodgingTax, otaFee, grossRevenue1]
  );
  const costsOfOwnership1 = useMemo(
    () => (hoa + maintenance + propertyTax) * purchasePrice,
    [hoa, maintenance, propertyTax, purchasePrice]
  );
  const costsOfOwnership5 = useMemo(
    () => (hoa + maintenance + propertyTax) * purchasePrice,
    [hoa, maintenance, propertyTax, purchasePrice]
  );
  const yearlyPayments = useMemo(() => mortgagePayment * 12, [mortgagePayment]);

  const yearlyPMI = useMemo(() => PMI * 12, [PMI]);

  const otherGrossRevenues = useCallback(
    (year: number) => grossRevenue1 * (1 + revenueGrowth) ** year,
    [grossRevenue1, revenueGrowth]
  );

  const netIncome1 = useMemo(
    () =>
      grossRevenue1 -
      (feesFromRevenue(grossRevenue1) +
        costsOfOwnership1 +
        yearlyPayments +
        yearlyPMI +
        homeownersInsurance +
        annualUtilities +
        strLicensing),
    [
      feesFromRevenue,
      grossRevenue1,
      costsOfOwnership1,
      yearlyPayments,
      yearlyPMI,
      homeownersInsurance,
      annualUtilities,
      strLicensing
    ]
  );

  const netIncomeAfter1 = useCallback(
    (revenueLocal) =>
      revenueLocal -
      (feesFromRevenue(revenueLocal) +
        costsOfOwnership5 +
        yearlyPayments +
        yearlyPMI +
        homeownersInsurance +
        annualUtilities +
        strLicensing),
    [
      feesFromRevenue,
      grossRevenue5,
      costsOfOwnership5,
      yearlyPayments,
      yearlyPMI,
      homeownersInsurance,
      annualUtilities,
      strLicensing
    ]
  );

  const netOperatingIncome = useMemo(
    () =>
      grossRevenue1 -
      (feesFromRevenue(grossRevenue1) +
        hoa * purchasePrice +
        (maintenance + propertyTax) * purchasePrice +
        homeownersInsurance +
        annualUtilities +
        strLicensing),
    [
      feesFromRevenue,
      grossRevenue1,
      hoa,
      maintenance,
      purchasePrice,
      homeownersInsurance,
      annualUtilities,
      strLicensing
    ]
  );

  const capRate = useMemo(
    () => netOperatingIncome / purchasePrice,
    [netOperatingIncome, purchasePrice]
  );

  const cashFlowPotential = useMemo(() => {
    if (capRate >= 0.1) return { text: "HIGH", color: "#4CAF50" }; // Green
    if (capRate >= 0.05) return { text: "MEDIUM", color: "#FFA000" }; // Amber
    return { text: "LOW", color: "#F44336" }; // Red
  }, [capRate]);

  const barChartData = React.useMemo(() => {
    return comps.reduce((acc: Record<string, number>, comp: any) => {
      const label = `Comp ${Object.keys(acc).length + 1}`;
      acc[label] = comp.annual_revenue;
      return acc;
    }, {});
  }, [comps]);

  const appreciationAfterYear = (year: number) =>
    (purchasePrice + renovationCosts) * (1 + homeAppreciation) ** year -
    purchasePrice;

  const investmentValueAfterYear = (year: number) =>
    appreciationAfterYear(year) +
    new Array(year)
      .fill(false)
      .map((discard, idx) => {
        if (idx === 0) {
          return netIncome1;
        }
        return netIncomeAfter1(otherGrossRevenues(idx + 1));
      })
      .reduce((prev, curr) => prev + curr, 0) +
    downPayment +
    calculateLoanDataAfterMonth(year * 12).totalPrincipalPaid;

  const onChange = ({
    target: { name, value }
  }: React.ChangeEvent<HTMLInputElement>) => {
    setCalcState((prevState: any) => ({
      ...prevState,
      [name]: name === "price" ? Number(value) : value
    }));

    if (name === "price") {
      const newPrice = Number(value);
      setProfitLoss((prevState: any) => ({
        ...prevState,
        purchasePrice: newPrice,
        downPayment: newPrice * 0.2, // Update down payment to 20% of new price
        homeownersInsurance: newPrice * 0.005, // Update to 0.5% of new price
        propertyTax: 0.01, // Keep at 1% (this is already a percentage)
        annualUtilities: newPrice * 0.0075 // Update to 0.75% of new price
      }));
    }

    if (["beds", "baths", "accommodates"].includes(name)) {
      const searchParams = new URLSearchParams(history.location.search);
      searchParams.set("beds", name === "beds" ? value : beds);
      searchParams.set("baths", name === "baths" ? value : baths);
      searchParams.set(
        "accommodates",
        name === "accommodates" ? value : (Number(beds) * 2 + 2).toString()
      );
      searchParams.set("price", name === "price" ? value : price);
      history.replace({
        pathname: history.location.pathname,
        search: searchParams.toString()
      });
    }
  };

  const handleUpdate = () => {
    getPrediction();
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    if (e.key === "Backspace" && value.slice(-1) === "%") {
      e.preventDefault();

      setProfitLoss((prevState: any) => {
        const newState = { ...prevState };
        const newValue = value.slice(0, value.length - 2);
        if (newValue === "") {
          delete periodRef.current[name];
        }
        newState[name] = Number(value.slice(0, value.length - 2)) / 100;
        return newState;
      });
    }
  };

  const onInputChange = (percent?: boolean) => (e: React.FormEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    // eslint-disable-next-line
    console.log(name, value, periodRef.current[name]);
    if (value.slice(-1) !== ".") {
      setProfitLoss((prevState: any) => {
        const newState = { ...prevState };
        let str = value.replace(/[^0-9.]/g, "");
        if (periodRef.current[name]) {
          str = str
            .split(".")
            .join("")
            .replace(
              periodRef.current[name]?.prevNumStr?.split(".")[0],
              `${periodRef.current[name]?.prevNumStr?.split(".")[0]}.`
            );
        }
        let num = Number(str);
        if (percent) {
          num /= 100;
        }
        newState[name] = num;
        return newState;
      });
    } else {
      const str = value.replace(/[^0-9.]/g, "");
      periodRef.current[name] = {
        prevNumStr: str
      };
    }
  };
  // Add this useEffect hook after your other useEffect hooks
  useEffect(() => {
    const initializeUserId = async () => {
      try {
        // Check if user is authenticated
        const currentUser = await Auth.currentAuthenticatedUser();
        if (currentUser) {
          setUserId(currentUser.attributes.sub);
        } else {
          // For unauthenticated users, use or create a session ID
          let sessionId = getCookie("session_id");
          if (!sessionId) {
            sessionId = uuidV4();
            setCookie("session_id", sessionId); // Set cookie to expire in 1 year
          }
          setUserId(sessionId || null);
        }
      } catch (error) {
        console.error("Error initializing user ID:", error);
      }
    };

    initializeUserId();
  }, []);

  useEffect(() => {
    console.log("Address from context:", address);
    console.log("Current state:", { address, calcLat, calcLng });

    const lat = parseFloat(calcLat as string);
    const lng = parseFloat(calcLng as string);

    if (address && !Number.isNaN(lat) && !Number.isNaN(lng)) {
      getPrediction();
    } else {
      console.log("Missing or invalid data:", {
        hasAddress: !!address,
        addressValue: address,
        hasLat: !Number.isNaN(lat),
        hasLng: !Number.isNaN(lng),
        calcLat,
        calcLng,
        beds,
        baths,
        accommodates,
        parsedLat: lat,
        parsedLng: lng
      });
    }
  }, [address, calcLat, calcLng]);

  useEffect(() => {
    setCalcState(
      propCalcState || {
        beds: 3,
        baths: 2,
        accommodates: 8,
        price: 500000
      }
    );
  }, [propCalcState]);

  useEffect(() => {
    if (user) {
      document.body.style.overflow = "";
    } else {
      const anonUserId = getCookie("anon_user_id");
      console.log("anonUserId in calc", anonUserId);
      const queryAmounts = getCookie("str_pred_query_count");

      if (!anonUserId && !user) {
        setCookie("anon_user_id", uuidV4());
        setCookie("str_pred_query_count", "0");
      }

      // if (queryAmounts && Number(queryAmounts) === 3 && !user) {
      //   document.body.style.overflow = "hidden";
      // }
    }
  }, [user]);

  useEffect(() => {
    if (location?.state) {
      if ((location as any).state.showInstructions) {
        setShowInstructions(true);
      } else {
        setShowInstructions(false);
      }
    } else {
      setShowInstructions(true);
    }
  }, [location]);

  const splitAddress = address.split(",");
  const street = splitAddress[0];
  const city = splitAddress[1]?.trim();
  const state = splitAddress[2]?.trim().split(" ")[0];

  const center =
    calcLat &&
    !Number.isInteger(calcLat) &&
    calcLng &&
    !Number.isInteger(calcLng)
      ? {
          lat: parseFloat(calcLat),
          lng: parseFloat(calcLng)
        }
      : {
          lat: 39.740063,
          lng: -104.939545
        };

  const getMaxAnnualRevenue = () => {
    if (comps.length === 0) return "No data";

    const maxComp = comps.reduce<{
      annual_revenue: number;
    }>(
      (max, comp: any) =>
        max.annual_revenue > (comp.annual_revenue ?? 0) ? max : comp,
      { annual_revenue: 0 }
    );

    return formatter(maxComp.annual_revenue ?? 0);
  };

  // Add these styled components
  const AnalyticsContent = styled.div`
    display: flex;
    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  `;

  const AnalyticsRowsContainer = styled.div`
    width: 70%;
    padding-right: 1rem;

    @media (max-width: 768px) {
      width: 100%;
      padding-right: 0;
    }
  `;

  const MapContainer = styled.div`
    width: 50%;
    height: 80vh;
    position: fixed;
    top: 153px;
    right: 0;

    @media (max-width: 768px) {
      display: none;
    }
  `;

  const landingInfo = JSON.parse(localStorage.getItem("landingInfo") || "{}");

  const typeformHiddenFields = {
    market_of_interest: `${city}, ${state}` || "Not Specified",
    source_page: location.pathname,
    utm_source: landingInfo.utm_source || "",
    utm_medium: landingInfo.utm_medium || "",
    anon_user_id: landingInfo.anon_user_id || "",
    user_id: landingInfo.user_id || "",
    utm_campaign: landingInfo.utm_campaign || "",
    utm_term: landingInfo.utm_term || "",
    utm_content: landingInfo.utm_content || "",
    subquery: address || ""
  };

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.getchalet.com/analytics/str-calculator"
        />
        <title>FREE Airbnb/Short-Term Rental Calculator and STR Analysis</title>
        <meta property="og:type" content="website" />
        <meta
          name="description"
          content="Unlock the full potential of your short-term rental with our free, AI-powered calculator. Get actionable insights on revenue, occupancy, ROI, and more—no sign-ups, just data-driven results."
        />
        <meta
          property="og:title"
          content="AI-Powered Airbnb Calculator: Short-Term Rental Investability Analyzer"
        />
        <meta
          property="og:description"
          content="Maximize your Airbnb investment with our advanced AI calculator. Instantly estimate revenue, occupancy, cap rates, and more"
        />
        <meta
          property="og:image"
          content="https://www.getchalet.com/images/airbnb-calculator.webp"
        />
        <meta
          property="og:url"
          content="https://www.getchalet.com/analytics/str-calculator"
        />
        <meta property="og:site_name" content="Chalet" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content="https://www.getchalet.com/images/airbnb-calculator.webp"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="FREE Short-Term Rental (Airbnb) Calculator"
        />
        <meta
          name="twitter:description"
          content="FREE Airbnb Investment Estimator powered by Generative AI.
            Estimates on Annual Revenue, Cap Rates,
            Earning Potentials, ROI, ADR, Occupancy Rate, Cash on Cash, Yields, and more."
        />
        <meta
          name="twitter:image"
          content="https://www.getchalet.com/images/airbnb-calculator.webp"
        />
      </Helmet>
      <Section
        style={{
          boxShadow: "none",
          maxWidth: "100%",
          padding: mobileSize ? "1rem 1rem" : "2rem",
          display: "flex",
          flexDirection: "column",
          gap: "4rem" // Add gap between all direct children of Section
        }}
      >
        <Row
          style={{
            gap: "1rem",
            position: "relative",
            flexDirection: "column", // Stack children vertically
            maxWidth: "1200px", // Add max width
            margin: "1rem auto",
            padding: mobileSize ? "0" : "2rem", // Center the container
            width: "100%", // Ensure it takes full width up to max-width
            boxShadow: mobileSize
              ? "none"
              : "rgba(108, 129, 171, 0.3) 0px 1.4rem 6rem -2rem",
            border: mobileSize ? "none" : "1px solid var(--lightPurple)"
          }}
        >
          {/* Address Section */}
          <Column
            style={{
              width: "100%", // Take full width
              gap: "0.75rem"
            }}
            $noPadding
          >
            <Address>
              <div className="address-1">{splitAddress[0] || "- - -"}</div>
              <div className="address-2">
                {address !== "" ? splitAddress.slice(1).join(",") : "- - -"}
              </div>
            </Address>
            <ChipRow
              style={{
                gap: "0.25rem",
                padding: mobileSize ? "1rem 0" : "0",
                justifyContent: "space-between",
                background: "var(--white)",
                border: "1px solid var(--lightPurple)",
                boxShadow: "0px 8px 24px -8px rgba(108,129,171,0.4)"
              }}
            >
              <Chip noBorders>
                <InputField>{propertyData.bedrooms || beds}</InputField>
                Bedroom{(propertyData.bedrooms || beds) !== 1 ? "s" : ""}
              </Chip>
              <Chip noBorders>
                <InputField>{propertyData.fullBaths || baths}</InputField>
                Bathroom{(propertyData.fullBaths || baths) !== 1 ? "s" : ""}
              </Chip>
              <Chip style={{ display: mobileSize ? "none" : "flex" }} noBorders>
                <InputField>
                  {propertyData.accommodates || accommodates}
                </InputField>
                Guest
                {(propertyData.accommodates || accommodates) !== 1 ? "s" : ""}
              </Chip>
              <Chip noBorders>
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.3rem",
                    minWidth: "60px"
                  }}
                >
                  {zestimate ? currencyNotation(zestimate) : "---"}
                </span>
                <a
                  href={zestimates?.bundle?.[0]?.zillowUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    fontSize: "1.3rem",
                    fontWeight: "400",
                    textDecoration: "underline",
                    color: "inherit",
                    display: "inline-block"
                  }}
                >
                  Zestimate
                </a>
              </Chip>
            </ChipRow>
            <Row
              style={{
                gap: "0.25rem",
                padding: "0",
                justifyContent: "space-between",
                background: "var(--white)",
                border: "1px solid var(--lightPurple)",
                boxShadow: "0px 8px 24px -8px rgba(108,129,171,0.4)"
              }}
              $hideMobile
            >
              <Chip>
                <ChipText weight={500} size="1rem" paddingBottom="0">
                  Cash-flow potential:{" "}
                  <span
                    style={{
                      color: revenue ? cashFlowPotential.color : undefined,
                      paddingInlineStart: "0.25rem",
                      fontWeight: "bold"
                    }}
                  >
                    {revenue ? cashFlowPotential.text : "- - -"}
                  </span>
                </ChipText>
                <ChipText weight={500} size="1rem" paddingBottom="0">
                  Long-Term Asset Appreciation:{" "}
                  <span
                    style={{
                      color: revenue ? "#FFA000" : undefined,
                      paddingInlineStart: "0.25rem"
                    }}
                  >
                    {revenue ? "MEDIUM" : "- - -"}
                  </span>
                </ChipText>
              </Chip>
              <div />
            </Row>
          </Column>

          {/* Metrics Section */}
          <Column
            style={{
              width: "100%" // Take full width
            }}
            $noPadding
          >
            <MetricRow
              style={{ gap: "0.5rem", padding: mobileSize ? "0" : "initial" }}
              noPadding
            >
              <Metric
                loading={loading}
                borderColor={Color.primary}
                color={Color.primary}
                shadowColor={Color.primary}
                titleColor={Color.sixth}
                title="Annual Revenue"
                value={
                  revenue
                    ? formatter(revenue, {
                        style: "currency",
                        currency: "USD"
                      })
                    : undefined
                }
              />
              <Metric
                loading={loading}
                borderColor={Color.primary}
                color={Color.primary}
                shadowColor={Color.primary}
                titleColor={Color.sixth}
                title="Occupancy Rate"
                value={
                  occupancy_rate
                    ? formatter(occupancy_rate / 100, {
                        style: "percent"
                      })
                    : undefined
                }
              />
              <Metric
                loading={loading}
                borderColor={Color.primary}
                color={Color.primary}
                shadowColor={Color.primary}
                titleColor={Color.sixth}
                title="Average Daily Rate"
                value={
                  average_daily_rate
                    ? formatter(average_daily_rate, {
                        style: "currency",
                        currency: "USD"
                      })
                    : undefined
                }
              />
              <Metric
                loading={loading}
                borderColor={Color.primary}
                color={Color.primary}
                shadowColor={Color.primary}
                titleColor={Color.sixth}
                title="Net Income"
                value={
                  address && netIncome1
                    ? formatter(netIncome1, {
                        style: "currency",
                        currency: "USD"
                      })
                    : undefined
                }
              />
              <Metric
                loading={loading}
                borderColor={Color.primary}
                color={Color.primary}
                shadowColor={Color.primary}
                titleColor={Color.sixth}
                title="Cap Rate"
                value={
                  address
                    ? formatter(netOperatingIncome / purchasePrice, {
                        style: "percent"
                      })
                    : undefined
                }
              />
            </MetricRow>
          </Column>
          <Column>
            <Section
              style={{
                boxShadow: "none",
                background: "var(--sixth)",
                maxWidth: "100%",
                padding: "0"
              }}
            >
              <div className="get-started-container">
                <div className="get-started-content">
                  <h2>Ready to Secure Financing for Your STR Investment?</h2>
                  <p>
                    Connecting with our DSCR and conventional lending partners
                    is completely free and comes with no obligation.
                  </p>
                  <div className="get-started-buttons">
                    {/* <Button onClick={() => history.push("/buying-an-airbnb")}>
                I am buying an Airbnb
              </Button> */}
                    <PopupButton
                      id="M700RBmq"
                      shareGaInstance="true"
                      hidden={{
                        ...typeformHiddenFields,
                        source_page: "/(cta-bottom-homepage)",
                        start_point: "selling"
                      }}
                      data-id="connect-with-str-lender_button"
                      className="connect-with-str-lender_button_top btn-consult"
                      style={{ flex: mobileSize ? "1 0 100%" : "0 1 auto" }}
                    >
                      Get Started
                    </PopupButton>
                    {/* <PopupButton
                id="M700RBmq"
                shareGaInstance="true"
                hidden={{
                  source_page: "/(cta-bottom-homepage)",
                  start_point: "ancillary"
                }}
                data-id="talk-to-str-expert"
                className="talk-to-str-expert_button_top btn-consult"
                style={{ flex: mobileSize ? "1 0 100%" : "0 1 auto" }}
              >
                I am looking for financing
              </PopupButton> */}
                  </div>
                </div>
                <div className="get-started-image">
                  <Image
                    src={`${process.env.PUBLIC_URL}/images/house-with-palm-trees.svg`}
                    alt="House with palm trees illustration"
                  />
                </div>
              </div>
            </Section>
          </Column>

          {/* Share/Save Buttons */}
          <div
            style={{
              display: "flex",
              gap: "1rem",
              position: mobileSize ? "relative" : "absolute",
              top: mobileSize ? "initial" : "-2rem",
              right: mobileSize ? "initial" : "2rem",
              margin: mobileSize ? "0.5rem auto" : "initial"
            }}
          >
            {user && (
              <StyledMyPropertiesLink
                to={{
                  pathname: "/analytics/str-calculator/saved-analysis",
                  state: { userId }
                }}
              >
                My Properties
              </StyledMyPropertiesLink>
            )}
            <SaveButton
              allowSave={!!user}
              onRejectSave={onRejectSave}
              alreadySaved={alreadySaved}
              data={{
                address,
                bedrooms: beds,
                bathrooms: baths,
                latitude: calcLat,
                longitude: calcLng,
                annualRevenue: revenue,
                occupancyRate: occupancy_rate,
                capRate,
                price,
                accommodates
              }}
              activeUserId={userId}
            />
            {/* <ShareButton /> */}
          </div>
        </Row>
        <Row
          style={{
            gap: "0.5rem",
            boxShadow: "0px 1.4rem 6rem -2rem rgba(108,129,171,0.3)",
            border: "1px solid var(--lightPurple)"
            // margin: mobileSize ? "0" : ""
          }}
        >
          <Column>
            <Header
              size={3}
              style={{
                textAlign: "left",
                marginBottom: "1rem",
                padding: mobileSize ? "0 1rem" : "0"
              }}
            >
              Property Insights
            </Header>
            <Row noPadding style={{ alignItems: "flex-start", gap: "0.5rem" }}>
              <Column style={{ flex: "1 1 35%", padding: "0" }}>
                {revenue ? (
                  <>
                    <Text style={{ paddingBottom: 0 }}>
                      {address} is expected to have a{" "}
                      <span
                        style={{
                          color: cashFlowPotential.color,
                          fontWeight: "400"
                        }}
                      >
                        {cashFlowPotential.text}
                      </span>{" "}
                      cash-flow potential.
                      <Tooltip
                        content={
                          <>
                            <Text block>
                              Chalet considers any property with a cap rate of
                              10% or higher to have high cash flow potential.
                            </Text>
                            <Text style={{ paddingBottom: 0 }}>
                              Properties with a cap rate of 5% or higher are
                              considered medium cash flow potential. Properties
                              with a cap rate lower than 5% are considered low
                              cash flow potential.
                            </Text>
                          </>
                        }
                      >
                        <span
                          style={{
                            fontSize: "0.7rem",
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "1px solid var(--primary)",
                            padding: "0.55rem",
                            borderRadius: "2rem",
                            color: "var(--quinary)",
                            marginLeft: "0.5rem",
                            cursor: "help",
                            fontWeight: "bold",
                            width: "1rem",
                            height: "1rem"
                          }}
                        >
                          i
                        </span>
                      </Tooltip>
                    </Text>
                    <Text style={{ paddingBottom: 0 }}>
                      This property is also expected to have a{" "}
                      <span
                        style={{
                          color: "#FFA000",
                          fontWeight: "400"
                        }}
                      >
                        MEDIUM
                      </span>{" "}
                      long-term appreciation potential.
                      <Tooltip
                        content={
                          <>
                            <Text style={{ paddingBottom: 0 }}>
                              Chalet considers any property with a historical
                              appreciation (last 10 years) of 5% or higher to
                              have high long-term appreciation potential.
                              Properties with a historical appreciation rate of
                              2.5% to 5% are considered to have a medium
                              long-term appreciation potential.
                            </Text>
                            <Text style={{ paddingBottom: 0 }}>
                              Properties with a historical appreciation rate
                              lower than 2.5% are considered to have a low
                              long-term appreciation potential.
                            </Text>
                          </>
                        }
                      >
                        <span
                          style={{
                            fontSize: "0.7rem",
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "1px solid var(--primary)",
                            padding: "0.55rem",
                            borderRadius: "2rem",
                            color: "var(--quinary)",
                            marginLeft: "0.5rem",
                            cursor: "help",
                            fontWeight: "bold",
                            width: "1rem",
                            height: "1rem"
                          }}
                        >
                          i
                        </span>
                      </Tooltip>
                    </Text>
                    <Text style={{ paddingBottom: 0 }}>
                      The comp with the highest annual revenue is{" "}
                      {comps.length > 0 && (
                        <TableLink
                          href={
                            comps.length > 0
                              ? comps.reduce<{
                                  annual_revenue?: number;
                                  listing_url?: string;
                                }>(
                                  (max, comp: any) =>
                                    (max.annual_revenue ?? 0) >
                                    (comp.annual_revenue ?? 0)
                                      ? max
                                      : comp,
                                  {} // Add this initial value
                                ).listing_url ?? "#"
                              : "#"
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color: "var(--primary)",
                            paddingInlineStart: "0.25rem",
                            fontWeight: "400",
                            fontSize: "1.1rem"
                          }}
                        >
                          {comps.length > 0
                            ? comps.reduce<{
                                annual_revenue: number;
                                listing_title: string;
                              }>(
                                (max, comp: any) =>
                                  (max.annual_revenue ?? 0) >
                                  (comp.annual_revenue ?? 0)
                                    ? max
                                    : comp,
                                { annual_revenue: 0, listing_title: "" }
                              ).listing_title
                            : "No comps available"}
                        </TableLink>
                      )}{" "}
                      with revenue of {getMaxAnnualRevenue()}
                    </Text>
                  </>
                ) : (
                  <Text>---</Text>
                )}
              </Column>
              <Column style={{ flex: "1 1 30%", padding: "0" }}>
                {revenue ? (
                  <>
                    <Text>
                      Annual revenue for comps ranges from{" "}
                      {comps.length > 0
                        ? formatter(
                            Math.min(
                              ...comps.map((comp: any) => comp.annual_revenue)
                            ),
                            { style: "currency", currency: "USD" }
                          )
                        : "N/A"}{" "}
                      to{" "}
                      {comps.length > 0
                        ? formatter(
                            Math.max(
                              ...comps.map((comp: any) => comp.annual_revenue)
                            ),
                            { style: "currency", currency: "USD" }
                          )
                        : "N/A"}{" "}
                      . Days available varies from{" "}
                      {comps.length > 0
                        ? Math.min(
                            ...comps.map((comp: any) => comp.days_available)
                          )
                        : "N/A"}{" "}
                      to{" "}
                      {comps.length > 0
                        ? Math.max(
                            ...comps.map((comp: any) => comp.days_available)
                          )
                        : "N/A"}{" "}
                      days
                      <Tooltip
                        content={
                          <>
                            <Text block>
                              &quot;Days Available&quot; refers to the number of
                              days a property is listed as available for booking
                              on short-term rental platforms over the course of
                              a year.
                            </Text>
                            <Text block>
                              This metric can indicate how actively a property
                              is managed and its potential for generating
                              revenue.
                            </Text>
                          </>
                        }
                      >
                        <span
                          style={{
                            fontSize: "0.7rem",
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "1px solid var(--primary)",
                            padding: "0.55rem",
                            borderRadius: "2rem",
                            color: "var(--quinary)",
                            marginLeft: "0.5rem",
                            cursor: "help",
                            fontWeight: "bold",
                            width: "1rem",
                            height: "1rem"
                          }}
                        >
                          i
                        </span>
                      </Tooltip>
                      .
                    </Text>
                    <Text>
                      Occupancy rates vary from{" "}
                      {comps.length > 0
                        ? formatter(
                            Math.min(
                              ...comps.map((comp: any) => comp.occupancy_rate)
                            ) / 100,
                            {
                              style: "percent",
                              minimumFractionDigits: 1,
                              maximumFractionDigits: 1
                            }
                          )
                        : "N/A"}{" "}
                      to{" "}
                      {comps.length > 0
                        ? formatter(
                            Math.max(
                              ...comps.map((comp: any) => comp.occupancy_rate)
                            ) / 100,
                            {
                              style: "percent",
                              minimumFractionDigits: 1,
                              maximumFractionDigits: 1
                            }
                          )
                        : "N/A"}
                      . Average Daily Rates (ADR) range from{" "}
                      {comps.length > 0
                        ? formatter(
                            Math.min(
                              ...comps.map(
                                (comp: any) => comp.average_daily_rate
                              )
                            ),
                            { style: "currency", currency: "USD" }
                          )
                        : "N/A"}{" "}
                      to{" "}
                      {comps.length > 0
                        ? formatter(
                            Math.max(
                              ...comps.map(
                                (comp: any) => comp.average_daily_rate
                              )
                            ),
                            { style: "currency", currency: "USD" }
                          )
                        : "N/A"}
                      <Tooltip
                        content={
                          <>
                            <Text>
                              Occupancy rate is the percentage of time a
                              property is occupied by guests.
                            </Text>
                            <Text>
                              Higher occupancy rates generally indicate stronger
                              demand and better revenue potential.
                            </Text>
                            <Text>
                              Average Daily Rate (ADR) is the average rental
                              income earned for an occupied room per day.
                            </Text>
                            <Text>
                              It&apos;s calculated by dividing the total revenue
                              by the number of occupied room nights.
                            </Text>
                          </>
                        }
                      >
                        <span
                          style={{
                            fontSize: "0.7rem",
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "1px solid var(--primary)",
                            padding: "0.55rem",
                            borderRadius: "2rem",
                            color: "var(--quinary)",
                            marginLeft: "0.5rem",
                            cursor: "help",
                            fontWeight: "bold",
                            width: "1rem",
                            height: "1rem"
                          }}
                        >
                          i
                        </span>
                      </Tooltip>
                      .
                    </Text>

                    <Text>
                      Days available varies from{" "}
                      {comps.length > 0
                        ? Math.min(
                            ...comps.map((comp: any) => comp.days_available)
                          )
                        : "N/A"}{" "}
                      to{" "}
                      {comps.length > 0
                        ? Math.max(
                            ...comps.map((comp: any) => comp.days_available)
                          )
                        : "N/A"}{" "}
                      days
                      {/* ... existing tooltip for days available ... */}.
                    </Text>
                  </>
                ) : (
                  <Text>---</Text>
                )}
              </Column>
              <Column style={{ flex: "1 1 35%" }}>
                <AnalyticsBox
                  $decorative
                  $shadow
                  style={{
                    alignItems: "flex-start",
                    flex: "1 1 auto"
                  }}
                >
                  <Header
                    size={3}
                    style={{ alignItems: "flex-start", textAlign: "left" }}
                  >
                    Looking to buy or sell your short-term rental?
                  </Header>
                  <div style={{ padding: "0 1rem", fontSize: "0.875rem" }}>
                    Get paired with Chalet Preferred Partners in{" "}
                    <strong style={{ color: "var(--primary)" }}>
                      {city}, {state}
                    </strong>
                  </div>
                  <div style={{ padding: "0 1rem" }}>
                    <StyledPopupButton
                      id="DU51pTsh"
                      shareGaInstance="true"
                      hidden={typeformHiddenFields}
                      className="connect-with-realtor_popup_top"
                    >
                      Connect with STR Agent
                    </StyledPopupButton>
                  </div>
                </AnalyticsBox>
              </Column>
            </Row>
          </Column>
        </Row>
        <Row
          style={{
            gap: "0.5rem",
            boxShadow: "0px 1.4rem 6rem -2rem rgba(108,129,171,0.3)",
            border: "1px solid var(--lightPurple)",
            padding: mobileSize ? "1rem" : "2rem"
            // backgroundColor: "var(--lightPurple)"
          }}
        >
          <AnalyticsContent>
            <AnalyticsRowsContainer>
              <Column style={{ flex: "1 1 65%", gap: "1rem" }} $noPadding>
                <Header
                  size={3}
                  style={{
                    textAlign: "left",
                    marginBottom: "1rem",
                    padding: mobileSize ? "0 1rem" : "0"
                  }}
                >
                  Competition Insights
                </Header>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHeaderCell color={Color.primary} $longCell>
                        Name
                      </TableHeaderCell>
                      <TableHeaderCell color={Color.primary} $longCell>
                        Annual Revenue
                      </TableHeaderCell>
                      <TableHeaderCell color={Color.primary} $longCell>
                        Occupancy Rate
                      </TableHeaderCell>
                      <TableHeaderCell color={Color.primary}>
                        ADR
                      </TableHeaderCell>
                      <TableHeaderCell color={Color.primary}>
                        Beds
                      </TableHeaderCell>
                      <TableHeaderCell color={Color.primary}>
                        Bathrooms
                      </TableHeaderCell>
                      <TableHeaderCell color={Color.primary}>
                        Days Available
                      </TableHeaderCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {visibleComps.map((comp: any) => (
                      <TableRow key={comp.listing_id}>
                        <TableCell align="left" $longCell>
                          <TableLink
                            href={comp.listing_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {comp.listing_title}
                          </TableLink>
                        </TableCell>
                        <TableCell $longCell>
                          {formatter(comp.annual_revenue, {
                            style: "currency",
                            currency: "USD"
                          })}
                        </TableCell>
                        <TableCell $longCell>
                          {formatter(comp.occupancy_rate / 100, {
                            style: "percent"
                          })}
                        </TableCell>
                        <TableCell>
                          {formatter(comp.average_daily_rate, {
                            style: "currency",
                            currency: "USD"
                          })}
                        </TableCell>
                        <TableCell>{comp.Bedrooms}</TableCell>
                        <TableCell>{comp.Bathrooms}</TableCell>
                        <TableCell>{comp.days_available}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {comps.length > 5 && (
                  <div style={{ textAlign: "center", marginTop: "1rem" }}>
                    <Button onClick={toggleShowAllComps}>
                      {showAllComps ? "Show Less" : "Show More"}
                    </Button>
                  </div>
                )}
              </Column>
            </AnalyticsRowsContainer>

            <Column style={{ flex: "1 1 35%" }}>
              {isLoaded && (
                <GoogleMap
                  mapContainerStyle={{
                    width: mobileSize ? "100%" : "100%",
                    height: mobileSize ? "262px" : "500px",
                    borderRadius: "0.5rem"
                  }}
                  center={center}
                  zoom={13}
                  options={{
                    styles: simplifiedMapStyle,
                    disableDefaultUI: true,
                    zoomControl: true,
                    scrollwheel: true,
                    mapTypeControl: false,
                    streetViewControl: false,
                    fullscreenControl: false
                  }}
                  onClick={() => setSelectedMarker(null)} // Close popup when clicking map
                >
                  {/* Main property marker */}
                  <Marker
                    position={center}
                    icon={{
                      path: google.maps.SymbolPath.CIRCLE,
                      scale: 12,
                      fillColor: "#5D48B2",
                      fillOpacity: 1,
                      strokeColor: "#F06F7C",
                      strokeWeight: 3
                    }}
                    onClick={() =>
                      setSelectedMarker({
                        position: center,
                        isMainProperty: true,
                        annual_revenue: revenue,
                        occupancy_rate,
                        average_daily_rate
                      })
                    }
                  />

                  {/* Comp markers */}
                  {comps.map((comp: any) => (
                    <Marker
                      key={comp.listing_id}
                      position={{ lat: comp.Latitude, lng: comp.Longitude }}
                      icon={{
                        path: google.maps.SymbolPath.CIRCLE,
                        scale: 9,
                        fillColor: "#5D48B2",
                        fillOpacity: 1,
                        strokeColor: "#FFFFFF",
                        strokeWeight: 2
                      }}
                      onClick={() =>
                        setSelectedMarker({
                          ...comp,
                          position: { lat: comp.Latitude, lng: comp.Longitude }
                        })
                      }
                    />
                  ))}

                  {/* Info Window */}
                  {selectedMarker && (
                    <InfoWindow
                      position={selectedMarker.position}
                      onCloseClick={() => setSelectedMarker(null)}
                    >
                      <MapInfoCard
                        onClick={() => {
                          if (
                            !selectedMarker.isMainProperty &&
                            selectedMarker.listing_url
                          ) {
                            window.open(selectedMarker.listing_url, "_blank");
                          }
                        }}
                      >
                        <InfoCardTitle>
                          {selectedMarker.isMainProperty
                            ? address
                            : selectedMarker.listing_title ||
                              "Comparable Property"}
                        </InfoCardTitle>
                        <InfoCardStat>
                          <span>Annual Revenue:</span>
                          <span>
                            {formatter(selectedMarker.annual_revenue, {
                              style: "currency",
                              currency: "USD"
                            })}
                          </span>
                        </InfoCardStat>
                        <InfoCardStat>
                          <span>Occupancy Rate:</span>
                          <span>
                            {formatter(selectedMarker.occupancy_rate / 100, {
                              style: "percent"
                            })}
                          </span>
                        </InfoCardStat>
                        <InfoCardStat>
                          <span>Daily Rate:</span>
                          <span>
                            {formatter(selectedMarker.average_daily_rate, {
                              style: "currency",
                              currency: "USD"
                            })}
                          </span>
                        </InfoCardStat>
                        {!selectedMarker.isMainProperty && (
                          <Text
                            size="0.75rem"
                            style={{
                              color: "var(--primary)",
                              textAlign: "center",
                              marginTop: "0.5rem"
                            }}
                          >
                            Click to view listing
                          </Text>
                        )}
                      </MapInfoCard>
                    </InfoWindow>
                  )}
                </GoogleMap>
              )}
            </Column>
          </AnalyticsContent>
        </Row>
        <Row
          noPadding
          style={{ gap: "0.5rem", padding: mobileSize ? "0" : "initial" }}
        >
          <Column style={{ gap: "1rem" }} $noPadding>
            <SectionTitle>
              <Accordion.Indicator>&#x25BE;</Accordion.Indicator> Financial
              Assumptions
            </SectionTitle>
            <Grid>
              <Section
                style={{
                  boxShadow: "0px 1.4rem 6rem -2rem rgba(108,129,171,0.3)",
                  border: "1px solid var(--lightPurple)"
                }}
              >
                <Text weight={500} style={{ fontSize: "1.25rem" }}>
                  Investment
                </Text>
                <InlineInput
                  name="downPayment"
                  label="Initial Investment"
                  value={formatter(downPayment, {
                    style: "currency",
                    currency: "USD"
                  })}
                  onChange={onInputChange()}
                  boldLabel
                />
                <InlineInput
                  name="closingCosts"
                  label="Closing Costs"
                  value={formatter(closingCosts, { style: "percent" })}
                  onKeyDown={onKeyDown}
                  onChange={onInputChange(true)}
                />
                <InlineInput
                  name="purchasePrice"
                  label="Purchase Price"
                  value={formatter(purchasePrice)}
                  onChange={onInputChange()}
                />
                <InlineInput
                  name="interestRate"
                  label="Interest Rate"
                  value={formatter(interestRate, { style: "percent" })}
                  onKeyDown={onKeyDown}
                  onChange={onInputChange(true)}
                />
                <InlineInput
                  name="homeAppreciation"
                  label="Estimated Home Appreciation"
                  value={formatter(homeAppreciation, {
                    style: "percent"
                  })}
                  onKeyDown={onKeyDown}
                  onChange={onInputChange(true)}
                />
                <InlineInput
                  name="mortgageTerm"
                  label="Mortage Term in Years"
                  value={mortgageTerm}
                  onChange={onInputChange()}
                />
                <InlineInput
                  name="furnishingCosts"
                  label="Furnishing Costs"
                  value={formatter(furnishingCosts)}
                  onKeyDown={onKeyDown}
                  onChange={onInputChange()}
                />
              </Section>
              <Section
                style={{
                  boxShadow: "0px 1.4rem 6rem -2rem rgba(108,129,171,0.3)",
                  border: "1px solid var(--lightPurple)"
                }}
              >
                <Text weight={500} style={{ fontSize: "1.25rem" }}>
                  Income
                </Text>
                <InlineInput
                  name="occupancyRate"
                  label="Occupancy Rate"
                  value={formatter(occupancyRate, { style: "percent" })}
                  onKeyDown={onKeyDown}
                  onChange={onInputChange(true)}
                />
                <InlineInput
                  name="netAdr"
                  label="Net ADR"
                  value={formatter(netAdr, {
                    style: "currency",
                    currency: "USD"
                  })}
                  onChange={onInputChange()}
                />
                <InlineInput
                  name="revenueGrowth"
                  label="Revenue Growth"
                  value={formatter(revenueGrowth, { style: "percent" })}
                  onKeyDown={onKeyDown}
                  onChange={onInputChange(true)}
                />
                <InlineInput
                  name="grossRevenue1"
                  label="Gross Revenue Year 1"
                  value={formatter(grossRevenue1, {
                    style: "currency",
                    currency: "USD"
                  })}
                  onChange={onInputChange()}
                  boldLabel
                  boldValue
                  color={Color.primary}
                />
                <InlineInput
                  name="grossRevenue5"
                  label="Gross Revenue Year 5"
                  value={formatter(grossRevenue5, {
                    style: "currency",
                    currency: "USD"
                  })}
                  onChange={onInputChange()}
                  boldLabel
                  boldValue
                  plaintext
                  color={Color.eighth}
                />
              </Section>
              <Section
                style={{
                  boxShadow: "0px 1.4rem 6rem -2rem rgba(108,129,171,0.3)",
                  border: "1px solid var(--lightPurple)"
                }}
              >
                <Text weight={500} style={{ fontSize: "1.25rem" }}>
                  Variable Expenses
                </Text>
                <InlineInput
                  name="mortgagePayment"
                  label="Mortage Payment"
                  value={formatter(mortgagePayment, {
                    style: "currency",
                    currency: "USD"
                  })}
                  onChange={onInputChange()}
                  boldLabel
                  boldValue
                  plaintext
                  color={Color.eighth}
                />
                <InlineInput
                  name="otaFee"
                  label="OTA (Airbnb) Fee"
                  value={formatter(otaFee, { style: "percent" })}
                  onKeyDown={onKeyDown}
                  onChange={onInputChange(true)}
                />
                <InlineInput
                  name="lodgingTax"
                  label="Lodging Tax"
                  value={formatter(lodgingTax, { style: "percent" })}
                  onKeyDown={onKeyDown}
                  onChange={onInputChange(true)}
                />
                <InlineInput
                  name="propertyManagement"
                  label="Property Management"
                  value={formatter(propertyManagement, {
                    style: "percent"
                  })}
                  onKeyDown={onKeyDown}
                  onChange={onInputChange(true)}
                />
                <InlineInput
                  name="maintenance"
                  label="Maintenance"
                  value={formatter(maintenance, { style: "percent" })}
                  onKeyDown={onKeyDown}
                  onChange={onInputChange(true)}
                />
                <InlineInput
                  name="cleaningFees"
                  label="Cleaning Fees"
                  value={formatter(cleaningFees, { style: "percent" })}
                  onKeyDown={onKeyDown}
                  onChange={onInputChange(true)}
                />
              </Section>
              <Section
                style={{
                  boxShadow: "0px 1.4rem 6rem -2rem rgba(108,129,171,0.3)",
                  border: "1px solid var(--lightPurple)"
                }}
              >
                <Text weight={500} style={{ fontSize: "1.25rem" }}>
                  Fixed Expenses
                </Text>
                <InlineInput
                  name="strLicensing"
                  label="STR Licensing"
                  value={formatter(strLicensing, {
                    style: "currency",
                    currency: "USD"
                  })}
                  onChange={onInputChange()}
                />
                <InlineInput
                  name="homeownersInsurance"
                  label="Homeowners Insurance"
                  value={formatter(homeownersInsurance, {
                    style: "currency",
                    currency: "USD"
                  })}
                  onChange={onInputChange()}
                />
                <InlineInput
                  name="propertyTax"
                  label="Property Tax"
                  value={formatter(propertyTax, { style: "percent" })}
                  onKeyDown={onKeyDown}
                  onChange={onInputChange(true)}
                />
                <InlineInput
                  name="annualUtilities"
                  label="Annual Utilities"
                  value={formatter(annualUtilities, {
                    style: "currency",
                    currency: "USD"
                  })}
                  onChange={onInputChange()}
                />
                <InlineInput
                  name="hoa"
                  label="HOA"
                  value={formatter(hoa, { style: "percent" })}
                  onKeyDown={onKeyDown}
                  onChange={onInputChange(true)}
                />
              </Section>
            </Grid>
          </Column>
        </Row>
        <Row noPadding>
          <AnalyticsBox
            $shadow
            $decorative
            style={{
              alignItems: "flex-start",
              flex: "1 1 auto",
              margin: mobileSize ? "1rem 0" : "2rem 1rem",
              padding: mobileSize ? "1rem 0" : "1rem 0rem",
              border: "none"
            }}
          >
            <Header
              size={mobileSize ? 4 : 2}
              style={{
                alignItems: "flex-start",
                textAlign: "left",
                marginBottom: "0"
              }}
            >
              Get Short-Term Rental Financing
            </Header>
            <div>
              <ul
                style={{
                  margin: "0"
                }}
              >
                <li>Low down payments</li>
                <li>Financing based on your Airbnb Rental Income</li>
                <li>Get loan rates and get pre-qualified</li>
              </ul>
            </div>
            <div
              style={{
                padding: "0 1rem",
                width: mobileSize ? "100%" : undefined
              }}
            >
              <FinancingCallout>
                {!mobileSize && (
                  <div className="firstText">
                    Est Mortgage Payment $
                    {formatter(mortgagePayment, {
                      maximumFractionDigits: 0
                    })}
                    /month
                  </div>
                )}
                <div className="secondText">
                  Get Short-Term Rental Financing
                </div>
              </FinancingCallout>
            </div>
          </AnalyticsBox>
        </Row>
        <Row style={{ gap: "0.5rem", border: "none" }}>
          <Column $noPadding>
            {!mobileSize && (
              <Row noPadding>
                <SectionTitle>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/sparking-stars.png`}
                    alt="Icon"
                    style={{
                      marginRight: "2px",
                      width: "25px",
                      height: "25px"
                    }}
                  />
                  Financial AI Insights
                </SectionTitle>
              </Row>
            )}
            {!mobileSize && (
              <Row noPadding>
                <Column style={{ flex: "1 1 50%" }}>
                  <Banner
                    title="Financial AI Insights Coming Soon!"
                    description="Be the first to access our AI-driven financial analysis for short-term rentals."
                    ctaText="Get Early Access"
                    ctaAction={handleJoinAIWaitlistSubmit}
                    style={{ width: "100%", margin: "1rem 0", border: "none" }}
                  />
                </Column>
              </Row>
            )}
          </Column>
          <Column style={{ flex: "1 1 50%" }} $shadow>
            <Flex style={{ justifyContent: "center" }}>
              <Text
                weight={300}
                style={{
                  textAlign: "center",
                  fontSize: "1.5rem",
                  color: "var(--primary)"
                }}
              >
                Estimated Investment Value
              </Text>
            </Flex>
            <div
              style={{
                height: "450px",
                padding: mobileSize ? "0rem" : "1rem"
              }}
            >
              <StackedBarChart
                data={new Array(mobileSize ? 5 : 10)
                  .fill(false)
                  .map((discard, idx) => ({
                    year: String(new Date().getFullYear() + 1 + idx),
                    values: [
                      {
                        name: "Cummulative Net Cash Flow",
                        value: new Array(idx + 1)
                          .fill(false)
                          .map((income, incomeIdx) => {
                            if (incomeIdx === 0) {
                              return netIncome1;
                            }

                            return netIncomeAfter1(
                              otherGrossRevenues(incomeIdx + 1)
                            );
                          })
                          .reduce((prev, curr) => prev + curr, 0)
                      },
                      {
                        name: "Cummulative Equity",
                        value:
                          downPayment +
                          calculateLoanDataAfterMonth((idx + 1) * 12)
                            .totalPrincipalPaid +
                          renovationCosts
                      },
                      {
                        name: "Cummulative Appreciation",
                        value: appreciationAfterYear(idx + 1)
                      }
                    ]
                  }))}
                line={[
                  {
                    name: "Est. Investment Value",
                    values: new Array(10)
                      .fill(false)
                      .map((discard, idx) => investmentValueAfterYear(idx + 1))
                  }
                ]}
              />
            </div>
          </Column>
        </Row>
      </Section>
      <AnalyticsModal
        isOpen={showAnalyticsModal}
        // onClose={() => setShowAnalyticsModal(false)}
      />
    </>
  );
};

export default StrCalculatorBody;
