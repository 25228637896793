import { useHistory, useParams, useLocation } from "react-router-dom";
import React, { useEffect, useContext } from "react";
import { Parser } from "html-to-react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { PopupButton } from "@typeform/embed-react";
import useSWR from "swr";
import Section from "../components/Section";
import Text from "../components/Text";
import Select from "../components/Select";
import Header from "../components/Header";
import "../styles/Home.scss";
import Post from "../components/Post";
import Image from "../components/Image";
import { AppContext } from "../components/AppContext";
import SplitView from "../components/SplitView";
import useCookies from "../hooks/useCookies";
import "../styles/RentalRegulations.scss";
import SkeletonLanding from "../components/SkeletonLanding";
import { Featured, FeaturedPost, FeaturedRight } from "../components/Featured";
import fetcher from "../utils/fetch";
import AnalyticsWrapper from "./Analytics/components/AnalyticsWrapper";
import SkeletonLoader from "../components/SkeletonLoader";

const htmlToReactParser: any = new (Parser as any)();

function toTitleCase(str: string) {
  return str
    .toLowerCase()
    .split(" ")
    .map((word: string) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

// const defaultForm = {
//   fullName: "",
//   // phone: "",
//   email: "",
//   // interestedIn: "",
//   consent: true
// };

const RentalRegulationsLocationsDropdown = styled(Select)`
  display: flex;
  flex-direction: column;
  max-height: 260px;
  margin-top: inherit;
  background-color: var(--quinary);
  color: var(--white);
  overflow-y: auto;
  @media (max-width: 800px) {
    // margin-top: -2rem;
  }
`;

const StyledSectionRentalRegulations = styled(Section)`
  @media (max-width: 800px) {
    svg {
      top: 0.5rem;
    }
  }
`;

const RentalRegulations = ({
  hideHeader,
  isAnalytics,
  slug: propSlug
}: {
  hideHeader?: boolean;
  isAnalytics?: boolean;
  slug?: string;
}) => {
  const history = useHistory();
  const { slug: paramSlug } = useParams<any>();
  const location = useLocation();
  // const [isContactUs, setContactUs] = useState(false);
  // const [, setThankYou] = useState(false);
  const { getAllCookies } = useCookies();
  const { mobileSize, prerenderHeight, setPrerenderHeight } =
    useContext(AppContext);

  const baseUrl = React.useMemo(
    () =>
      isAnalytics ? "/analytics/rental-regulations" : "/rental-regulations",
    []
  );

  const cookies: Record<string, string> = {};
  getAllCookies().forEach(([cookieName, val]) => {
    cookies[cookieName] = val;
  });

  const slug = paramSlug || propSlug;

  const transformHTMLContent = (htmlContent: any) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");

    const images = doc.querySelectorAll("img");
    console.log("images", images);
    images.forEach((image) => {
      const src = image.getAttribute("src");
      console.log("source", src);
      if (
        src &&
        (src.includes("44.231.197.151") ||
          src.includes("dgbqmpxvjgsxl.cloudfront.net"))
      ) {
        const newsrc = src
          .replace("http", "https")
          .replace("44.231.197.151", "dgbqmpxvjgsxl.cloudfront.net");
        // .replace("dgbqmpxvjgsxl.cloudfront.net", "dgbqmpxvjgsxl.cloudfront.net");
        image.setAttribute("src", newsrc);
      }
    });

    return doc.body.innerHTML;
  };

  function getMarketFromSlug() {
    // let marketFromSlug = ''
    let marketFromSlug = slug ? toTitleCase(slug.replace(/-/g, " ")) : "";
    // eslint-disable-next-line
    // eslint-disable-next-line
    if (
      [
        "fort-myers-fl",
        "lake-havasu-city-az",
        "panama-city-beach-fl",
        "pompano-beach-fl",
        "gulf-shores-al",
        "san-antonio-tx",
        "west-palm-beach-fl"
      ].includes(slug)
    ) {
      marketFromSlug = marketFromSlug.replace(
        marketFromSlug.slice(-3),
        `, ${marketFromSlug.slice(-2).toUpperCase()}`
      );
    } else if (slug === "kissimmee-fl") {
      marketFromSlug = "Kissimmee, FL";
    } else if (slug === "panama-city-beach-fl") {
      marketFromSlug = "Panama City Beach, Fl";
    } else if (slug === "san-antonio-tx") {
      marketFromSlug = "San Antonio, TX";
    } else if (slug === "broward-county-fl-rental-regulations") {
      marketFromSlug = "Fort Lauderdale, FL";
    } else if (slug === "dallas-tx-rental-regulations") {
      marketFromSlug = "Dallas, TX";
    } else if (slug === "hollywood-fl-str-regulations") {
      marketFromSlug = "Hollywood, FL";
    } else if (slug === "palm-springs-ca-rental-regulations") {
      marketFromSlug = "Palm Springs, CA";
    } else if (slug === "philadelphia-pa-str-regulations") {
      marketFromSlug = "Philadelphia, PA";
    } else if (slug === "san-diego-ca-str-regulations") {
      marketFromSlug = "San Diego, CA";
    } else if (slug === "philadelphia-pa-str-regulations") {
      marketFromSlug = "Philadelphia, PA";
    } else if (slug === "pompano-beach-fl") {
      marketFromSlug = "Pompano Beach, FL";
    } else if (slug === "shelby-county-str-regulation") {
      marketFromSlug = "Memphis, TN";
    } else if (slug === "str-regulation-fulton-county-atlanta-metro-ga") {
      marketFromSlug = "Atlanta, GA";
    } else if (slug === "maricopa-count-az-str-regulations") {
      marketFromSlug = "Phoenix/Scottsdale, AZ";
    } else {
      marketFromSlug = marketFromSlug.replace(
        marketFromSlug.slice(-3),
        `, ${marketFromSlug.slice(-2).toUpperCase()}`
      );
    }
    return marketFromSlug;
  }
  function getSearchPlaceholder() {
    let label: any = window.location.pathname.split("/");
    label = label[label.length - 1];
    if (label === "rental-regulations") {
      return "Search Locations";
    }
    return getMarketFromSlug();
  }

  // useEffect(() => {
  //   async function addContact() {
  //     try {
  //       const requestOptions = {
  //         method: "POST",
  //         // headers: { "Content-Type": "application/json" },
  //         body: JSON.stringify({
  //           name: fullName,
  //           email,
  //           downloadReport: true,
  //           market: getMarketFromSlug(),
  //           consent,
  //           ...cookies,
  //           sourcePage: "rental-regulations-v2"
  //         })
  //       };
  //       // const apiData = await API.post("customers", "/contacts", data);
  //       await fetch(
  //         "https://cbehz6zbjl.execute-api.us-west-2.amazonaws.com/v1/contacts",
  //         requestOptions
  //       );
  //
  //       setThankYou(true);
  //     } catch (error) {
  //       // eslint-disable-next-line no-console
  //       // eslint-disable-next-line
  //       console.error("error scheduling consultation:", error);
  //     }
  //     setContactUs(false);
  //   }
  //   if (isContactUs) {
  //     setContactUs(true);
  //     addContact();
  //   }
  // }, [isContactUs, form]);

  const { data: activePosts, isLoading } = useSWR(
    slug
      ? `https://dgbqmpxvjgsxl.cloudfront.net/wp-json/wp/v2/rental_regulations?slug=${slug}`
      : undefined,
    fetcher,
    { fallbackData: [] }
  );

  const activePost = activePosts[0];

  const { data: unFilteredCategories } = useSWR(
    "https://dgbqmpxvjgsxl.cloudfront.net/wp-json/wp/v2/categories?per_page=100&parent=34",
    fetcher,
    { fallbackData: [] }
  );

  const categories = React.useMemo(
    () =>
      unFilteredCategories
        .filter((category: any) => {
          return (
            category.link.includes("rental_regulations") &&
            category.slug !== "rental_regulations"
          );
        })
        .map((category: any) => ({
          id: category.id,
          label: category.name,
          value: category.slug
        })),
    [unFilteredCategories]
  );

  useEffect(() => {
    setPrerenderHeight(mobileSize ? "2300px" : "1200px");
  }, [mobileSize]);

  useEffect(() => {
    setTimeout(() => {
      const signUpLinks = Array.from(
        document.querySelectorAll("[href*='/sign-up']")
      );
      signUpLinks.forEach((link) => {
        link.addEventListener("click", (e) => {
          e.preventDefault();
          e.stopPropagation();
          // setIsModalOpen(true);
        });
      });
    }, 322);
  }, [activePost]);

  const onSelect = (e: any) => {
    history.push(`${baseUrl}/${e.value.value}`);
  };

  const landingInfo = JSON.parse(localStorage.getItem("landingInfo") || "{}");

  const typeformHiddenFields = {
    market_of_interest: getMarketFromSlug() || "Not Specified",
    source_page: location.pathname,
    utm_source: landingInfo.utm_source || "",
    utm_medium: landingInfo.utm_medium || "",
    anon_user_id: landingInfo.anon_user_id || "",
    user_id: landingInfo.user_id || "",
    utm_campaign: landingInfo.utm_campaign || "",
    utm_term: landingInfo.utm_term || "",
    utm_content: landingInfo.utm_content || "",
    subquery: ""
  };

  return (
    <div
      className="rental-regulations_main"
      style={{
        paddingTop: !hideHeader ? "120px" : undefined
      }}
    >
      <Helmet>
        <link rel="canonical" href={`https://www.getchalet.com${baseUrl}`} />
        <title>Chalet | Short-Term Rental (Airbnb) Regulation</title>
        <meta property="og:type" content="website" />
        <meta
          name="description"
          content="Short-term rental regulations for any local U.S. market.
          Investors planning to Airbnb, VRBO, or rent a vacation rental must
          understand current market conditions and stay up-to-date on local regulation changes."
        />
        <meta
          property="og:title"
          content="Chalet | Short-Term (Airbnb) Rental Regulation"
        />
        <meta
          property="og:description"
          content="Short-term rental regulations for any local U.S. market.
          Investors planning to Airbnb, VRBO, or rent a vacation rental must
          understand current market conditions and stay up-to-date on local regulation changes."
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content={`https://www.getchalet.com${baseUrl}`}
        />
        <meta property="og:site_name" content="Chalet" />
      </Helmet>
      {!hideHeader && (
        <StyledSectionRentalRegulations
          className="search-rental-regulations"
          style={{
            display: "flex",
            flexDirection: "column",
            padding: mobileSize ? "0rem" : "1rem",
            overflow: "inherit",
            marginBottom: !mobileSize ? "-14rem" : "initial",
            boxShadow: "none",
            borderRadius: "0.75rem 0.75rem 0 0rem",
            background: "none"
          }}
        >
          <SplitView
            split="1:1"
            style={{
              padding: mobileSize ? "1rem" : "1rem 2rem 0",
              // marginBottom: 0,
              paddingTop: "1rem",
              alignItems: "flex-start",
              // gridTemplateColumns: "2fr 3fr",
              // backgroundColor: "rgba(255,255,255, 0.8)",
              maxWidth: mobileSize ? "100vw" : "85vw",
              margin: "1rem auto 0"
            }}
          >
            <div id="location-container" style={{ padding: 0 }}>
              <Text
                weight={300}
                block
                style={{
                  fontSize: mobileSize ? "1.7em" : "2.4rem",
                  lineHeight: mobileSize ? "2rem" : "2.5rem",
                  paddingBottom: mobileSize ? "1rem" : "0.5rem",
                  color: "var(--primary)",
                  fontWeight: 400
                }}
              >
                Short-Term Rental Regulations
              </Text>
              <Text
                weight={400}
                block
                style={{
                  fontSize: mobileSize ? "1.2em" : "1.2rem",
                  lineHeight: mobileSize ? "2rem" : "2.5rem",
                  paddingBottom: "1rem",
                  color: "var(--quinary)"
                }}
              />
              <RentalRegulationsLocationsDropdown
                style={{
                  marginTop: mobileSize ? "1rem" : "initial",
                  padding: "1rem"
                }}
                isSearchable
                options={categories}
                placeholder={getSearchPlaceholder()}
                onChange={onSelect}
                data-id="best-airbnbs_dropdown"
              />
            </div>
            <div style={{ padding: "0px" }}>
              <Image
                className={`search-image + ${activePost ? "_bg" : ""}`}
                src={`${process.env.PUBLIC_URL}/images/investor-guides3.0.webp`}
                alt="Review of best Airbnbs in the United States"
                style={{
                  margin: "0 auto",
                  padding: "0rem",
                  maxWidth: "100%"
                }}
                $mobileSize={mobileSize}
              />
            </div>
          </SplitView>
        </StyledSectionRentalRegulations>
      )}
      {isLoading && (
        <AnalyticsWrapper marginTop={false}>
          <SkeletonLoader />
        </AnalyticsWrapper>
      )}
      {!isLoading && !activePost && (
        <>
          <Post id="no-market">
            <SkeletonLanding width="100%" height={prerenderHeight}>
              <div id="no-market_intro">
                At Chalet, we&apos;ve found that understanding STR regulations
                is a critical first step when starting your investment journey.
                <br />
                <br />
                Thus, we&apos;ve made it our mission to create town, city, and
                unincorporated county STR regulation guides. Note that this is
                not legal advice and should be used only as a research tool. We
                always recommend checking directly with the local authority to
                fully understand their STR regulations.
                <br />
                <br />
                <br />
                <Featured title="Popular Markets">
                  <FeaturedPost
                    image="https://dgbqmpxvjgsxl.cloudfront.net/wp-content/uploads/2024/05/galveston-tx-thumb.webp"
                    title="Galveston, TX"
                    text="How does Galveston, Texas, define a short-term (Airbnb) rental (STR)? An accessory building or dwelling unit that is designed to house humans on a temporary basis and a residential unit that’s rented for any period of time that is less than 30 consecutive days. "
                    href={`${baseUrl}/galveston-tx`}
                  />
                  <FeaturedRight
                    image="https://dgbqmpxvjgsxl.cloudfront.net/wp-content/uploads/2024/05/kissimmee-fl-thumb.webp"
                    title="Kissimmee, FL"
                    text="For Kissimmee, parent Osceola County under which Kissimmee is governed defines STR as: “Short-term (Airbnb) rental shall mean any dwelling unit, or group of dwelling units located within Osceola County within a single development."
                    href={`${baseUrl}/kissimmee-fl`}
                  />
                </Featured>
                <Featured title="">
                  <FeaturedRight
                    image="https://dgbqmpxvjgsxl.cloudfront.net/wp-content/uploads/2024/05/thumb_Myrtle-Beach-SC-Investor-Guides.jpg"
                    title="Myrtle Beach, SC"
                    text="How does Myrtle Beach define a short-term rental (STR)?
                    In Myrtle Beach, a short-term rental is defined as any rental period of less than 90 days. Most residential areas in the city are not zoned for short-term rentals"
                    href={`${baseUrl}/myrtle-beach-sc`}
                  />
                  <FeaturedPost
                    image="https://dgbqmpxvjgsxl.cloudfront.net/wp-content/uploads/2024/05/memphis-tn-thumb.webp"
                    title="Memphis, TN"
                    text="Shelby County, TN defines a short-term (Airbnb) rental property as “a residential dwelling unit1."
                    href={`${baseUrl}/memphis-tn`}
                  />
                </Featured>
                <Featured title="">
                  <FeaturedPost
                    image="https://dgbqmpxvjgsxl.cloudfront.net/wp-content/uploads/2024/05/gulf-shores-al-thumb.webp"
                    title="Gulf Shores, AL"
                    text="A vacation rental in Gulf Shores, AL, is defined as any dwelling that includes a kitchen, sleeping, and sanitary facility occupied by one family under a lease, sublease, or rental agreement for a period of fewer than 180 days."
                    href={`${baseUrl}/gulf-shores-al`}
                  />
                  <FeaturedRight
                    image="https://dgbqmpxvjgsxl.cloudfront.net/wp-content/uploads/2024/05/minneapolis-mn-thumb.webp"
                    title="Minneapolis, MN"
                    text="A short-term (Airbnb) rental in Minneapolis is a property that offers somewhere to stay or live temporarily. These include homes or a portion of an existing home."
                    href={`${baseUrl}/minneapolis-mn`}
                  />
                </Featured>
              </div>
            </SkeletonLanding>
          </Post>
        </>
      )}
      {!isLoading && activePost && (
        <>
          <Helmet>
            {(activePost as any)?.head_tags.map(
              ({ content, tag, attributes }: any, idx: number) => {
                if (tag === "title") {
                  return <title key={tag}>{content}</title>;
                }
                if (tag === "meta") {
                  const { name, content: metaContent, property } = attributes;
                  const replacedContent = metaContent
                    .replace("http", "https")
                    .replace("44.231.197.151", window.location.host);
                  if (name) {
                    return (
                      <meta key={name} name={name} content={replacedContent} />
                    );
                  }
                  if (property) {
                    return (
                      <meta
                        key={property}
                        property={property}
                        content={replacedContent}
                      />
                    );
                  }
                }
                if (tag === "link") {
                  const { href, ...otherAttributes } = attributes;
                  let replacedHref = href;
                  const key = `link-${idx}`;
                  if (
                    href.includes("44.231.197.151") ||
                    href.includes("dgbqmpxvjgsxl.cloudfront.net")
                  ) {
                    replacedHref = replacedHref
                      .replace("http", "https")
                      .replace("44.231.197.151", window.location.host)
                      .replace(
                        "dgbqmpxvjgsxl.cloudfront.net",
                        window.location.host
                      );
                  }
                  if (href.includes("rental_regulations")) {
                    replacedHref = replacedHref.replace(
                      "rental_regulations",
                      "rental-regulations"
                    );
                  }

                  if (replacedHref.includes("rental-regulations")) {
                    replacedHref = replacedHref.replace(
                      "rental-regulations",
                      "analytics/rental-regulations"
                    );
                  }
                  // return only if it's a link to a rental regulation
                  if (
                    replacedHref.includes("rental-regulations") &&
                    !replacedHref.includes("wp-json") &&
                    !replacedHref.includes("/feed")
                  ) {
                    console.log("key", key);
                    console.log("replacedHref", replacedHref);
                    return (
                      <link
                        key={key}
                        href={replacedHref}
                        {...otherAttributes}
                      />
                    );
                  }
                  // return (
                  //   // console.log('key', key);
                  //   <link key={key} href={replacedHref} {...otherAttributes} />
                  // );
                }
                return null;
              }
            )}
          </Helmet>
          <Post>
            {(activePost as any)?.title.rendered
              .split(":")
              .map((t: string, idx: number) => (
                <Header
                  style={{
                    fontSize: mobileSize ? "2rem" : "3rem",
                    maxWidth: "1200px",
                    margin: "0 auto"
                  }}
                  key={t}
                  subtitle={idx === 1}
                >
                  {t}
                </Header>
              ))}
            <div
              style={{
                maxWidth: mobileSize ? "100%" : "1200px",
                margin: "0 auto"
              }}
            >
              {htmlToReactParser.parse(
                transformHTMLContent(activePost?.content?.rendered || "")
              )}
            </div>
            <div
              style={{
                background: "var(--sixth)",
                padding: "4rem 0",
                marginTop: "2rem"
              }}
            >
              <div className="get-started-container">
                <div className="get-started-content">
                  <h2>Ready to get started?</h2>
                  <p>
                    Instantly match with leading short-term rental real estate
                    agents and lenders.
                  </p>
                  <div
                    className="get-started-buttons"
                    style={{ maxWidth: "700px" }}
                  >
                    <PopupButton
                      id="DU51pTsh"
                      shareGaInstance="true"
                      hidden={{
                        ...typeformHiddenFields,
                        source_page: `investor-guides/${slug}/(cta-bottom)`,
                        start_point: "buying"
                      }}
                      data-id="connect-with-realtor_button"
                      className="connect-with-realtor_button_top btn-consult"
                      style={{ flex: mobileSize ? "1 0 100%" : "0 1 auto" }}
                    >
                      Buying an Airbnb
                    </PopupButton>
                    <PopupButton
                      id="lMhk4Fej"
                      shareGaInstance="true"
                      hidden={{
                        ...typeformHiddenFields,
                        source_page: `investor-guides/${slug}/(cta-bottom)`,
                        start_point: "selling"
                      }}
                      data-id="talk-to-str-expert"
                      className="talk-to-str-expert_button_top btn-consult"
                      style={{ flex: mobileSize ? "1 0 100%" : "0 1 auto" }}
                    >
                      Selling an Airbnb
                    </PopupButton>
                    <PopupButton
                      id="M700RBmq"
                      shareGaInstance="true"
                      hidden={{
                        ...typeformHiddenFields,
                        source_page: `investor-guides/${slug}/(cta-bottom)`,
                        start_point: "ancillary"
                      }}
                      data-id="connect-with-str-lender_button"
                      className="connect-with-str-lender_button_top btn-consult"
                      style={{ flex: mobileSize ? "1 0 100%" : "0 1 auto" }}
                    >
                      Financing Your Airbnb
                    </PopupButton>
                  </div>
                </div>
                <div className="get-started-image">
                  <Image
                    src={`${process.env.PUBLIC_URL}/images/house-with-palm-trees.svg`}
                    alt="House with palm trees illustration"
                  />
                </div>
              </div>
            </div>
            <div style={{ maxWidth: "1200px", margin: "0 auto" }}>
              <h6 style={{ margin: 0 }}>
                The information provided on this website regarding short-term
                rental regulations is for general informational and educational
                purposes only. We endeavor to keep the information up-to-date
                and accurate, but laws, regulations, and policies governing
                short-term rentals are subject to change and can vary widely by
                location and over time.
              </h6>
              <h6 style={{ margin: 0 }}>
                Please be aware that the regulatory environment for short-term
                rentals is in a constant state of flux. Changes at the local,
                state, or federal level may occur rapidly and could have a
                significant impact on the operation of short-term rentals.
                Therefore, we cannot guarantee the completeness, reliability, or
                accuracy of the information provided as it may not reflect the
                most current legal developments.
              </h6>
              <h6 style={{ margin: 0 }}>
                This information is not intended to provide, and should not be
                relied on for, legal, tax, or professional advice. We encourage
                you to consult with a professional advisor or legal counsel
                familiar with the specific circumstances of your situation and
                the local regulations before making any decisions based on the
                information found on this site.
              </h6>
              <h6 style={{ margin: 0 }}>
                By using this site, you acknowledge and agree that
                www.getchalet.com is not responsible or liable for any claim,
                loss, or damage arising from the use of the information
                provided. You are ultimately responsible for ensuring compliance
                with the applicable laws and regulations for your short-term
                rental.
              </h6>
            </div>
          </Post>
        </>
      )}
    </div>
  );
};

export default RentalRegulations;
