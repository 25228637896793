import React, {
  useRef,
  useEffect,
  useContext,
  lazy,
  Suspense,
  useState
} from "react";
import { Helmet } from "react-helmet";
import Typed from "typed.js";
import styled from "styled-components";
import { useParams, Link } from "react-router-dom";
import { PopupButton } from "@typeform/embed-react";
import { AppContext } from "../../components/AppContext";
import Text from "../../components/Text";
import Header from "../../components/Header";
import useCookies from "../../hooks/useCookies";
import Flex from "../../components/Flex";
import SectionFooter from "../../components/SectionFooter";
import SplitView from "../../components/SplitView";
import Group from "../../components/Group";
import Section from "../../components/Section";
import { Color } from "../../types";
import LiveMarkets from "../../liveMarkets";
import Image from "../../components/Image";
import Card from "../../components/Card";
import List from "../../components/List";
// Import the JSON file
import listingsData from "../ForSaleListings/2024-10-01_combined.json";
import HR from "../../components/HR";

interface Listing {
  id: number;
  title: string;
  location: string;
  price: number;
  annualRevenue: number;
  image: string;
  // hoa: number;
  // lotSize: number;
  bedrooms: number;
  bathrooms: number;
  // availability: string;
  sqft: number;
  capRate: string;
}

const LazyImage = lazy(() => import("../../components/LazyImage"));

const ListingCard: React.FC<{ listing: Listing }> = ({ listing }) => (
  <Link
    to={`/airbnbs-for-sale-listings/${listing.location}`}
    target="_blank"
    style={{
      display: "block",
      textAlign: "center",
      background: "var(--white)",
      color: "white",
      padding: "8px",
      borderRadius: "4px",
      textDecoration: "none"
    }}
    className="listing-card-link"
  >
    <div
      style={{
        border: "1px solid #ddd",
        borderRadius: "8px",
        padding: "16px",
        // width: "300px",
        height: "500px", // Increased height
        cursor: "pointer",
        display: "flex",
        // margin: "10px",
        flexDirection: "column",
        justifyContent: "space-between" // Distribute content evenly
      }}
      className="listing-card"
    >
      <div>
        <h2 style={{ fontSize: "1.2em", marginBottom: "8px" }}>
          {listing.location}
        </h2>
        <Suspense fallback={<div>Loading...</div>}>
          <LazyImage
            src={listing.image}
            alt={listing.title}
            style={{
              width: "100%",
              height: "200px",
              objectFit: "cover",
              marginBottom: "12px",
              borderRadius: "4px"
            }}
          />
        </Suspense>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "8px"
          }}
        >
          {/* <span>Price:</span> */}
          <span style={{ fontWeight: "bold", color: "var(--primary)" }}>
            ${listing.price.toLocaleString()}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: "12px",
            fontSize: "1em",
            color: "var(--quaternary)"
          }}
        >
          <span style={{ color: "var(--secondary)", fontWeight: "500" }}>
            {listing.bedrooms} beds
          </span>
          <span style={{ margin: "0 8px" }}>|</span>
          <span style={{ color: "var(--secondary)", fontWeight: "500" }}>
            {listing.bathrooms} baths
          </span>
          <span style={{ margin: "0 8px" }}>|</span>
          <span style={{ color: "var(--secondary)", fontWeight: "500" }}>
            {listing.sqft} sq ft
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "8px"
          }}
        >
          <span>Projected Revenue:</span>
          <span style={{ fontWeight: "500", color: "var(--primary)" }}>
            ${listing.annualRevenue.toLocaleString()}/year
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "8px"
          }}
        >
          <span>Cap Rate:</span>
          <span style={{ fontWeight: "500", color: "var(--primary)" }}>
            {((listing.annualRevenue / listing.price) * 60).toFixed(2)}%
          </span>
        </div>
      </div>
    </div>
  </Link>
);

const Container = styled(Flex)`
  // flex-direction: column;
  font-size: 2.5rem;
  height: 100vh;
  // width: 50%;
  justify-content: center;
  line-height: 1;
  h1 {
    font-weight: 300;
    font-size: 3.5rem;
    // text-align: center;
    margin-bottom: 0;
    text-align: left;
    // padding-bottom: 1rem;
  }
  h3 {
    font-weight: 300;
    font-size: 1.6rem;
    // text-align: center;
    margin: 0;
  }
  video {
    margin: 2rem;
    max-width: 450px;
    border-radius: 0.5rem;
  }
  padding: 5rem 0;
  .first ${Text} {
    white-space: nowrap;
    font-size: 3.5rem;
  }
  .second h1 {
    font-size: 1.8rem;
    font-weight: 300;
    margin: 0;
    color: var(--primary);
  }
  .third ${Text} {
    font-size: 1.5rem;
  }
  .first {
    padding: 1rem;
    padding-left: 1rem;
    padding-bottom: 0;
    // width: 50%;
  }
  .second,
  .third {
    align-items: center;
    display: flex;
    font-size: 1rem;
    padding-top: 0.75rem;
    padding: 1rem;
    padding-left: 1rem;
    // width: 50%;
  }
  .second {
    padding-top: 0;
  }
  .third {
    // display: block;
    // text-align: left;
    padding: 0rem;
  }
  ${Group} {
    :first-of-type {
      margin-top: 6rem;
    }
  }

  @media (max-width: 810px) {
    // justify-content: start;
    align-items: flex-start;
    margin-top: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 80vh;
    h3 {
      font-size: 2rem;
    }
    .first {
      text-align: center;
      h1 {
        font-size: 2.2rem;
        text-align: left;
        ${Text} {
          font-size: 2.2rem;
          text-align: left;
          line-height: 2.2rem;
        }
      }
    }
    .second {
      // display: none;
      h3 {
        font-size: 1.2rem;
        text-align: left;
        ${Text} {
          font-size: 1.2rem !important;
        }
      }
    }
    video {
      display: none;
    }
    .second {
      padding-bottom: 1rem;
    }
    .third ${Text} {
      padding-bottom: 1rem;
    }
    .second,
    .third {
      text-align: center;
    }
    .third {
      flex-direction: column;
    }
  }
`;

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  // centerMode: true,
  // centerPadding: "60px",
  rows: 1,
  // variableWidth: true,

  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true
        // centerMode: true,
        // centerPadding: "60px"
      }
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        centerMode: false
        // centerPadding: "60px"
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        arrows: false,
        centerPadding: "60px"
      }
    }
  ]
};

const FAQSection = styled(Section)`
  background: var(--white);
  //   padding: 3rem 2rem;
  //   max-width: 95vw;
  box-shadow: none;
  margin: 0 auto;

  h2 {
    color: var(--primary);
    text-align: center;
    margin-bottom: 2rem;
  }

  .faq-item {
    margin-bottom: 3rem;
    border-radius: 0.5rem;
    padding: 1.5rem;
    background-color: var(--lightPurple);
    border: 1px solid var(--primary);

    h3 {
      color: var(--primary);
      margin-bottom: 1rem;
    }

    p {
      color: var(--secondary);
      line-height: 1.6;
    }

    ol {
      padding-left: 1.5rem;
      margin-top: 0.5rem;
    }

    li {
      margin-bottom: 0.5rem;
      font-weight: 300;
    }
  }
`;

const SellingYourSTRForSocial = () => {
  const { getAllCookies } = useCookies();
  const { marketPathParam } = useParams<any>();
  const [searchTerm, setSearchTerm] = useState("");
  const [listings, setListings] = useState<Listing[]>([]);
  useEffect(() => {
    // Load the data from the imported JSON file
    setListings(
      listingsData.map((listing) => ({
        ...listing,
        title: listing.address,
        location: listing.address,
        annualRevenue: listing.annualRevenue,
        image: listing.images[0],
        sqft: listing.sqft ?? 0, // Provide a default value of 0 if sqft is undefined
        capRate: `${listing.capRate.toFixed(2)}%`
      }))
    );
  }, []);

  const el = useRef(null);
  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ["No Hassle", "Confidence", "Expert Partners"], // Strings to display
      // Speed settings, try diffrent values untill you get good results
      startDelay: 200,
      typeSpeed: 100,
      backSpeed: 100,
      backDelay: 50
    });

    // Destropying
    return () => {
      typed.destroy();
    };
  }, []);

  const { mobileSize } = useContext(AppContext);

  const bodyRef = useRef<HTMLDivElement>();

  const cookies: Record<string, string> = {};

  getAllCookies().forEach(([cookieName, val]) => {
    cookies[cookieName] = val;
  });

  const titleString = `Sell Your Airbnb Fast ${marketPathParam ? "in" : ""} ${
    LiveMarkets[marketPathParam] || ""
  } | Get the Best Offers`;
  // const descriptionString = `Airbnb Realtors. Find the perfect short-term rental investment in the ${
  //   LiveMarkets[marketPathParam] || ""
  // } market with the help of a local experts. Our top Airbnb realtors
  //  know the market inside and out and can guide you to the best
  //  investment with the highest ROI. Connect with Chalet today`;
  const descriptionString = `Sell your Airbnb rental ${
    marketPathParam ? "in" : ""
  } ${
    LiveMarkets[marketPathParam] || ""
  } with our expert short-term rental agents. Get your listing in front of the largest network of Airbnb rental buyers. Work with Chalet to sell your (Airbnb) short-term rental property fast and for top dollar.`;

  const urlSlug = marketPathParam
    ? `https://www.getchalet.com/sell-your-str/${marketPathParam}`
    : "https://www.getchalet.com/sell-your-str";
  const filteredListings = listings.filter(
    (listing) =>
      listing.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      listing.location.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <>
      <Helmet>
        <link rel="canonical" href={urlSlug} />
        <title>{titleString}</title>
        <meta property="og:type" content="website" />
        <meta name="description" content={descriptionString} />
        <meta property="og:title" content={titleString} />
        <meta property="og:description" content={descriptionString} />
        <meta property="og:image" content="" />
        <meta property="og:url" content={urlSlug} />
        <meta property="og:site_name" content="Chalet" />
      </Helmet>
      <Container ref={bodyRef as any}>
        <div
          style={{
            // maxWidth: mobileSize ? "100%" : "50%",
            paddingRight: mobileSize ? "0" : "4rem"
          }}
        >
          <div className="first">
            <h1 style={{ lineHeight: mobileSize ? "2.6rem" : "4.8rem" }}>
              Sell your short-term rental property with{" "}
              <Text
                style={{
                  display: "block",
                  // fontSize: mobileSize ? "2rem" : "initial",
                  fontWeight: 400,
                  paddingBottom: mobileSize ? "1rem" : "2rem",
                  whiteSpace: "initial"
                }}
              >
                {" "}
                <span
                  style={{
                    color: "var(--quinary)",
                    // display: mobileSize ? "block" : "inline-block",
                    // background: "var(--white)",
                    paddingLeft: mobileSize ? 0 : "0rem"
                    // display: "block"
                  }}
                  ref={el}
                />
              </Text>
            </h1>
          </div>
          <div className="third">
            <PopupButton
              id="ih3Ukxa2"
              // open="load"
              // data-tf-lazy="false"
              shareGaInstance="true"
              hidden={{
                market_of_interest:
                  LiveMarkets[marketPathParam] || "Not Specified",
                source_page: urlSlug,
                device_type: mobileSize ? "mobile" : "desktop",
                interested_in: "typeform:selling-your-str"
              }}
              // data-id="connect-with-realtor"
              style={{
                // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                backgroundColor: mobileSize
                  ? "var(--quinary)"
                  : "var(--quinary)",
                textTransform: "uppercase",
                color: "var(--white)",
                padding: "1rem",
                border: 0,
                fontWeight: 400,
                width: mobileSize ? "100%" : "initial",
                textDecoration: "none",
                display: mobileSize ? "block" : "initial",
                fontSize: "1rem",
                cursor: "pointer",
                textAlign: "center",
                boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                borderRadius: "0.25rem",
                // display: mobileSize ? "block" : "initial",
                margin: mobileSize ? "1rem auto" : "1rem"
              }}
              className="connect-with-realtor_popup_top"
            >
              Sell Your Airbnb Rental Now
            </PopupButton>
          </div>
          <div
            className="fourth"
            style={{
              display: mobileSize ? "none" : "none",
              marginTop: "5rem"
            }}
          >
            <h3 style={{ color: "var(--white)", textAlign: "center" }}>
              Buy
              <Text
                style={{
                  fontSize: mobileSize ? "2rem" : "1.6rem",
                  fontWeight: 400,
                  color: "var(--white)"
                }}
              >
                &nbsp;the best Short-Term Rental investments
                <Text
                  style={{
                    fontSize: mobileSize ? "2rem" : "1.6rem",
                    textAlign: "center"
                  }}
                >
                  {marketPathParam && " in"}
                </Text>{" "}
                <Text
                  style={{
                    color: "var(--white)",
                    fontWeight: "500",
                    // textDecoration: "underline",
                    textAlign: "center",
                    fontSize: mobileSize ? "2rem" : "1.6rem"
                  }}
                >
                  {LiveMarkets[marketPathParam]}
                </Text>
              </Text>
              &nbsp;with top real estate agents
              <Text
                // block
                style={{
                  fontSize: mobileSize ? "2rem" : "1.6rem",
                  fontWeight: 500,
                  color: "var(--white)",
                  textAlign: "center"
                  // background: "var(--primary)",
                  // padding: "1rem",
                  // marginTop: "-1.75rem"
                }}
              >
                {!marketPathParam && " in "}{" "}
                {!marketPathParam && (
                  <a
                    style={{
                      textAlign: "center",
                      color: "var(--quaternary)",
                      background: "rgba(255,255,255,0.3)",
                      padding: "0.5rem",
                      marginTop: "0.2rem",
                      borderRadius: "0.5rem",
                      display: "block"
                    }}
                    href="#locations"
                  >
                    most US markets
                  </a>
                )}
              </Text>
            </h3>
          </div>
        </div>
        <div style={{ display: "block", maxWidth: "50%" }}>
          <Image
            src={`${process.env.PUBLIC_URL}/images/partner-realtor-musculine.png`}
            alt="Chalet Partner Real Estate Agents With STR Investing Expertise"
            $mobileSize={mobileSize}
            style={{
              gridRowStart: mobileSize ? "1" : "inherit",
              maxWidth: mobileSize ? "100%" : "300px",
              display: mobileSize ? "none" : "block",
              borderRadius: "0.25rem"
            }}
          />
        </div>
      </Container>
      {/* <Section
        id="home-statistics"
        style={{
          backgroundImage: `url(&quot;${process.env.PUBLIC_URL}/images/radial-gradient.webp&apos;)`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat"
        }}
      >
        <div className="stats">
          <span id="alt-red">Connected Investors With</span>
          <span id="highlight">100M+</span>
          <span id="alt-black">in Short-Term Rental Assets</span>
        </div>
        <VerticalDivider name="verdiv" />
        <HorizontalDivider name="hordiv" />
        <div className="stats">
          <span id="alt-black">Short-Term Rental Partners</span>
          <span id="highlight">All 50 states</span>
          <span id="alt-black">200+ Markets</span>
        </div>
        <VerticalDivider name="verdiv" />
        <HorizontalDivider name="hordiv" />
        <div className="stats">
          <span id="alt-red">Analyzing</span>
          <span id="highlight">1M+</span>
          <span id="alt-red">short-term rentals</span>
        </div>
      </Section> */}
      <Group id="howitworks">
        <Section
          style={{
            boxShadow: mobileSize ? "none" : "inherit"
            // maxWidth: "100%"
          }}
        >
          <Header size={2}>How It Works</Header>
          <Flex
            style={{
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: "1rem"
            }}
          >
            {[
              {
                icon: "analyze-your-property.webp",
                heading: "1. Analyze Market Readiness",
                subheading: (
                  <>
                    Use our{" "}
                    <Link to="/analytics/str-calculator" target="_blank">
                      AI tools
                    </Link>{" "}
                    to assess if it&apos;s the right time to sell and highlight
                    key aspects of your property.
                  </>
                )
              },
              {
                icon: "match-with-chalet-realtor.webp",
                heading: "2. Match with an STR Realtor",
                subheading:
                  "We connect you with a top-rated realtor specializing in short-term rentals in your area."
              },
              {
                icon: "maximize-listing-views.webp",
                heading: "3. Maximize Exposure",
                subheading: (
                  <>
                    Get free promotion across{" "}
                    <Link to="/airbnbs-for-sale" target="_blank">
                      our platform
                    </Link>{" "}
                    social media, and our large network of STR investors.
                  </>
                )
              },
              {
                icon: "acquisition.webp",
                heading: "4. Attract Buyers",
                subheading:
                  "Targeted exposure ensures your property reaches qualified buyers."
              },
              {
                icon: "offer.webp",
                heading: "5. Reinvest",
                subheading:
                  "Looking to reinvest? We'll help you with 1031 exchanges and your next STR purchase."
              }
            ].map((card) => (
              <div
                key={card.heading}
                style={{
                  flex: mobileSize ? "1 1 300px" : "1 1 200px",
                  maxWidth: "400px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Card
                  icon={card.icon}
                  heading={card.heading}
                  subheading={card.subheading}
                />
              </div>
            ))}
          </Flex>
          <SectionFooter $mobileSize={mobileSize}>
            <PopupButton
              id="ih3Ukxa2"
              // open="load"
              // data-tf-lazy="false"
              shareGaInstance="true"
              hidden={{
                market_of_interest:
                  LiveMarkets[marketPathParam] || "Not Specified",
                source_page: urlSlug,
                device_type: mobileSize ? "mobile" : "desktop",
                interested_in: "typeform:selling-your-str"
              }}
              // data-id="connect-with-realtor"
              style={{
                // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                background: mobileSize
                  ? "var(--linearGradient)"
                  : "var(--linearGradient)",
                textTransform: "uppercase",
                color: "var(--white)",
                padding: "1rem 4rem",
                border: 0,
                fontWeight: 400,
                width: mobileSize ? "100%" : "initial",
                textDecoration: "none",
                display: mobileSize ? "block" : "block",
                fontSize: "1rem",
                cursor: "pointer",
                textAlign: "center",
                boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                borderRadius: "0.25rem",
                // display: mobileSize ? "block" : "initial",
                margin: mobileSize ? "1rem auto" : "1rem auto"
              }}
              className="connect-with-realtor_popup_top"
            >
              Get Started Now
            </PopupButton>
          </SectionFooter>
        </Section>
      </Group>
      <Group
        style={{
          // background:
          //   "radial-gradient(4291.36% 46161.46% at 2886.22% -7976.07%, #DB005F 36.46%, #2F354B 67.71%, #FB929D 71.35%)",
          // background: "var(--radialGradient)",
          background: `url("${process.env.PUBLIC_URL}/images/markets-bg.webp")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          maxWidth: "100%"
        }}
      >
        <Section
          style={{
            boxShadow: "none",
            background: "none"

            // maxWidth: "100%"
          }}
        >
          <Header size={2} color={Color.primary}>
            Who We Are
          </Header>
          <Header
            style={{
              maxWidth: mobileSize ? "100%" : "85vw",
              //   fontWeight: 400,
              margin: "0rem auto 3rem",
              display: mobileSize ? "none" : "block"
            }}
            size={3}
            color={Color.eighth}
          >
            Chalet is more than just a real estate platform—we combine
            cutting-edge data analysis with expert human insights to deliver
            real results.
          </Header>

          {/* <HR $color={Color.primary} /> */}
          <SplitView
            split="3:2"
            style={{
              alignItems: "start",
              maxWidth: mobileSize ? "100%" : "95vw",
              margin: "0 auto"
            }}
          >
            <div
              style={{
                gridRowStart: mobileSize ? "2" : "inherit",
                paddingTop: 0
              }}
            >
              <Header size={3} color={Color.primary}>
                Meet Chalet&apos;s founders, Ashley & Damir Durmo
              </Header>
              {/* <Text
                block
                align="left"
                style={{
                  lineHeight: "2.5rem",
                  maxWidth: mobileSize ? "100%" : "85vw",
                  margin: "0 auto 2rem"
                }}
                color={Color.primary}
              >
                Backed by a team of experienced short-term rental hosts and real
                estate professionals, we use a data-driven approach to help
                investors thrive. We also connect STR investors with top vendors
                in the industry, ensuring access to the best tools, services,
                and support for a seamless investment journey.
              </Text> */}
              <Text
                block
                align="left"
                // style={{ lineHeight: "2.5rem" }}
                color={Color.primary}
              >
                We began our short-term rental journey as tech industry
                employees, inspired by the Airbnb model. After our first hosting
                experience, we saw the need for a central hub to connect
                investors with top STR tools, resources, and providers. This led
                to the creation of Chalet, a one-stop shop for building STR
                portfolios.
              </Text>
              <HR $color={Color.primary} />
              <Text
                block
                align="left"
                // style={{ lineHeight: "2.5rem" }}
                color={Color.primary}
              >
                As experienced hosts, data experts, and owners of Chalet Realty,
                we’ve evolved into industry leaders. Our mission is to simplify
                your investment process by connecting you with the right STR
                tools and professionals.
              </Text>
              {/* <HR $color={Color.primary} /> */}
              <Text
                block
                align="left"
                // style={{ lineHeight: "2.5rem" }}
                color={Color.primary}
              >
                Our referral services are free, and we only earn when you engage
                with our recommended providers.
              </Text>
            </div>
            <Image
              src={`${process.env.PUBLIC_URL}/images/durmos.webp`}
              alt="Chalet Founders"
              $mobileSize={mobileSize}
              style={{
                display: "block",
                gridRowStart: mobileSize ? "1" : "inherit",
                justifyContent: "right",
                margin: "0 auto",
                padding: "0rem",
                borderRadius: "0.5rem",
                maxWidth: mobileSize ? "100vw" : "100%"
              }}
            />
          </SplitView>
          <SectionFooter $mobileSize={mobileSize}>
            <PopupButton
              id="ih3Ukxa2"
              // open="load"
              // data-tf-lazy="false"
              shareGaInstance="true"
              hidden={{
                market_of_interest:
                  LiveMarkets[marketPathParam] || "Not Specified",
                source_page: urlSlug,
                device_type: mobileSize ? "mobile" : "desktop",
                interested_in: "typeform:selling-your-str"
              }}
              // data-id="connect-with-realtor"
              style={{
                // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                background: mobileSize
                  ? "var(--linearGradient)"
                  : "var(--linearGradient)",
                textTransform: "uppercase",
                color: "var(--white)",
                padding: "1rem 4rem",
                border: 0,
                fontWeight: 400,
                width: mobileSize ? "100%" : "initial",
                textDecoration: "none",
                display: mobileSize ? "block" : "block",
                fontSize: "1rem",
                cursor: "pointer",
                textAlign: "center",
                boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                borderRadius: "0.25rem",
                // display: mobileSize ? "block" : "initial",
                margin: mobileSize ? "1rem auto" : "1rem auto"
              }}
              className="connect-with-realtor_popup_top"
            >
              Get Started Now
            </PopupButton>
          </SectionFooter>
        </Section>
      </Group>
      <Group
        style={{
          background: "var(--white)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover"
        }}
      >
        <Section
          style={{
            maxWidth: "100%",
            background: "none",
            boxShadow: "none"
          }}
        >
          <Header
            // style={{ fontWeight: 400, color: "var(--white)" }}
            size={2}
            color={Color.primary}
          >
            Our Data & Methodology
          </Header>
          <SplitView
            split="1:1"
            style={{
              //   alignItems: "start",
              maxWidth: mobileSize ? "100%" : "95vw",
              margin: "0 auto",
              alignItems: "center"
              // margin: "0 auto"
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: mobileSize ? "center" : "left",
                gridRowStart: mobileSize ? "1" : "inherit"
              }}
            >
              <Image
                src={`${process.env.PUBLIC_URL}/images/chalet_analytics.webp`}
                alt="$100M in STR Assets Connected with Investors, 1M+ Short-Term Rentals Analyzed, 200+ Airbnb Rental Markets"
                style={{
                  padding: "0rem",
                  borderRadius: "0.25rem",
                  maxWidth: mobileSize ? "100%" : "100%",
                  width: "100%"
                }}
                $mobileSize={mobileSize}
              />
            </div>
            <div
              style={{
                gridRowStart: mobileSize ? "2" : "inherit",
                paddingBottom: mobileSize ? "inherit" : "0",
                paddingTop: mobileSize ? "inherit" : "0"
              }}
            >
              <Text block align="left" color={Color.primary}>
                At Chalet, our{" "}
                <Link to="/analytics/overview/" target="_blank">
                  AI-driven tools
                </Link>{" "}
                and market insights give you a unique edge when pricing,
                marketing, and selling short-term rental properties.
              </Text>
              <HR $color={Color.primary} />
              <Text block align="left" color={Color.primary}>
                We believe modern technology, including AI, should be
                customizable for niche markets like short-term real estate,
                helping investors make smarter decisions with personalized
                insights.
              </Text>
            </div>
          </SplitView>
          <SectionFooter $mobileSize={mobileSize}>
            <PopupButton
              id="ih3Ukxa2"
              // open="load"
              // data-tf-lazy="false"
              shareGaInstance="true"
              hidden={{
                market_of_interest:
                  LiveMarkets[marketPathParam] || "Not Specified",
                source_page: urlSlug,
                device_type: mobileSize ? "mobile" : "desktop",
                interested_in: "typeform:selling-your-str"
              }}
              // data-id="connect-with-realtor"
              style={{
                // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                background: mobileSize
                  ? "var(--linearGradient)"
                  : "var(--linearGradient)",
                textTransform: "uppercase",
                color: "var(--white)",
                padding: "1rem 4rem",
                border: 0,
                fontWeight: 400,
                width: mobileSize ? "100%" : "initial",
                textDecoration: "none",
                display: mobileSize ? "block" : "block",
                fontSize: "1rem",
                cursor: "pointer",
                textAlign: "center",
                boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                borderRadius: "0.25rem",
                // display: mobileSize ? "block" : "initial",
                margin: mobileSize ? "1rem auto" : "1rem auto"
              }}
              className="connect-with-realtor_popup_top"
            >
              Get Started Now
            </PopupButton>
          </SectionFooter>
        </Section>
      </Group>
      <Group
        style={{
          background: `url("${process.env.PUBLIC_URL}/images/blue-fade.webp")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover"
        }}
      >
        <Section
          style={{
            maxWidth: "100%",
            background: "none",
            boxShadow: "none"
          }}
        >
          <Header
            // style={{ fontWeight: 400, color: "var(--white)" }}
            size={2}
            color={Color.primary}
          >
            Our Results
          </Header>
          <SplitView
            split="2:3"
            style={{
              //   alignItems: "start",
              maxWidth: mobileSize ? "100%" : "95vw",
              margin: "0 auto",
              alignItems: "center"
              // margin: "0 auto"
            }}
          >
            <div
              style={{
                gridRowStart: mobileSize ? "2" : "inherit",
                paddingBottom: mobileSize ? "inherit" : "0",
                paddingTop: mobileSize ? "inherit" : "0"
              }}
            >
              <Text block align="left" color={Color.primary}>
                With a proven track record of quickly matching sellers with
                qualified buyers, Chalet helps properties close faster and at
                competitive prices.
              </Text>
              <HR $color={Color.quaternary} />
              <Text block align="left" color={Color.primary}>
                We&apos;ve partnered with short-term rental experts in over{" "}
                <span className="bold" style={{ fontWeight: 500 }}>
                  200 markets
                </span>{" "}
                across all{" "}
                <span className="bold" style={{ fontWeight: 500 }}>
                  50 states
                </span>
                , analyzing more than{" "}
                <span className="bold" style={{ fontWeight: 500 }}>
                  1 million rentals{" "}
                </span>
                and connecting investors with over{" "}
                <span className="bold" style={{ fontWeight: 500 }}>
                  $100 million in STR assets
                </span>
                , making Chalet the{" "}
                <span className="bold" style={{ fontWeight: 500 }}>
                  #1 partner for selling your rental property
                </span>
                .
              </Text>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: mobileSize ? "center" : "left",
                gridRowStart: mobileSize ? "1" : "inherit"
              }}
            >
              <Image
                src={`${process.env.PUBLIC_URL}/images/our-results.webp`}
                alt="$100M in STR Assets Connected with Investors, 1M+ Short-Term Rentals Analyzed, 200+ Airbnb Rental Markets"
                style={{
                  padding: "0rem",
                  borderRadius: "0.25rem",
                  maxWidth: mobileSize ? "100%" : "100%",
                  width: "100%"
                }}
                $mobileSize={mobileSize}
              />
            </div>
          </SplitView>
          <SectionFooter $mobileSize={mobileSize}>
            <PopupButton
              id="ih3Ukxa2"
              // open="load"
              // data-tf-lazy="false"
              shareGaInstance="true"
              hidden={{
                market_of_interest:
                  LiveMarkets[marketPathParam] || "Not Specified",
                source_page: urlSlug,
                device_type: mobileSize ? "mobile" : "desktop",
                interested_in: "typeform:selling-your-str"
              }}
              // data-id="connect-with-realtor"
              style={{
                // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                background: mobileSize
                  ? "var(--linearGradient)"
                  : "var(--linearGradient)",
                textTransform: "uppercase",
                color: "var(--white)",
                padding: "1rem 4rem",
                border: 0,
                fontWeight: 400,
                width: mobileSize ? "100%" : "initial",
                textDecoration: "none",
                display: mobileSize ? "block" : "block",
                fontSize: "1rem",
                cursor: "pointer",
                textAlign: "center",
                boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                borderRadius: "0.25rem",
                // display: mobileSize ? "block" : "initial",
                margin: mobileSize ? "1rem auto" : "1rem auto"
              }}
              className="connect-with-realtor_popup_top"
            >
              Get Started Now
            </PopupButton>
          </SectionFooter>
        </Section>
      </Group>
      {/* New group with 4 listings */}
      <Group>
        <Section
          style={{
            boxShadow: mobileSize ? "none" : "inherit"
            // maxWidth: "100%"
          }}
        >
          <Header size={2}>
            Feature Your STR on Our Airbnb For Sale Platform
          </Header>
          <Text align="center" block>
            One of the key benefits of partnering with Chalet is exclusive
            access to our Airbnb For Sale platform. This section of our website
            is dedicated solely to active short-term rental properties,
            providing targeted exposure to serious STR investors.
          </Text>

          <Flex
            style={{
              flexWrap: mobileSize ? "wrap" : "nowrap",
              justifyContent: "space-between",
              gap: "2rem"
            }}
          >
            {filteredListings.slice(0, 4).map((listing) => (
              <ListingCard key={listing.id} listing={listing} />
            ))}
          </Flex>
          <Text style={{ marginTop: "2rem" }} align="center" block>
            Every visitor to GetChalet.com has access to this platform, allowing
            us to connect your property directly with qualified buyers looking
            to invest in short-term rentals.
          </Text>
        </Section>
        <SectionFooter $mobileSize={mobileSize}>
          <PopupButton
            id="ih3Ukxa2"
            // open="load"
            // data-tf-lazy="false"
            shareGaInstance="true"
            hidden={{
              market_of_interest:
                LiveMarkets[marketPathParam] || "Not Specified",
              source_page: urlSlug,
              device_type: mobileSize ? "mobile" : "desktop",
              interested_in: "typeform:selling-your-str"
            }}
            // data-id="connect-with-realtor"
            style={{
              // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
              background: mobileSize
                ? "var(--linearGradient)"
                : "var(--linearGradient)",
              textTransform: "uppercase",
              color: "var(--white)",
              padding: "1rem 4rem",
              border: 0,
              fontWeight: 400,
              width: mobileSize ? "100%" : "initial",
              textDecoration: "none",
              display: mobileSize ? "block" : "block",
              fontSize: "1rem",
              cursor: "pointer",
              textAlign: "center",
              boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
              borderRadius: "0.25rem",
              // display: mobileSize ? "block" : "initial",
              margin: mobileSize ? "1rem auto" : "1rem auto"
            }}
            className="connect-with-realtor_popup_top"
          >
            Get Started Now
          </PopupButton>
        </SectionFooter>
      </Group>
      <HR />
      <Group
        style={{
          //   paddingBottom: "0rem",
          maxWidth: "100%",
          //   paddingTop: "0rem",
          //   backgroundImage: `url("${process.env.PUBLIC_URL}/images/market-bg.webp")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover"
        }}
      >
        <Section
          style={{
            boxShadow: "none",
            // maxWidth: "100%",
            background: "none",
            marginBottom: mobileSize ? "initial" : "4rem"
          }}
        >
          <Header
            size={2}
            style={{ marginBottom: "0rem", paddingBottom: "0rem" }}
          >
            What Our Partners Are Saying About Chalet
          </Header>
          <Header
            size={3}
            color={Color.eighth}
            style={{ display: mobileSize ? "none" : "block", paddingTop: 0 }}
          >
            Here&apos;s what recent clients and realtors have to say about their
            experiences working with Chalet
          </Header>
          <Flex
            style={{
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: "4rem",
              marginTop: "2rem"
            }}
          >
            <div
              style={{
                flex: "1 1 200px",
                maxWidth: "400px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Image
                src={`${process.env.PUBLIC_URL}/images/review-1.webp`}
                alt="Review from Jimmy O, Short-term rental Owner"
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "8px"
                }}
              />
            </div>
            <div
              style={{
                flex: "1 1 200px",
                maxWidth: "400px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Image
                src={`${process.env.PUBLIC_URL}/images/review-2.webp`}
                alt="Review from Ben S, STR Investor"
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "8px"
                }}
              />
            </div>
            <div
              style={{
                flex: "1 1 200px",
                maxWidth: "400px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Image
                src={`${process.env.PUBLIC_URL}/images/review-3.webp`}
                alt="Review from Justin B, STR Investor"
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "8px"
                }}
              />
            </div>
          </Flex>
          <SectionFooter $mobileSize={mobileSize}>
            <PopupButton
              id="ih3Ukxa2"
              // open="load"
              // data-tf-lazy="false"
              shareGaInstance="true"
              hidden={{
                market_of_interest:
                  LiveMarkets[marketPathParam] || "Not Specified",
                source_page: urlSlug,
                device_type: mobileSize ? "mobile" : "desktop",
                interested_in: "typeform:selling-your-str"
              }}
              // data-id="connect-with-realtor"
              style={{
                // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                background: mobileSize
                  ? "var(--linearGradient)"
                  : "var(--linearGradient)",
                textTransform: "uppercase",
                color: "var(--white)",
                padding: "1rem 4rem",
                border: 0,
                fontWeight: 400,
                width: mobileSize ? "100%" : "initial",
                textDecoration: "none",
                display: mobileSize ? "block" : "block",
                fontSize: "1rem",
                cursor: "pointer",
                textAlign: "center",
                boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                borderRadius: "0.25rem",
                // display: mobileSize ? "block" : "initial",
                margin: mobileSize ? "1rem auto" : "1rem auto"
              }}
              className="connect-with-realtor_popup_top"
            >
              Get Started Now
            </PopupButton>
          </SectionFooter>
        </Section>
      </Group>

      <Group
        style={{
          position: "relative"
        }}
      >
        <Section
          style={{
            maxWidth: "100%",
            background: "var(--sixth)",
            boxShadow: "none"
          }}
        >
          <Header
            // style={{ fontWeight: 400, color: "var(--white)" }}
            size={2}
            color={Color.white}
            style={{ zIndex: 200, position: "relative" }}
          >
            Connect with a Short-Term Rental Seller Agent Now
          </Header>
          {/* <Image
            src={`${process.env.PUBLIC_URL}/images/vacation_home.webp`}
            alt="Airbnb Rental Investor Guides. Free Market Reports. Median Revenue, ADR, Occupacny rates"
            style={{
              padding: "0rem",
              position: "absolute",
              left: 0,
              top: 0,
              opacity: "0.1",
              // zIndex: "0",
              // right: 0,
              // borderRadius: "2rem",
              maxWidth: mobileSize ? "100%" : "100%",
              width: "100%"
            }}
            $mobileSize={mobileSize}
          /> */}
          <div
            style={{
              gridRowStart: mobileSize ? "2" : "inherit",
              paddingBottom: mobileSize ? "inherit" : "0",
              paddingTop: mobileSize ? "inherit" : "0",
              position: "relative",
              zIndex: 1
            }}
          >
            <Text block align="center" color={Color.white}>
              Instantly connect with an Airbnb real estate agent and start
              realizing your investment goals in the{" "}
              {LiveMarkets[marketPathParam]} short-term rental market.
            </Text>
            <PopupButton
              id="ih3Ukxa2"
              hidden={{
                market_of_interest:
                  LiveMarkets[marketPathParam] || "Not Specified",
                source_page: urlSlug,
                interested_in: "typeform:selling-your-str",
                device_type: mobileSize ? "mobile" : "desktop"
              }}
              shareGaInstance="true"
              className="connect-with-realtor_popup_bottom"
              style={{
                // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                // backgroundColor: "var(--primary)",
                background: "var(--white)",
                textTransform: "uppercase",
                display: "block",
                color: "var(--quinary)",
                padding: "1rem",
                border: 0,
                width: mobileSize ? "100%" : "initial",
                textDecoration: "none",
                // display: mobileSize ? "block" : "initial",
                fontSize: "1rem",
                cursor: "pointer",
                textAlign: "center",
                boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                borderRadius: "0.25rem",
                // display: mobileSize ? "block" : "initial",
                margin: mobileSize ? "1rem auto" : "1rem auto"
              }}
            >
              Connect With An Airbnb Realtor
            </PopupButton>
          </div>
        </Section>
      </Group>
      <Group
        style={{
          background: `url("${process.env.PUBLIC_URL}/images/markets-bg.webp")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover"
        }}
      >
        <Section
          style={{
            // maxWidth: "100%",
            background: "none",
            boxShadow: "none"
          }}
        >
          <Header
            // style={{ fontWeight: 400, color: "var(--white)" }}
            size={2}
            color={Color.primary}
          >
            Additional Resources for Sellers
          </Header>
          <Header size={3} style={{ paddingTop: 0 }} color={Color.primary}>
            Looking to sell your short-term rental? Chalet offers a suite of
            resources to help you every step of the way.
          </Header>
          <List
            type="disclosure-closed"
            items={[
              <Link
                to="/blog/the-ultimate-guide-to-selling-your-short-term-rental/"
                target="_blank"
              >
                Guide to Selling STR
              </Link>,
              <Link
                to="/blog/looking-to-sell-heres-10-ways-to-maximize-views-to-your-listing-and-improve-the-quality-of-your-buyer-leads/"
                target="_blank"
              >
                Looking to sell? Here&apos;s how to maximize views to your
                listing and guarantee qualified buyers
              </Link>,
              <Link
                to="/blog/the-fed-cut-interest-rates-by-0-5-heres-what-it-means-for-airbnb-sellers/"
                target="_blank"
              >
                The fed cut interest rates by 0.5%! Here&apos;s what it means
                for sellers
              </Link>
            ]}
          />
          <SectionFooter $mobileSize={mobileSize} />
        </Section>
      </Group>
      <FAQSection>
        <Header size={2}>Frequently Asked Questions</Header>
        <div className="faq-item">
          <Header size={2}>🔄 I am interested, what are the next steps?</Header>
          <List
            type="number"
            items={[
              "Match with an STR Realtor: We connect you with a top-rated realtor specializing in short-term rentals in your area.",
              "Maximize Exposure: Your Chalet partner Realtor will work with us to promote your property across our platform, social media, and our network of STR investors.",
              "Attract Buyers: This targeted exposure ensures your property reaches qualified buyers with aims to help streamline your selling process.",
              "Reinvest: Looking to reinvest? We'll help you with 1031 exchanges and your next STR purchase. Reach back out to Chalet to pair with a Chalet Parter Relator in the market you're looking to buy."
            ]}
          />
          <PopupButton
            id="ih3Ukxa2"
            // open="load"
            // data-tf-lazy="false"
            shareGaInstance="true"
            hidden={{
              market_of_interest:
                LiveMarkets[marketPathParam] || "Not Specified",
              source_page: urlSlug,
              device_type: mobileSize ? "mobile" : "desktop",
              interested_in: "typeform:selling-your-str"
            }}
            // data-id="connect-with-realtor"
            style={{
              // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
              backgroundColor: mobileSize ? "var(--quinary)" : "var(--quinary)",
              textTransform: "uppercase",
              color: "var(--white)",
              padding: "1rem",
              border: 0,
              fontWeight: 400,
              width: mobileSize ? "100%" : "initial",
              textDecoration: "none",
              display: mobileSize ? "block" : "block",
              fontSize: "1rem",
              cursor: "pointer",
              textAlign: "center",
              boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
              borderRadius: "0.25rem",
              // display: mobileSize ? "block" : "initial",
              margin: mobileSize ? "1rem auto" : "1rem auto"
            }}
            className="connect-with-realtor_popup_top"
          >
            Sell Your Airbnb Rental Now
          </PopupButton>
        </div>
        <div className="faq-item">
          <Header size={2}>
            🤝 Why should they trust us sell your rental in{" "}
            {LiveMarkets[marketPathParam]}?
          </Header>
          <Text>
            Our track record speaks for itself. We&apos;ve helped numerous
            property owners successfully sell their short-term rentals.
            Don&apos;t just take our word for it - check out our testimonials
            from satisfied clients who have experienced our expertise firsthand.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={2}>
            💰 How much does it cost to use Chalet&apos;s services in{" "}
            {LiveMarkets[marketPathParam]}?
          </Header>
          <Text>
            Our service is completely free to use. As a licensed real estate
            brokerage, we earn a referral commission from our partner Realtors,
            taking a percentage of the commission they agree upon with you. This
            means you never see any additional cost for using Chalet.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={2}>
            🏠 What makes Chalet&apos;s agents different from regular real
            estate agents?
          </Header>
          <Text>
            Our agents specialize in short-term rental properties. They
            understand the unique aspects of valuing and marketing these
            properties, including factors like rental income potential and local
            STR regulations. This expertise allows them to maximize the value of
            your property and find qualified buyers who understand the STR
            market.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={2}>
            📣 How do you market my property to other investors?
          </Header>
          <Text>
            We use a multi-channel approach to give your property maximum
            exposure to the right audience. Here&apos;s how we market your
            property
          </Text>
          <List
            items={[
              <Text>
                We share your listing with our email subscribers, one of the
                largest proprietary lists of Airbnb rental investors in the
                country.
              </Text>,
              <Text>
                Your property will be featured on our dedicated Airbnb
                properties for sale section on our website.
              </Text>,
              <Text>
                We promote featured listings through targeted social media
                campaigns and collaborate with our network of experienced real
                estate agents and their partners to find interested buyers.
              </Text>
            ]}
          />
        </div>
        <div className="faq-item">
          <Header size={2}>
            📅 Is there an optimal time to sell my short-term rental in{" "}
            {LiveMarkets[marketPathParam]}?
          </Header>
          <Text>
            The best time to sell can vary depending on your location and local
            market conditions. Generally, it&apos;s advantageous to list your
            property as early in the peak season as possible. This allows
            potential buyers to see the property&apos;s full earning potential.
            Our expert agents can help you determine the optimal timing for your
            specific situation.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={3}>
            📣 How do you market my property to other investors?
          </Header>
          <Text block>
            We use a multi-channel approach to ensure your property gets maximum
            exposure to the right audience. We share your listing with one of
            the largest proprietary email lists of Airbnb rental investors in
            the country.
          </Text>
          <Text block>
            Additionally, your property will be featured on our dedicated
            section for{" "}
            <a
              href="/airbnbs-for-sale"
              target="_blank"
              rel="noopener noreferrer"
            >
              Airbnb properties for sale on our website
            </a>
            . We also promote the listing through targeted social media
            campaigns and collaborate with our network of experienced real
            estate agents and their partners to find interested buyers.
          </Text>
          <Text block>
            This version flows smoothly and emphasizes the different channels
            you&apos;re using to reach potential buyers.
          </Text>
        </div>
      </FAQSection>
    </>
  );
};

export default SellingYourSTRForSocial;
