import React, { useCallback, useState } from "react";
import { Auth } from "aws-amplify";
import styled from "styled-components";
import {
  CognitoHostedUIIdentityProvider,
  CognitoUser
} from "@aws-amplify/auth";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Modal from "../../../components/Modal";
import Image from "../../../components/Image";
import Text from "../../../components/Text";
import SplitView from "../../../components/SplitView";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import { Color } from "../../../types";
import { AppContext } from "../../../components/AppContext";
import Flex from "../../../components/Flex";
import ConfirmSignup from "../../modals/LoginModal/ConfirmSignup";

// Add these type declarations at the top of the file
declare global {
  interface Window {
    FB: {
      init(options: {
        appId: string;
        cookie: boolean;
        xfbml: boolean;
        version: string;
      }): void;
      login(
        callback: (response: {
          status: string;
          authResponse: {
            accessToken: string;
            userID: string;
          };
        }) => void,
        options: { scope: string }
      ): void;
      api(
        path: string,
        params: { fields: string },
        callback: (response: { name: string; email: string }) => void
      ): void;
    };
    fbAsyncInit: () => void;
  }
}

const StyledModal = styled(Modal)`
  max-width: 90vw;
  overflow: auto;

  @media (max-width: 810px) {
    max-width: 90vw;
    overflow: auto;
  }
`;

const BackgroundImage = styled(Image)`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;

const ModalTitle = styled(Text)`
  text-align: center;
  color: var(--primary);
  // color: transparent;
  background: var(--radialGradient);
  background-clip: text;
  font-size: 1.8rem;
  font-weight: 400;
  padding: 2rem 2rem 0;
  width: 100%;
  @media (max-width: 768px) {
    padding: 2rem;
  }
`;

const CalloutList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  list-style-type: disclosure-closed;
  margin: 0rem 0rem 0rem 2rem;
  padding: 0;
`;

const CalloutItem = styled.li`
  font-weight: 400;
  font-size: 1.2rem;
  margin-bottom: 1rem;
`;

const CalloutItemTitle = styled.span`
  color: var(--primary);
  font-weight: 500;
  text-decoration: underline;
`;

const InputWithPadding = styled(Input)`
  margin-bottom: 0.5rem;
  padding: 0.5rem !important;
  width: 100% !important;
`;

const DisclaimerText = styled(Text)`
  display: flex;
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 1.3;
  margin-top: -1rem;
  margin-bottom: 1rem;
  padding-bottom: 0;
`;

const CenteredFlex = styled(Flex)`
  justify-content: center;
`;

const StyledSplitView = styled(SplitView)`
  & > *:first-child {
    padding: 2rem 1rem 2rem 2rem;
  }
  & > *:last-child {
    padding: 2rem 2rem 2rem 1rem;
  }
`;

export default function AnalyticsModal({
  ignoreTimeout,
  isOpen: propIsOpen,
  toggle: propToggle,
  suppressTimeout = false
}: {
  ignoreTimeout?: boolean;
  isOpen?: boolean;
  toggle?(): void;
  suppressTimeout?: boolean;
}) {
  const history = useHistory();
  const location = useLocation();
  const { market } = useParams<Record<string, string>>();
  const { setOpenLoginModal, user, setUser, mobileSize } =
    React.useContext(AppContext);
  const [isOpen, setIsOpen] = React.useState(propIsOpen || false);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [verificationCode, setVerificationCode] = React.useState("");
  const [error, setError] = useState<string | undefined>();
  const [confirmSignUp, setConfirmSignUp] = React.useState(false);
  const [thankYou, setThankYou] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasSeenModal, setHasSeenModal] = React.useState(() => {
    return localStorage.getItem("hasSeenAnalyticsModal") === "true";
  });

  const federatedSignIn = useCallback(async () => {
    try {
      const existingInfo = JSON.parse(
        localStorage.getItem("landingInfo") || "{}"
      );
      localStorage.setItem("prevLoc", history.location.pathname);

      await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google
      });
      const authenticatedUser = await Auth.currentAuthenticatedUser();

      // Get user attributes from authenticated user
      const userEmail = authenticatedUser.attributes.email;
      const userName = authenticatedUser.attributes.name;

      // Update landing info with user data from Google
      localStorage.setItem(
        "landingInfo",
        JSON.stringify({
          ...existingInfo,
          email: userEmail,
          name: userName
        })
      );

      setUser(authenticatedUser);
    } catch (err: any) {
      console.log("error signing up:", err);
    }
  }, []); // Remove email and name dependencies since we get them from authenticatedUser

  // Update the useEffect that handles the API call
  React.useEffect(() => {
    if (user) {
      const updateUserInfo = async () => {
        try {
          const landingInfo = JSON.parse(
            localStorage.getItem("landingInfo") || "{}"
          );

          // Get user attributes safely
          const userAttributes = (user as any).attributes || {};

          const updatedLandingInfo = {
            ...landingInfo, // This preserves all UTM params
            email: userAttributes.email || "",
            name: userAttributes.name || ""
          };

          await fetch(
            "https://yc800bmcu1.execute-api.us-east-1.amazonaws.com/v1/update-signup-contacts",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify(updatedLandingInfo)
            }
          );

          // Removed the localStorage.removeItem() call
        } catch (err) {
          console.error("Error updating user info:", err);
        }
      };

      updateUserInfo();
    }
  }, [user]);

  const onLoginClick = () => {
    setIsOpen(false);
    setOpenLoginModal(true);
  };

  const onSignUp = async () => {
    setIsLoading(true);
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      setError("Please use a correct email address!");
      setIsLoading(false);
      return;
    }
    try {
      // Get existing landing info
      const existingInfo = JSON.parse(
        localStorage.getItem("landingInfo") || "{}"
      );

      // Update landing info with user data
      localStorage.setItem(
        "landingInfo",
        JSON.stringify({
          ...existingInfo,
          email,
          name
        })
      );

      const { user: newUser } = await Auth.signUp({
        username: email,
        password,
        attributes: {
          name,
          email,
          "custom:title": "Placeholder",
          "custom:market": market || "Placeholder",
          "custom:other": location.pathname || "Placeholder",
          "custom:consent": "true"
        }
      });
      if (newUser) {
        setIsLoading(false);
        setConfirmSignUp(true);
        setUser(newUser);
        // setThankYou(true);
      }
    } catch (e: any) {
      setError(e.message);
      setIsLoading(false);
    }
  };

  const onConfirmSignUp = async () => {
    setIsLoading(true);
    try {
      await Auth.confirmSignUp(email, verificationCode);
      setConfirmSignUp(false);
      setThankYou(true);
      const userSignIn = await Auth.signIn(email, password);
      setUser(userSignIn);
      setIsLoading(false);
      setIsOpen(false);
      console.log("Auto sign-in success!", userSignIn);
    } catch (err) {
      setIsLoading(false);
      // eslint-disable-next-line no-console
      // eslint-disable-next-line
      console.log("error signing up:", err);
    }
  };

  const resendCode = async () => {
    setIsLoading(true);
    try {
      await Auth.resendSignUp(email);
      setIsLoading(false);
    } catch (err) {
      // eslint-disable-next-line
      // eslint-disable-next-line
      console.log("error resending code: ", err);
      setIsLoading(false);
    }
  };

  const toggle = () => {
    if (propToggle) {
      propToggle();
    } else {
      setIsOpen(false);
    }
    // Set the flag in localStorage when the modal is closed
    localStorage.setItem("hasSeenAnalyticsModal", "true");
    setHasSeenModal(true);
  };

  React.useEffect(() => {
    if (!user && !ignoreTimeout && !suppressTimeout) {
      const timeout = setTimeout(() => {
        setIsOpen(true);
      }, 12000);

      return () => {
        clearTimeout(timeout);
      };
    }
    return undefined;
  }, [ignoreTimeout, user, suppressTimeout]);

  React.useEffect(() => {
    setIsOpen(propIsOpen || false);
  }, [propIsOpen]);

  // Add Facebook SDK initialization
  React.useEffect(() => {
    // Load the Facebook SDK asynchronously
    (window as any).fbAsyncInit = function () {
      (window as any).FB.init({
        appId: "472037342554722",
        cookie: true,
        xfbml: true,
        version: "v21.0"
      });
    };

    // Load Facebook SDK
    (function (d: Document, s: string, id: string) {
      const js = d.createElement(s) as HTMLScriptElement;
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode?.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  // Add Facebook sign in function
  const facebookSignIn = useCallback(async () => {
    try {
      const existingInfo = JSON.parse(
        localStorage.getItem("landingInfo") || "{}"
      );
      localStorage.setItem("prevLoc", history.location.pathname);

      // Handle Facebook login
      window.FB.login(
        function (response) {
          if (response.status === "connected") {
            // Get user info from Facebook with explicit fields request
            window.FB.api("/me", { fields: "name,email" }, function (userInfo) {
              if (!userInfo.email) {
                console.error("No email provided from Facebook");
                return;
              }

              const updatedLandingInfo = {
                ...existingInfo,
                email: userInfo.email,
                name: userInfo.name
              };

              localStorage.setItem(
                "landingInfo",
                JSON.stringify(updatedLandingInfo)
              );

              // Sign in with Cognito using Facebook token
              Auth.federatedSignIn({
                provider: CognitoHostedUIIdentityProvider.Facebook,
                customState: JSON.stringify({
                  ...userInfo, // Include all user info
                  token: response.authResponse.accessToken,
                  custom: {
                    title: "Placeholder",
                    market: market || "Placeholder",
                    other: location.pathname || "Placeholder",
                    consent: "true"
                  }
                })
              }).then(() => {
                Auth.currentAuthenticatedUser()
                  .then((authenticatedUser) => {
                    setUser(authenticatedUser);
                  })
                  .catch((err) => {
                    console.log("Error getting authenticated user:", err);
                  });
              });
            });
          }
        },
        { scope: "public_profile,email" }
      );
    } catch (err) {
      console.log("error signing in with Facebook:", err);
    }
  }, [history, setUser, market, location.pathname]);

  return (
    <StyledModal
      toggle={toggle}
      isOpen={isOpen}
      // onClickOutside={false}
      // onClickOutside={!location.pathname.includes("/analytics")}
    >
      {/* <BackgroundImage src="/images/about2.webp" /> */}
      <Flex>
        {!thankYou && (
          <ModalTitle>
            Unlimited{" "}
            <span
              style={{ color: "var(--primary)", textDecoration: "underline" }}
            >
              Market Dashboards & Calculator
            </span>{" "}
            with a Free Account
          </ModalTitle>
        )}
      </Flex>
      {!confirmSignUp && !thankYou && (
        <StyledSplitView
          split="2:3"
          style={{ padding: mobileSize ? "1rem" : "2rem" }}
        >
          <div style={{ padding: mobileSize ? "1rem" : "initial" }}>
            <InputWithPadding
              name="name"
              placeholder="Name"
              value={name}
              onChange={(e: any) => {
                setName(e.target.value);

                if (error) {
                  setError(undefined);
                }
              }}
            />
            <InputWithPadding
              name="email"
              placeholder="Email"
              value={email}
              onChange={(e: any) => {
                setEmail(e.target.value);

                if (error) {
                  setError(undefined);
                }
              }}
            />
            <InputWithPadding
              name="password"
              placeholder="Password"
              type="password"
              value={password}
              onChange={(e: any) => {
                setPassword(e.target.value);

                if (error) {
                  setError(undefined);
                }
              }}
            />
            <DisclaimerText>
              By signing up, you agree to receive subsequent email and
              third-party marketing communications from Chalet pursuant to our
              Privacy Policy, which you may opt out of, or unsubscribe from at
              any time.
            </DisclaimerText>
            <Button
              block
              color={Color.linearGradient}
              data-id="analytics-modal-signup-button-email"
              style={{ fontWeight: "bold", width: "100%" }}
              onClick={onSignUp}
              isLoading={isLoading}
            >
              Sign Up
            </Button>
            <Button
              color={Color.primary}
              style={{ width: "100%", marginTop: "1rem" }}
              data-id="analytics-modal-signup-button-google"
              onClick={federatedSignIn}
            >
              <Image
                src="/images/google_logo_1.png"
                alt="google"
                style={{ marginRight: "0.5rem" }}
                height="27px"
                width="27px"
              />
              Sign up with Google
            </Button>
            {/* <Button
              color={Color.primary}
              style={{ width: "100%", marginTop: "1rem" }}
              data-id="analytics-modal-signup-button-facebook"
              onClick={facebookSignIn}
            >
              <i
                className="fa-brands fa-square-facebook fa-3x"
                style={{
                  color: "var(--white)",
                  height: "30px",
                  width: "30px",
                  paddingRight: "0.5rem",
                  fontSize: "2rem"
                }}
                role="button"
                aria-label="Facebook icon link"
                tabIndex={0}
              />
              Sign up with Facebook
            </Button> */}
            <CenteredFlex>
              <Text>
                Already a member?{" "}
                <Text
                  onClick={onLoginClick}
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  Log in
                </Text>
              </Text>
            </CenteredFlex>
            {error && (
              <Flex>
                <Text
                  color={Color.quaternary}
                  weight={400}
                  style={{ fontSize: "1.5rem" }}
                >
                  {error}
                </Text>
              </Flex>
            )}
          </div>

          <div style={{ display: mobileSize ? "none" : "block" }}>
            {/* <ModalSubTitle>
              Maximize Your Short-Term Rental Potential
            </ModalSubTitle> */}
            <CalloutList>
              <CalloutItem>
                <CalloutItemTitle>Airbnb Market Dashboards:</CalloutItemTitle>{" "}
                Unlimited access to detailed market analytics.
              </CalloutItem>
              <CalloutItem>
                <CalloutItemTitle>Market Comparison Guide:</CalloutItemTitle>{" "}
                Top 200 short-term rental markets sent to your inbox
              </CalloutItem>
              <CalloutItem>
                <CalloutItemTitle>Airbnb Calculator:</CalloutItemTitle>{" "}
                AI-powered tool for identifying top investment opportunities.
              </CalloutItem>
              <CalloutItem>
                <CalloutItemTitle>
                  Short-Term Rental AI Copilot:
                </CalloutItemTitle>{" "}
                Early access to personalized investment insights (Coming Soon).
              </CalloutItem>
              <CalloutItem>
                <CalloutItemTitle>STR Service Providers:</CalloutItemTitle> Free
                introduction to 100+ top-rated STR service providers.
              </CalloutItem>
            </CalloutList>
          </div>
        </StyledSplitView>
      )}
      {confirmSignUp && !thankYou && (
        <ConfirmSignup
          email={email}
          isLoading={isLoading}
          verificationcode={verificationCode}
          onChange={(e: any) => setVerificationCode(e.target.value)}
          setSubmitConfirmation={onConfirmSignUp}
          resendCode={resendCode}
        />
      )}
    </StyledModal>
  );
}
