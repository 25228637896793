import React from "react";
import styled from "styled-components";
import Section from "../components/Section";
import { Color } from "../types";

const Link = styled.a`
  color: var(--white);

  &:hover {
    color: var(--white);
    cursor: pointer;
  }
`;

const StyledHeading = styled.h1`
  // font-family: "Source Sans 3", Impact, "Franklin Gothic Bold", sans-serif;
`;

const Heading = ({ size, ...props }: any) => {
  return <StyledHeading as={`h${size}`} {...props} />;
};

const PrivacyPolicy = () => {
  return (
    <div
      style={{
        margin: "0 auto",
        maxWidth: "85vw",
        paddingTop: "208px"
      }}
    >
      <Section color={Color.white}>
        <Heading size={1}>Chalet Privacy Policy</Heading>
        <div>Last updated: April 14, 2021</div>
        <div>
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </div>
        <div>
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy. This Privacy Policy has been
          created with the help of the Privacy Policy Generator.
        </div>
        <Heading size={2}>Interpretation and Definitions</Heading>
        <Heading size={3}>Interpretation</Heading>
        <div>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </div>
        <Heading size={3}>Definitions</Heading>
        <div>For the purposes of this Privacy Policy:</div>
        <ul>
          <li>
            <strong>Account</strong> means a unique account created for You to
            access our Service or parts of our Service.
          </li>
          <li>
            <strong>Company</strong> (referred to as either &quot;the
            Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in
            this Agreement) refers to GetChalet Inc. , P.O. Box 140655,
            Edgewater, Colorado 80214, US.
          </li>
          <li>
            <strong>Cookies</strong> are small files that are placed on Your
            computer, mobile device or any other device by a website, containing
            the details of Your browsing history on that website among its many
            uses.
          </li>
          <li>
            <strong>Country</strong> refers to: Colorado, United States
          </li>
          <li>
            <strong>Device</strong> means any device that can access the Service
            such as a computer, a cellphone or a digital tablet.
          </li>
          <li>
            <strong>Personal Data</strong> is any information that relates to an
            identified or identifiable individual.
          </li>
          <li>
            <strong>Service</strong> refers to the Website.
          </li>
          <li>
            <strong>Service Provider</strong> means any natural or legal person
            who processes the data on behalf of the Company. It refers to
            third-party companies or individuals employed by the Company to
            facilitate the Service, to provide the Service on behalf of the
            Company, to perform services related to the Service or to assist the
            Company in analyzing how the Service is used.
          </li>
          <li>
            <strong>Third-party Social Media Service</strong> refers to any
            website or any social network website through which a User can log
            in or create an account to use the Service.
          </li>
          <li>
            <strong>Usage</strong> Data refers to data collected automatically,
            either generated by the use of the Service or from the Service
            infrastructure itself (for example, the duration of a page visit).
          </li>
          <li>
            <strong>Website</strong> refers to Chalet, accessible from{" "}
            <Link href="https://www.getchalet.com">
              https://www.getchalet.com
            </Link>
          </li>
          <li>
            <strong>You</strong> means the individual accessing or using the
            Service, or the company, or other legal entity on behalf of which
            such individual is accessing or using the Service, as applicable.
          </li>
        </ul>
        <Heading size={2}>Collecting and Using Your Personal Data</Heading>
        <Heading size={3}>Types of Data Collected</Heading>
        <Heading size={4}>Personal Data</Heading>
        <div>
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </div>
        <ul>
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Phone number</li>
          <li>Usage Data</li>
        </ul>
        <Heading size={4}>Usage Data</Heading>
        <div>Usage Data is collected automatically when using the Service.</div>
        <div>
          Usage Data may include information such as Your Device&apos;s Internet
          Protocol address (e.g. IP address), browser type, browser version, the
          pages of our Service that You visit, the time and date of Your visit,
          the time spent on those pages, unique device identifiers and other
          diagnostic data.
        </div>
        <div>
          When You access the Service by or through a mobile device, We may
          collect certain information automatically, including, but not limited
          to, the type of mobile device You use, Your mobile device unique ID,
          the IP address of Your mobile device, Your mobile operating system,
          the type of mobile Internet browser You use, unique device identifiers
          and other diagnostic data.
        </div>
        <div>
          We may also collect information that Your browser sends whenever You
          visit our Service or when You access the Service by or through a
          mobile device.
        </div>
        <Heading size={4}>
          Information from Third-Party Social Media Services
        </Heading>
        <div>
          The Company allows You to create an account and log in to use the
          Service through the following Third-party Social Media Services:
        </div>
        <ul>
          <li>Google</li>
          <li>Facebook</li>
          <li>Twitter</li>
        </ul>
        <div>
          If You decide to register through or otherwise grant us access to a
          Third-Party Social Media Service, We may collect Personal data that is
          already associated with Your Third-Party Social Media Service&apos;s
          account, such as Your name, Your email address, Your activities or
          Your contact list associated with that account.
        </div>
        <div>
          You may also have the option of sharing additional information with
          the Company through Your Third-Party Social Media Service&apos;s
          account. If You choose to provide such information and Personal Data,
          during registration or otherwise, You are giving the Company
          permission to use, share, and store it in a manner consistent with
          this Privacy Policy.
        </div>
        <Heading size={3}>Tracking Technologies and Cookies</Heading>
        <div>
          We use Cookies and similar tracking technologies to track the activity
          on Our Service and store certain information. Tracking technologies
          used are beacons, tags, and scripts to collect and track information
          and to improve and analyze Our Service. The technologies We use may
          include:
        </div>
        <ul>
          <li>
            Cookies or Browser Cookies. A cookie is a small file placed on Your
            Device. You can instruct Your browser to refuse all Cookies or to
            indicate when a Cookie is being sent. However, if You do not accept
            Cookies, You may not be able to use some parts of our Service.
            Unless you have adjusted Your browser setting so that it will refuse
            Cookies, our Service may use Cookies.
          </li>
          <li>
            Flash Cookies. Certain features of our Service may use local stored
            objects (or Flash Cookies) to collect and store information about
            Your preferences or Your activity on our Service. Flash Cookies are
            not managed by the same browser settings as those used for Browser
            Cookies. For more information on how You can delete Flash Cookies,
            please read &quot;Where can I change the settings for disabling, or
            deleting local shared objects?&quot; available{" "}
            <Link
              href={
                "https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main" +
                "_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_"
              }
            >
              here
            </Link>
            .
          </li>
          <li>
            Web Beacons. Certain sections of our Service and our emails may
            contain small electronic files known as web beacons (also referred
            to as clear gifs, pixel tags, and single-pixel gifs) that permit the
            Company, for example, to count users who have visited those pages or
            opened an email and for other related website statistics (for
            example, recording the popularity of a certain section and verifying
            system and server integrity).
          </li>
        </ul>
        <div>
          Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies.
          Persistent Cookies remain on Your personal computer or mobile device
          when You go offline, while Session Cookies are deleted as soon as You
          close Your web browser. Learn more about cookies:{" "}
          <Link href="https://www.freeprivacypolicy.com/blog/cookies/">
            Cookies: What Do They Do?
          </Link>
          .
        </div>
        <div>
          We use both Session and Persistent Cookies for the purposes set out
          below:
        </div>
        <ul>
          <li>
            <div>Necessary / Essential Cookies</div>
            <div>Type: Session Cookies</div>
            <div>Administered by: Us</div>
            <div>
              Purpose: These Cookies are essential to provide You with services
              available through the Website and to enable You to use some of its
              features. They help to authenticate users and prevent fraudulent
              use of user accounts. Without these Cookies, the services that You
              have asked for cannot be provided, and We only use these Cookies
              to provide You with those services.
            </div>
          </li>
          <li>
            <div>Cookies Policy / Notice Acceptance Cookies</div>
            <div>Type: Persistent Cookies</div>
            <div>Administered by: Us</div>
            <div>
              Purpose: These Cookies identify if users have accepted the use of
              cookies on the Website.
            </div>
          </li>
          <li>
            <div>Functionality Cookies</div>
            <div>Type: Persistent Cookies</div>
            <div>Administered by: Us</div>
            <div>
              {" "}
              Purpose: These Cookies allow us to remember choices You make when
              You use the Website, such as remembering your login details or
              language preference. The purpose of these Cookies is to provide
              You with a more personal experience and to avoid You having to
              re-enter your preferences every time You use the Website.
            </div>
          </li>
        </ul>
        <div>
          For more information about the cookies we use and your choices
          regarding cookies, please visit our Cookies Policy or the Cookies
          section of our Privacy Policy.
        </div>
        <Heading size={3}>Use of Your Personal Data</Heading>
        <div>The Company may use Personal Data for the following purposes:</div>
        <ul>
          <li>
            To provide and maintain our Service, including to monitor the usage
            of our Service.
          </li>
          <li>
            To manage Your Account: to manage Your registration as a user of the
            Service. The Personal Data You provide can give You access to
            different functionalities of the Service that are available to You
            as a registered user.
          </li>
          <li>
            For the performance of a contract: the development, compliance and
            undertaking of the purchase contract for the products, items or
            services You have purchased or of any other contract with Us through
            the Service.
          </li>
          <li>
            To contact You: To contact You by email, telephone calls, SMS, or
            other equivalent forms of electronic communication, such as a mobile
            application&apos;s push notifications regarding updates or
            informative communications related to the functionalities, products
            or contracted services, including the security updates, when
            necessary or reasonable for their implementation.
          </li>
          <li>
            To provide You with news, special offers and general information
            about other goods, services and events which we offer that are
            similar to those that you have already purchased or enquired about
            unless You have opted not to receive such information.
          </li>
          <li>
            To manage Your requests: To attend and manage Your requests to Us.
          </li>
          <li>
            For business transfers: We may use Your information to evaluate or
            conduct a merger, divestiture, restructuring, reorganization,
            dissolution, or other sale or transfer of some or all of Our assets,
            whether as a going concern or as part of bankruptcy, liquidation, or
            similar proceeding, in which Personal Data held by Us about our
            Service users is among the assets transferred.
          </li>
          <li>
            For other purposes: We may use Your information for other purposes,
            such as data analysis, identifying usage trends, determining the
            effectiveness of our promotional campaigns and to evaluate and
            improve our Service, products, services, marketing and your
            experience.
          </li>
        </ul>
        <div>
          We may share Your personal information in the following situations:
        </div>
        <ul>
          <li>
            With Service Providers: We may share Your personal information with
            Service Providers to monitor and analyze the use of our Service, to
            contact You.
          </li>
          <li>
            For business transfers: We may share or transfer Your personal
            information in connection with, or during negotiations of, any
            merger, sale of Company assets, financing, or acquisition of all or
            a portion of Our business to another company.
          </li>
          <li>
            With Affiliates: We may share Your information with Our affiliates,
            in which case we will require those affiliates to honor this Privacy
            Policy. Affiliates include Our parent company and any other
            subsidiaries, joint venture partners or other companies that We
            control or that are under common control with Us.
          </li>
          <li>
            With business partners: We may share Your information with Our
            business partners to offer You certain products, services or
            promotions.
          </li>
          <li>
            With other users: when You share personal information or otherwise
            interact in the public areas with other users, such information may
            be viewed by all users and may be publicly distributed outside. If
            You interact with other users or register through a Third-Party
            Social Media Service, Your contacts on the Third-Party Social Media
            Service may see Your name, profile, pictures and description of Your
            activity. Similarly, other users will be able to view descriptions
            of Your activity, communicate with You and view Your profile.
          </li>
          <li>
            With Your consent: We may disclose Your personal information for any
            other purpose with Your consent.
          </li>
        </ul>
        <Heading size={3}>Retention of Your Personal Data</Heading>
        <div>
          The Company will retain Your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </div>
        <div>
          The Company will also retain Usage Data for internal analysis
          purposes. Usage Data is generally retained for a shorter period of
          time, except when this data is used to strengthen the security or to
          improve the functionality of Our Service, or We are legally obligated
          to retain this data for longer time periods.
        </div>
        <Heading size={3}>Transfer of Your Personal Data</Heading>
        <div>
          Your information, including Personal Data, is processed at the
          Company&apos;s operating offices and in any other places where the
          parties involved in the processing are located. It means that this
          information may be transferred to — and maintained on — computers
          located outside of Your state, province, country or other governmental
          jurisdiction where the data protection laws may differ than those from
          Your jurisdiction.
        </div>
        <div>
          Your consent to this Privacy Policy followed by Your submission of
          such information represents Your agreement to that transfer.
        </div>
        <div>
          The Company will take all steps reasonably necessary to ensure that
          Your data is treated securely and in accordance with this Privacy
          Policy and no transfer of Your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of Your data and other personal information.
        </div>
        <Heading size={3}>Disclosure of Your Personal Data</Heading>
        <Heading size={4}>Business Transactions</Heading>
        <div>
          If the Company is involved in a merger, acquisition or asset sale,
          Your Personal Data may be transferred. We will provide notice before
          Your Personal Data is transferred and becomes subject to a different
          Privacy Policy.
        </div>
        <Heading size={4}>Law enforcement</Heading>
        <div>
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </div>
        <Heading size={4}>Other legal requirements</Heading>
        <div>
          The Company may disclose Your Personal Data in the good faith belief
          that such action is necessary to:
        </div>
        <ul>
          <li>Comply with a legal obligation</li>
          <li>Protect and defend the rights or property of the Company</li>
          <li>
            Prevent or investigate possible wrongdoing in connection with the
            Service
          </li>
          <li>
            Protect the personal safety of Users of the Service or the public
          </li>
          <li>Protect against legal liability</li>
        </ul>
        <Heading size={3}>Security of Your Personal Data</Heading>
        <div>
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security.
        </div>
        <Heading size={3}>Children&apos;s Privacy</Heading>
        <div>
          Our Service does not address anyone under the age of 13. We do not
          knowingly collect personally identifiable information from anyone
          under the age of 13. If You are a parent or guardian and You are aware
          that Your child has provided Us with Personal Data, please contact Us.
          If We become aware that We have collected Personal Data from anyone
          under the age of 13 without verification of parental consent, We take
          steps to remove that information from Our servers.
        </div>
        <div>
          If We need to rely on consent as a legal basis for processing Your
          information and Your country requires consent from a parent, We may
          require Your parent&apos;s consent before We collect and use that
          information.
        </div>
        <Heading size={3}>Links to Other Websites</Heading>
        <div>
          Our Service may contain links to other websites that are not operated
          by Us. If You click on a third party link, You will be directed to
          that third party&apos;s site. We strongly advise You to review the
          Privacy Policy of every site You visit.
        </div>
        <div>
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </div>
        <Heading size={3}>Changes to this Privacy Policy</Heading>
        <div>
          We may update Our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page.
        </div>
        <div>
          We will let You know via email and/or a prominent notice on Our
          Service, prior to the change becoming effective and update the
          &quot;Last updated&quot; date at the top of this Privacy Policy.
        </div>
        <div>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </div>
        <Heading size={3}>Contact Us</Heading>
        <div>
          If you have any questions about this Privacy Policy, You can contact
          us:
        </div>
        <ul>
          <li>By email: product@getchalet.com </li>
          <li>By mail: P.O. Box 140655, Edgewater, Colorado 80214, US</li>
        </ul>
      </Section>
    </div>
  );
};

export default PrivacyPolicy;
