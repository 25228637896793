import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import Text from "../components/Text";
import Field from "../components/Field";
import { OptionProp } from "../components/Select";
import Image from "../components/Image";
import useCookies from "../hooks/useCookies";
import Button from "../components/Button";
import { Color } from "../types";
import { AppContext } from "../components/AppContext";
import Flex from "../components/Flex";
import SplitView from "../components/SplitView";
import Section from "../components/Section";

function toTitleCase(str: string) {
  return str
    .toLowerCase()
    .replace("-", " ")
    .split(" ")
    .map((word: string) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

const defaultForm = {
  name: "",
  //   phone: "",
  email: "",
  //   interestedIn: "",
  consent: true
};
const STRUnderwritingTool = () => {
  const { getAllCookies } = useCookies();
  const { mobileSize } = useContext(AppContext);
  const [isContactUs, setContactUs] = useState(false);
  const [thankYou, setThankYou] = useState(false);
  function getMarketFromSlug(marketSlug: string) {
    // let marketFromSlug = '
    // eslint-disable-next-line
    console.log("makret slug beffore removal of -", marketSlug);
    let marketFromSlug = marketSlug
      ? toTitleCase(marketSlug.replace("-", " "))
      : "";
    // eslint-disable-next-line
    console.log("marketFromSlug before transform", marketFromSlug);
    if (
      [
        "fort-myers-fl",
        "lake-havasu-city-az",
        "panama-city-beach-fl",
        "pompano-beach-fl",
        "gulf-shores-al",
        "san-antonio-tx",
        "west-palm-beach-fl"
      ].includes(marketSlug)
    ) {
      marketFromSlug = marketFromSlug.replace(
        marketFromSlug.slice(-3),
        `, ${marketFromSlug.slice(-2).toUpperCase()}`
      );
    } else {
      marketFromSlug = marketFromSlug.replace(
        marketFromSlug.slice(-3),
        `, ${marketFromSlug.slice(-2).toUpperCase()}`
      );
      // eslint-disable-next-line
    console.log("its not three/four words", marketFromSlug);
    }
    // eslint-disable-next-line
    console.log("its three/fourds word slug after transform", marketFromSlug);
    return marketFromSlug;
  }
  const { search } = useLocation();
  // eslint-disable-next-line
    console.log("Search", search);

  const ConsentLabel = () => {
    return (
      <div
        style={{
          fontWeight: 300,
          fontSize: "0.7rem",
          marginTop: "-0.9rem",
          marginBottom: "2rem"
        }}
      >
        By submitting this form, you agree to receive subsequent emails and
        third-party marketing communications from Chalet pursuant to our{" "}
        <Link
          to="/privacy-policy"
          target="_blank"
          style={{
            cursor: "pointer",
            display: "inline-block",
            textDecoration: "underline"
          }}
        >
          Privacy Policy
        </Link>
        , which you may opt out of, or unsubscribe from, at any time.
      </div>
    );
  };

  const marketSearchQuery = search ? search.split("=")[1] : "";
  const marketOfInterest = getMarketFromSlug(marketSearchQuery);

  const [errors, setErrors] = useState<any>({
    name: false,
    // phone: false,
    email: false,
    // interestedIn: false,
    notes: false
  });
  const cookies: Record<string, string> = {};

  getAllCookies().forEach(([cookieName, val]) => {
    cookies[cookieName] = val;
  });
  const [form, setForm] = useState(defaultForm);
  const { name, email, consent } = form;
  useEffect(() => {
    async function addContact() {
      // eslint-disable-next-line
    console.log("name", name);
      // eslint-disable-next-line
    console.log("Consent", consent);
      try {
        const requestOptions = {
          method: "POST",
          // headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name,
            email,
            // phone,
            downloadReport: true,
            market: marketOfInterest,
            // interested_in: interestedIn,
            consent,
            ...cookies,
            sourcePage: "str-underwriting-tool"
          })
        };
        // const apiData = await API.post("customers", "/contacts", data);
        await fetch(
          "https://cbehz6zbjl.execute-api.us-west-2.amazonaws.com/v1/contacts",
          requestOptions
        );
        setThankYou(true);
      } catch (error) {
        // eslint-disable-next-line no-console
        // eslint-disable-next-line
    console.log("error scheduling consultation:", error);
      }
      setContactUs(false);
    }
    if (isContactUs) {
      setContactUs(true);
      addContact();
    }
  }, [isContactUs, form]);

  const allowContactUs = () => {
    const isEveryElementFilled = Object.entries(form).every(
      ([formName, value]: [string, string | boolean]) => {
        if (
          formName !== "password" &&
          formName !== "confirmpassword" &&
          formName !== "verificationcode" &&
          formName !== "other"
        ) {
          if (formName === "consent") {
            return value;
          }
          return value !== "";
        }

        return true;
      }
    );
    if (!isEveryElementFilled) {
      const newErrors = { ...errors };
      Object.entries(form).forEach(
        ([formName, value]: [string, string | boolean]) => {
          if (
            formName !== "password" &&
            formName !== "confirmpassword" &&
            formName !== "verificationcode" &&
            formName !== "other"
          ) {
            if (formName === "consent") {
              newErrors.consent = !consent;
            } else {
              newErrors[formName] = value === "";
            }
          }
        }
      );
      setErrors(newErrors);
    }
    return isEveryElementFilled;
  };

  const onChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.persist();
    const { name: n, value } = e.target as HTMLInputElement;
    // eslint-disable-next-line
    console.log("On change name", n);
    // eslint-disable-next-line
    console.log("On change value", value);
    let inputName = n;
    let inputValue = value;
    if (!inputName) {
      inputName = (e as any).name;
      inputValue = ((e as any).value as OptionProp).label as string;
    }
    setForm((prevState) => ({
      ...prevState,
      [inputName]: inputValue
    }));
  };
  return (
    <div style={{ paddingTop: mobileSize ? "120px" : "100px" }}>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.getchalet.com/download-report"
        />
        <title>Chalet | Free Airbnb Rental Deal Analyzer</title>
        <meta property="og:type" content="website" />
        <meta
          name="description"
          content="Download our Free Short-Term Rental Investment
          Underwriting Xcel Sheet and analyze Airbnb rental deals 
          like a pro. Calculate net income, cash on cash, gross 
          yields, cap rates, and investment value over time. Get 
          reliable projections for smarter investment decisions"
        />
        <meta
          property="og:title"
          content="Chalet | Free Airbnb Rental Deal Analyzer"
        />
        <meta
          property="og:description"
          content="Download our Free Short-Term Rental Investment
          Underwriting Xcel Sheet and analyze Airbnb rental deals 
          like a pro. Calculate net income, cash on cash, gross 
          yields, cap rates, and investment value over time. Get 
          reliable projections for smarter investment decisions"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.getchalet.com/str-undewriting-tool"
        />
        <meta property="og:site_name" content="Chalet" />
      </Helmet>
      <Section
        style={{
          background: mobileSize ? "var(--white)" : "var(--white)",
          boxShadow: "none"
        }}
      >
        <Header size={2}> Free Airbnb Rental Deal Underwriting Tool</Header>
        <div>
          <Text
            block
            align="left"

            // style={{ lineHeight: "2rem" }}
            // lineHeight="4.5rem"
          >
            If you&apos;re a real estate investor looking to analyze and
            underwrite Airbnb rental deals, you&apos;ve come to the right place.
            Our Excel-based underwriting tool helps you easily calculate key
            metrics such as net income, cash on cash, gross yields, cap rates,
            and investment value over time for any potential short-term rental
            investment property.
          </Text>
          <Text
            block
            align="left"
            style={{ display: mobileSize ? "none" : "block" }}
          >
            Using our investment underwriting tool is simple and user-friendly.
            All you have to do is input the necessary values from{" "}
            <Link to="/analytics/str-calculator">our Airbnb calculator</Link>{" "}
            tool or wherever else you are getting the numbers from, and our
            underwriting tool will do the rest. You&apos;ll receive accurate and
            customizable projections that help you make smart investment
            choices. Whether you&apos;re a seasoned investor or just starting
            out, our free investment underwriting tool is an essential tool for
            analyzing short-term rental properties.
          </Text>
        </div>
        <SplitView
          style={{
            // padding: mobileSize ? "1rem" : "2rem",
            // paddingTop: "1rem",
            // boxShadow: mobileSize ? "none" : "0 0 43px rgba(0, 0, 0, 0.1)",
            alignItems: "center",
            borderRadius: "0.25rem",
            backgroundColor: "rgba(255,255,255, 0.8)",
            maxWidth: mobileSize ? "100vw" : "85vw",
            margin: "1rem auto"
          }}
          split="2:3"
        >
          <div
            style={{
              paddingBottom: "1rem",
              gridRowStart: mobileSize ? "2" : "inherit",
              alignItems: "center"
            }}
          >
            {!thankYou && (
              <>
                <div
                  style={{ width: mobileSize ? "calc(100% - 0rem)" : "100%" }}
                >
                  <Field
                    name="name"
                    placeholder="Name"
                    type="text"
                    value={name}
                    invalid={errors.name}
                    onChange={onChange}
                  />
                  {/* <Field
                    name="phone"
                    placeholder="Phone"
                    type="text"
                    value={phone}
                    invalid={errors.phone}
                    onChange={onChange}
                  /> */}
                  <Field
                    name="email"
                    placeholder="Email"
                    type="email"
                    value={email}
                    invalid={errors.email}
                    onChange={onChange}
                  />
                  {/* <Select
                    name="interestedIn"
                    options={interestedInOptions}
                    style={{ fontSize: "1rem" }}
                    placeholder="I am interested in ..."
                    value={interestedInOptions.find(
                      (option) => option.label === interestedIn
                    )}
                    invalid={errors.interestedIn}
                    onChange={onChange}
                  /> */}
                  {/* <TextArea
                  name="other"
                  placeholder="Please let us know which market you are interested in and we will contact you soon"
                  style={{ minHeight: mobileSize ? "40px" : "128px" }}
                  value={notes}
                  onChange={onChange}
                  tabIndex={0}
                /> */}
                  <ConsentLabel />
                  {Object.values(errors).some((value) => value) && (
                    <Text
                      color={Color.danger}
                      style={{
                        display: "block",
                        padding: "0.5rem",
                        textAlign: "center",
                        width: "100%"
                      }}
                    >
                      Fields{" "}
                      {Object.entries(errors)
                        .map(([key, value]) => (value ? key : undefined))
                        .filter((str) => str !== undefined)
                        .join(", ")}{" "}
                      are missing values
                    </Text>
                  )}
                  <Button
                    isLoading={isContactUs}
                    style={{
                      marginBottom: "1rem",
                      width: "100%",
                      //   margin: 0 !important;
                      background:
                        "radial-gradient(4291.36% 46161.46% at 2886.22% -7976.07%,#BC6A81 36.46%,#695979 66.15%,#FB929D 71.35%)",
                      boxShadow: "0 0 32px rgb(0 0 0 / 15%)"
                    }}
                    onClick={() => allowContactUs() && setContactUs(true)}
                    data-id="modal_contact_us_message_us_button"
                  >
                    Download NOW
                  </Button>
                </div>
              </>
            )}
            {thankYou && (
              <Flex
                style={{
                  alignItems: "stretch",
                  flexDirection: "column",
                  padding: mobileSize ? "0.5rem" : "3rem",
                  textAlign: "center",
                  minHeight: "70vh"
                }}
              >
                <Text
                  weight={400}
                  color={Color.quaternary}
                  style={{ fontSize: "1.5rem" }}
                >
                  Thank you for contacting us!
                </Text>
                <Text style={{ fontSize: "1.5rem" }}>
                  A Chalet Market Specialist will reach out to you soon.
                </Text>
              </Flex>
            )}
          </div>
          <div style={{ gridRowStart: mobileSize ? "1" : "inherit" }}>
            {!thankYou && (
              <>
                <Image
                  src={`${process.env.PUBLIC_URL}/images/str-underwriting-tool.webp`}
                  alt="Short-Term Rental Deal Underwriting Tool"
                  style={{
                    padding: "0rem",
                    boxShadow: "0 0 43px rgba(0, 0, 0, 0.1)",
                    maxWidth: "100%"
                  }}
                  $mobileSize={mobileSize}
                />
              </>
            )}
          </div>
        </SplitView>
        <Text block align="left" style={{ paddingBottom: 0 }}>
          Some of the key features include:
        </Text>
        <ul>
          <li
            style={{
              // color: "var(--white)",
              fontSize: "1.3rem",
              fontWeight: 300
            }}
          >
            Net Income Calculation: Our underwriting tool helps you easily
            calculate the net income of your short-term rental investment
            property, taking into account all of the expenses associated with
            owning and operating the property.
          </li>
          <li
            style={{
              // color: "var(--white)",
              fontSize: "1.3rem",
              fontWeight: 300
            }}
          >
            Cash on Cash Return: Our underwriting tool determines the cash on
            cash return for your investment property, a measure of the cash flow
            you&apos;ll receive from the property relative to the amount of cash
            you invest.
          </li>
          <li
            style={{
              // color: "var(--white)",
              fontSize: "1.3rem",
              fontWeight: 300
            }}
          >
            Gross Yield Calculation: Our underwriting tool calculates the gross
            yield of your investment property, a measure of the income generated
            by the property relative to the purchase price.
          </li>
          <li
            style={{
              // color: "var(--white)",
              fontSize: "1.3rem",
              fontWeight: 300
            }}
          >
            A well-connected network of partners and 1031 exchange
            intermediaries
          </li>
          <li
            style={{
              // color: "var(--white)",
              fontSize: "1.3rem",
              fontWeight: 300
            }}
          >
            Cap Rate Calculation: Our underwriting tool helps you calculate the
            cap rate of your investment property, a measure of the rate of
            return on the property based on the income generated and the
            purchase price.
          </li>
          <li
            style={{
              // color: "var(--white)",
              fontSize: "1.3rem",
              fontWeight: 300
            }}
          >
            Investment Value Over Time: Our underwriting tool includes
            projections for the investment value of your property over time, so
            you can get a sense of how your investment will perform in the long
            term.
          </li>
        </ul>
        <Text block align="left">
          In conclusion, our Free Airbnb Rental Investment Deal Analyzer is an
          invaluable resource for any real estate investor interested in the
          short-term rental market. It&apos;s completely free to download and
          use, so there&apos;s no risk or obligation involved. Try it out today
          and make smarter investment decisions for your portfolio!
        </Text>
      </Section>
    </div>
  );
};

export default STRUnderwritingTool;
