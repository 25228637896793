import uniqueId from "lodash.uniqueid";
import styled from "styled-components";
import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import Field, { FieldProps } from "./Field";
import Option from "./Option";
import Popover, { PopoverProps } from "./Popover";
import useOnClickOutside from "../hooks/useOnClickOutside";
import Flex from "./Flex";
import Text from "./Text";

const ChevronDown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-arrow-down"
  >
    <polyline points="6 9 12 15 18 9" />
  </svg>
);

export interface OptionProp {
  label?: string;
  value?: any;
}

export interface SelectProps extends FieldProps {
  options?: OptionProp[];
  isSearchable?: boolean;
}

export const StyledSelect = styled.div`
  display: flex;
  flex: 1 1 auto;
  position: relative;
  color: var(--quinary);
  box-shadow: none;
  text-transform: uppercase;
  width: 100%;
  input {
    // padding-right: 40px;
    background: var(--white);

    cursor: pointer;
    // text-transform: uppercase;
    // font-weight: bold;
    font-size: "1rem";
    // font-family: "Source Sans 3", Impact, "Franklin Gothic Bold", sans-serif;
  }
  > svg {
    height: calc(100% - 1rem);
    margin: 0 0.5rem;
    pointer-events: none;
    position: absolute;
    right: 1rem;
    top: -0rem;
  }

  @media (max-width: 800px) {
    max-width: 100%;
    input {
      // padding: 1rem 7rem;
      font-size: 1rem;
    }
  }
`;

const innerFunction = forwardRef(
  (props: PopoverProps, ref: ForwardedRef<any>) => (
    <Popover {...props} ref={ref} />
  )
);

const Menu = styled(innerFunction)`
  display: flex;
  flex-direction: column;
`;

const SelectForCalculator = ({
  isSearchable,
  name,
  onChange,
  options,
  value: propValue,
  ...props
}: SelectProps) => {
  const id = useRef(uniqueId("select-"));
  const container = useRef<any>();
  const popoverRef = useRef<HTMLElement>();
  const fieldRef = useRef<HTMLInputElement>();
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(propValue);
  const [searchValue, setSearchValue] = useState("");

  useOnClickOutside(popoverRef, (e: React.FocusEvent<HTMLDivElement>) => {
    if (!container.current.contains(e.relatedTarget)) {
      setIsOpen(false);
    }
  });

  useEffect(() => {
    if (value !== propValue) {
      setValue(propValue);
    }
  }, [propValue]);

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleFocus = () => {
    setIsOpen(!isOpen);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.relatedTarget !== popoverRef.current) {
      setIsOpen(false);
      setValue(propValue);
      setSearchValue("");
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "ArrowDown" || e.key === "Tab") {
      if (popoverRef.current) {
        e.preventDefault();
        e.stopPropagation();
        popoverRef.current?.getElementsByTagName("button")[0]?.focus();
      }
    }
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchValue((e.target as any).value);
  };

  const handleOptionClick = (option: OptionProp) => (e: React.MouseEvent) => {
    e.preventDefault();
    setValue(option);
    setSearchValue("");
    setIsOpen(false);
    (e as any).name = name;
    (e as any).value = option;
    onChange?.(e as any);
    fieldRef?.current?.focus();
  };

  const handleOptionKeyDown =
    (option: OptionProp) => (e: React.KeyboardEvent) => {
      if (e.key === "Enter" || e.key === " ") {
        handleOptionClick(option)(e as any);
      }
    };

  const filteredOptions = useMemo(
    () =>
      (options || []).filter((option: OptionProp) =>
        option?.label?.includes(searchValue)
      ),
    [options, searchValue]
  );

  return (
    <StyledSelect ref={container}>
      <Field
        ref={fieldRef}
        id={id.current}
        value={searchValue || value?.label}
        name={name}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onClick={handleClick}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        readOnly={!isSearchable}
        {...props}
      />
      <ChevronDown />
      <Menu
        ref={popoverRef}
        container={container}
        isOpen={isOpen}
        target={id.current}
      >
        {filteredOptions.length !== 0 &&
          filteredOptions.map((option: OptionProp, idx: number) => {
            const key = `${option.label}-${idx}`;
            return (
              <Option
                key={key}
                onMouseDown={handleOptionClick(option)}
                onKeyDown={handleOptionKeyDown(option)}
                tabIndex={0}
              >
                {option.label}
              </Option>
            );
          })}
        {filteredOptions.length === 0 && (
          <Flex
            style={{
              alignItems: "center",
              padding: "1rem",
              textAlign: "center"
            }}
          >
            <Text style={{ width: "100%" }}>No options found.</Text>
          </Flex>
        )}
      </Menu>
    </StyledSelect>
  );
};

SelectForCalculator.defaultProps = {
  isSearchable: false
};

export default SelectForCalculator;
