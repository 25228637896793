import React, { useCallback, useState } from "react";
import { Auth } from "aws-amplify";
import styled from "styled-components";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Modal from "../../../components/Modal";
import Image from "../../../components/Image";
import Text from "../../../components/Text";
import SplitView from "../../../components/SplitView";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import { Color } from "../../../types";
import { AppContext } from "../../../components/AppContext";
import Flex from "../../../components/Flex";
import ConfirmSignup from "../../modals/LoginModal/ConfirmSignup";
import Header from "../../../components/Header";

// Add this interface above the StyledModal definition
interface StyledModalProps {
  $forced?: boolean;
}

const StyledModal = styled(Modal)<StyledModalProps>`
  max-width: 90vw;
  overflow: auto;

  // Prevent clicking outside when modal is forced
  ${(props) =>
    props.$forced &&
    `
    .modal-overlay {
      pointer-events: all !important;
    }
  `}

  @media (max-width: 810px) {
    max-width: 90vw;
    overflow: auto;
  }
`;

const BackgroundImage = styled(Image)`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;

const ModalTitle = styled(Text)`
  text-align: center;
  color: var(--sixth);
  // color: transparent;
  background: var(--radialGradient);
  background-clip: text;
  font-size: 1.8rem;
  font-weight: 400;
  padding: 2rem 2rem 0;
  width: 100%;
  @media (max-width: 768px) {
    padding: 2rem;
  }
`;

const CalloutList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  list-style-type: disclosure-closed;
  margin: 0rem 0rem 0rem 2rem;
  padding: 0;
`;

const CalloutItem = styled.li`
  font-weight: 400;
  font-size: 1.2rem;
  margin-bottom: 1rem;
`;

const CalloutItemTitle = styled.span`
  color: var(--primary);
  font-weight: 500;
  text-decoration: underline;
`;

const InputWithPadding = styled(Input)`
  margin-bottom: 0.5rem;
  padding: 0.5rem !important;
  width: 100% !important;
`;

const DisclaimerText = styled(Text)`
  display: flex;
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 1.3;
  margin-top: -1rem;
  margin-bottom: 1rem;
  padding-bottom: 0;
`;

const CenteredFlex = styled(Flex)`
  justify-content: center;
`;

const StyledSplitView = styled(SplitView)`
  & > *:first-child {
    padding: 2rem 1rem 2rem 2rem;
  }
  & > *:last-child {
    padding: 2rem 2rem 2rem 1rem;
  }
`;

interface ZipcodeModalProps {
  ignoreTimeout?: boolean;
  isOpen?: boolean;
  toggle?(): void;
  forced?: boolean; // Add this prop
}

// Add Facebook types at the top
declare global {
  interface Window {
    FB: {
      init(options: {
        appId: string;
        cookie: boolean;
        xfbml: boolean;
        version: string;
      }): void;
      login(
        callback: (response: {
          status: string;
          authResponse: {
            accessToken: string;
            userID: string;
          };
        }) => void,
        options: { scope: string }
      ): void;
      api(
        path: string,
        params: { fields: string },
        callback: (response: { name: string; email: string }) => void
      ): void;
    };
    fbAsyncInit: () => void;
  }
}

export default function ZipcodeModal({
  ignoreTimeout,
  isOpen: propIsOpen,
  toggle: propToggle,
  forced = false
}: ZipcodeModalProps) {
  const history = useHistory();
  const location = useLocation();
  const { market } = useParams<Record<string, string>>();
  const { setOpenLoginModal, user, setUser, mobileSize } =
    React.useContext(AppContext);
  const [isOpen, setIsOpen] = React.useState(propIsOpen || false);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [verificationCode, setVerificationCode] = React.useState("");
  const [error, setError] = useState<string | undefined>();
  const [confirmSignUp, setConfirmSignUp] = React.useState(false);
  const [thankYou, setThankYou] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  // Add Facebook SDK initialization
  React.useEffect(() => {
    (window as any).fbAsyncInit = function () {
      (window as any).FB.init({
        appId: "472037342554722",
        cookie: true,
        xfbml: true,
        version: "v21.0"
      });
    };

    (function (d: Document, s: string, id: string) {
      const js = d.createElement(s) as HTMLScriptElement;
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode?.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  // Add Facebook sign in function
  const facebookSignIn = useCallback(async () => {
    try {
      localStorage.setItem("prevLoc", history.location.pathname);

      window.FB.login(
        function (response) {
          if (response.status === "connected") {
            window.FB.api("/me", { fields: "name,email" }, function (userInfo) {
              Auth.federatedSignIn({
                provider: CognitoHostedUIIdentityProvider.Facebook,
                customState: JSON.stringify({
                  email: userInfo.email,
                  name: userInfo.name,
                  "custom:title": "Placeholder",
                  "custom:market": market || "Placeholder",
                  "custom:other": location.pathname || "Placeholder",
                  "custom:consent": "true",
                  token: response.authResponse.accessToken
                })
              })
                .then((cognitoUser) => {
                  setUser(cognitoUser);
                })
                .catch((err) => {
                  console.log("Error signing in with Cognito:", err);
                });
            });
          }
        },
        { scope: "public_profile,email" }
      );
    } catch (err) {
      console.log("error signing in with Facebook:", err);
    }
  }, [history, location.pathname, market, setUser]);

  const federatedSignIn = useCallback(async () => {
    // if (allowAddingPassword()) {
    try {
      localStorage.setItem("prevLoc", history.location.pathname);
      await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
        customState: JSON.stringify({
          email,
          name,
          "custom:title": "Placeholder",
          "custom:market": market || "Placeholder",
          "custom:other": location.pathname || "Placeholder",
          "custom:consent": "true"
        })
      } as any);
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      console.log("Authenticated user", authenticatedUser);
      setUser(authenticatedUser);
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.log("error signing up:", err);
    }
    // }
  }, [email, name]);

  const onLoginClick = () => {
    setIsOpen(false);
    setOpenLoginModal(true);
  };

  const onSignUp = async () => {
    setIsLoading(true);
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      setError("Please use a correct email address!");
      setIsLoading(false);
      return;
    }
    try {
      const { user: newUser } = await Auth.signUp({
        username: email,
        password,
        attributes: {
          name,
          email,
          "custom:title": "Placeholder",
          "custom:market": market || "Placeholder",
          "custom:other": location.pathname || "Placeholder",
          "custom:consent": "true"
        }
      });
      if (newUser) {
        setIsLoading(false);
        setConfirmSignUp(true);
        setUser(newUser);
        // setThankYou(true);
      }
    } catch (e: any) {
      setError(e.message);
      setIsLoading(false);
    }
  };

  const onConfirmSignUp = async () => {
    setIsLoading(true);
    try {
      await Auth.confirmSignUp(email, verificationCode);
      setConfirmSignUp(false);
      setThankYou(true);
      const userSignIn = await Auth.signIn(email, password);
      setUser(userSignIn);
      setIsLoading(false);
      setIsOpen(false);
      console.log("Auto sign-in success!", userSignIn);
    } catch (err) {
      setIsLoading(false);
      // eslint-disable-next-line no-console
      // eslint-disable-next-line
      console.log("error signing up:", err);
    }
  };

  const resendCode = async () => {
    setIsLoading(true);
    try {
      await Auth.resendSignUp(email);
      setIsLoading(false);
    } catch (err) {
      // eslint-disable-next-line
      // eslint-disable-next-line
      console.log("error resending code: ", err);
      setIsLoading(false);
    }
  };

  const toggle = () => {
    if (forced) return; // Prevent closing if modal is forced
    if (propToggle) {
      propToggle();
    } else {
      setIsOpen(false);
    }
  };

  //   React.useEffect(() => {
  //     if (!user && !ignoreTimeout) {
  //       const timeout = setTimeout(() => {
  //         setIsOpen(true);
  //       }, 9000);

  //       return () => {
  //         clearTimeout(timeout);
  //       };
  //     }
  //     return undefined;
  //   }, [ignoreTimeout, user]);

  React.useEffect(() => {
    setIsOpen(propIsOpen || false);
  }, [propIsOpen]);

  return (
    <StyledModal
      toggle={toggle}
      isOpen={isOpen}
      $forced={forced}
      onClickOutside={!forced}
    >
      {/* <BackgroundImage src="/images/about2.webp" /> */}
      <Flex>
        {!thankYou && (
          <ModalTitle>
            Unlock{" "}
            <span
              style={{ color: "var(--primary)", textDecoration: "underline" }}
            >
              ZIP Code Analysis
            </span>{" "}
            with a Free Account!
          </ModalTitle>
        )}
      </Flex>
      <Header size={4}>
        Sign up now to access all market dashboards and features at no cost.
      </Header>
      {!confirmSignUp && !thankYou && (
        <StyledSplitView
          split="2:3"
          style={{ padding: mobileSize ? "1rem" : "initial" }}
        >
          <div>
            <InputWithPadding
              name="name"
              placeholder="Name"
              value={name}
              onChange={(e: any) => {
                setName(e.target.value);

                if (error) {
                  setError(undefined);
                }
              }}
            />
            <InputWithPadding
              name="email"
              placeholder="Email"
              value={email}
              onChange={(e: any) => {
                setEmail(e.target.value);

                if (error) {
                  setError(undefined);
                }
              }}
            />
            <InputWithPadding
              name="password"
              placeholder="Password"
              type="password"
              value={password}
              onChange={(e: any) => {
                setPassword(e.target.value);

                if (error) {
                  setError(undefined);
                }
              }}
            />
            <DisclaimerText>
              By signing up, you agree to receive subsequent email and
              third-party marketing communications from Chalet pursuant to our
              Privacy Policy, which you may opt out of, or unsubscribe from at
              any time.
            </DisclaimerText>
            <Button
              block
              color={Color.linearGradient}
              data-id="analytics-modal-signup-button-email"
              style={{ fontWeight: "bold", width: "100%" }}
              onClick={onSignUp}
              isLoading={isLoading}
            >
              Sign Up
            </Button>
            <Button
              color={Color.primary}
              style={{ width: "100%", marginTop: "1rem" }}
              data-id="analytics-modal-signup-button-google"
              onClick={federatedSignIn}
            >
              <Image
                src="/images/google_logo_1.png"
                alt="google"
                style={{ marginRight: "0.5rem" }}
                height="27px"
                width="27px"
              />
              Sign up with Google
            </Button>
            {/* <Button
              color={Color.primary}
              style={{ width: "100%", marginTop: "1rem" }}
              data-id="analytics-modal-signup-button-facebook"
              onClick={facebookSignIn}
            >
              <i
                className="fa-brands fa-square-facebook fa-3x"
                style={{
                  color: "var(--white)",
                  height: "30px",
                  width: "30px",
                  paddingRight: "0.5rem",
                  fontSize: "2rem"
                }}
                role="button"
                aria-label="Facebook icon link"
                tabIndex={0}
              />
              Sign up with Facebook
            </Button> */}
            <CenteredFlex>
              <Text>
                Already a member?{" "}
                <Text
                  onClick={onLoginClick}
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  Log in
                </Text>
              </Text>
            </CenteredFlex>
            {error && (
              <Flex>
                <Text
                  color={Color.quaternary}
                  weight={400}
                  style={{ fontSize: "1.5rem" }}
                >
                  {error}
                </Text>
              </Flex>
            )}
          </div>

          <div style={{ display: mobileSize ? "none" : "block" }}>
            {/* <ModalSubTitle>
              Maximize Your Short-Term Rental Potential
            </ModalSubTitle> */}
            <CalloutList>
              <CalloutItem>
                <CalloutItemTitle>Airbnb Market Dashboards:</CalloutItemTitle>{" "}
                Unlimited access to detailed market analytics.
              </CalloutItem>
              <CalloutItem>
                <CalloutItemTitle>Market Comparison Guide:</CalloutItemTitle>{" "}
                Top 200 short-term rental markets sent to your inbox
              </CalloutItem>
              <CalloutItem>
                <CalloutItemTitle>Airbnb Calculator:</CalloutItemTitle>{" "}
                AI-powered tool for identifying top investment opportunities.
              </CalloutItem>
              <CalloutItem>
                <CalloutItemTitle>
                  Short-Term Rental AI Copilot:
                </CalloutItemTitle>{" "}
                Early access to personalized investment insights (Coming Soon).
              </CalloutItem>
              <CalloutItem>
                <CalloutItemTitle>STR Service Providers:</CalloutItemTitle> Free
                introduction to 100+ top-rated STR service providers.
              </CalloutItem>
            </CalloutList>
          </div>
        </StyledSplitView>
      )}
      {confirmSignUp && !thankYou && (
        <ConfirmSignup
          email={email}
          isLoading={isLoading}
          verificationcode={verificationCode}
          onChange={(e: any) => setVerificationCode(e.target.value)}
          setSubmitConfirmation={onConfirmSignUp}
          resendCode={resendCode}
        />
      )}
    </StyledModal>
  );
}
