import React, { useState, useEffect, lazy, Suspense, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../../styles/AirbnbsForSale.scss";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { PopupButton } from "@typeform/embed-react";
import ImageGalleryModal from "./ImageGalleryModal";
import listingsData from "./2024-10-01_combined.json";
import Image from "../../components/Image";
import Section from "../../components/Section";
import { AppContext } from "../../components/AppContext";

interface Listing {
  address: string;
  price: number;
  annualRevenue: number;
  images: string[];
  bedrooms: number;
  bathrooms: number;
  sqft: number;
  yearBuilt: number;
  pricePerSqft: number;
  hoa: number;
  capRate: number;
  occupancyRate: number;
  description: string;
  latitude: number;
  longitude: number;
}

const LazyImage = lazy(() => import("../../components/LazyImage"));

const extractLocationFromAddress = (
  address: string
): { city: string; state: string } => {
  const parts = address.split(",");
  if (parts.length >= 2) {
    const cityPart = parts[parts.length - 2].trim();
    const statePart = parts[parts.length - 1].trim().split(" ")[0];
    return { city: cityPart, state: statePart };
  }
  return { city: "", state: "" };
};

const IndividualListingPage: React.FC = () => {
  const { encodedAddress } = useParams<{ encodedAddress: string }>();
  const [listing, setListing] = useState<Listing | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isShared, setIsShared] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showThankYouModal, setShowThankYouModal] = useState(false);
  const { mobileSize } = useContext(AppContext);

  // Get landing info from localStorage including original UTM params
  const landingInfo = JSON.parse(localStorage.getItem("landingInfo") || "{}");

  const typeformHiddenFields = {
    // market_of_interest: LiveMarkets[marketPathParam] || "Not Specified",
    source_page: `airbnbs-for-sale-listings/${encodedAddress}`,
    utm_source: landingInfo.utm_source || "",
    utm_medium: landingInfo.utm_medium || "",
    anon_user_id: landingInfo.anon_user_id || "",
    user_id: landingInfo.user_id || "",
    utm_campaign: landingInfo.utm_campaign || "",
    utm_term: landingInfo.utm_term || "",
    utm_content: landingInfo.utm_content || ""
    // subquery: marketPathParam || ""
  };

  useEffect(() => {
    const fetchListing = () => {
      const decodedAddress = decodeURIComponent(encodedAddress || "");

      const foundListing = listingsData.find(
        (item) => item.address.toLowerCase() === decodedAddress.toLowerCase()
      );
      if (foundListing) {
        const {
          address,
          price,
          annualRevenue,
          images,
          bedrooms,
          bathrooms,
          sqft,
          yearBuilt,
          capRate,
          occupancyRate,
          description,
          latitude,
          longitude
        } = foundListing;
        setListing({
          address,
          price,
          annualRevenue,
          images,
          bedrooms,
          bathrooms,
          sqft: sqft ?? 0,
          yearBuilt,
          pricePerSqft: sqft ? Math.round(price / sqft) : 0,
          hoa: 0,
          capRate,
          occupancyRate,
          description,
          latitude,
          longitude
        });
      } else {
        setListing(null);
      }
    };

    fetchListing();
  }, [encodedAddress]);

  if (!listing) {
    return <div>Loading...</div>;
  }

  const { city, state } = extractLocationFromAddress(listing.address);
  const formattedAddress = listing.address.replace(/\s\d{5}$/, "");
  const pageTitle = `Airbnb for Sale: ${formattedAddress}`;
  const pageDescription = `Explore this ${
    listing.sqft
  } sqft STR for sale in ${city}, ${state}. Priced at $${listing.price.toLocaleString()} with an annual Airbnb revenue of $${listing.annualRevenue
    .toFixed(0)
    .toLocaleString()}. Perfect investment opportunity!`;

  const openModal = (index: number) => {
    setCurrentImageIndex(index);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const nextImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex + 1) % listing.images.length
    );
  };

  const prevImage = () => {
    setCurrentImageIndex(
      (prevIndex) =>
        (prevIndex - 1 + listing.images.length) % listing.images.length
    );
  };

  const mapContainerStyle = {
    width: "100%",
    height: "400px"
  };

  const center = {
    lat: listing.latitude,
    lng: listing.longitude
  };

  const hideAnalyzeMarketButton = [
    "Ormond Beach",
    "Orange Beach",
    "Foley",
    "Delray Beach",
    "Cottonwood",
    "Saint Augustine",
    "Winter Haven",
    "Santa Rosa Beach"
  ].includes(city);

  const handleAnalyzeMarket = () => {
    // const { state } = extractLocationFromAddress(listing.address);
    const marketSlug = `${city
      .toLowerCase()
      .replace(/\s+/g, "-")}-${state.toLowerCase()}`;
    window.open(`/analytics/overview/${marketSlug}`, "_blank");
  };

  const handleShare = async () => {
    const shareUrl = window.location.href;
    const shareTitle = `Check out this Airbnb for sale: ${listing?.address}`;

    if (navigator.share) {
      try {
        await navigator.share({
          title: shareTitle,
          text: `${shareTitle} - Price: $${listing?.price.toLocaleString()}`,
          url: shareUrl
        });
        setIsShared(true);
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      // Fallback for browsers that don't support Web Share API
      await navigator.clipboard.writeText(shareUrl);
      setIsShared(true);
      setTimeout(() => setIsShared(false), 2000);
    }
  };

  const renderGalleryImages = () => {
    if (listing.images.length === 0) return null;

    return (
      <>
        <Suspense fallback={<div>Loading...</div>}>
          <button
            className="gallery-image main-image"
            onClick={() => openModal(0)}
            aria-label={`View full-size image 1 of ${listing.address}`}
            type="button"
          >
            <LazyImage src={listing.images[0]} alt={`${listing.address} - 1`} />
          </button>
          {listing.images.slice(1, 5).map((image, index) => (
            <button
              key={`image-${image}`}
              className="gallery-image desktop-only"
              onClick={() => openModal(index + 1)}
              aria-label={`View full-size image ${index + 2} of ${
                listing.address
              }`}
              type="button"
            >
              <LazyImage
                src={image}
                alt={`${listing.address} - ${index + 2}`}
              />
            </button>
          ))}
        </Suspense>
        <button
          className="see-all-photos"
          onClick={() => openModal(0)}
          type="button"
        >
          See all {listing.images.length} photos
        </button>
      </>
    );
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    const formData = new FormData(form);

    const payload = {
      email: formData.get("email"),
      fullName: formData.get("fullName"),
      phone: formData.get("phone"),
      interestedIn: `${listing.address} - $${listing.price.toLocaleString()}`
    };

    try {
      const response = await fetch(
        "https://cbehz6zbjl.execute-api.us-west-2.amazonaws.com/v1/airbnbs-for-sale-contacts",
        {
          method: "POST",
          // headers: {
          //   "Content-Type": "application/json"
          // },
          body: JSON.stringify(payload)
        }
      );

      if (response.ok) {
        setShowThankYouModal(true);
        form.reset();
      } else {
        throw new Error("Server responded with an error");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const ThankYouModal: React.FC = () => (
    <div className="modal-overlay">
      <div className="modal-content thank-you-modal">
        <h2>Thank You!</h2>
        <p>We appreciate your interest. Our team will contact you soon.</p>
        <button type="button" onClick={() => setShowThankYouModal(false)}>
          Close
        </button>
      </div>
    </div>
  );

  const renderContactForm = () => (
    <div className="contact-form-mobile">
      <h3>Contact the seller</h3>
      <form onSubmit={handleSubmit}>
        <input type="text" name="fullName" placeholder="Full Name" required />
        <input type="email" name="email" placeholder="Email" required />
        <input type="tel" name="phone" placeholder="Phone" />
        <textarea
          name="message"
          placeholder="What would you like to know?"
          defaultValue={`I am interested in ${listing.address}.`}
        />
        <button
          type="submit"
          data-id="submit-interested-in-property-form"
          className="submit-button"
        >
          Submit
        </button>
      </form>
      <p className="form-disclaimer">
        By pressing Submit, you agree that GetChalet, Inc. and its affiliates
        may contact you via phone/text about your inquiry, which may involve use
        of automated means. You don&apos;t need to consent as a condition of
        buying any property, goods or services. Message/data rates may apply.
        You also agree to our Terms of Use. GetChalet, Inc. is committed to
        protecting your privacy.
      </p>
    </div>
  );

  return (
    <div className="individual-listing-page">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={listing.images[0]} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content={listing.images[0]} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Link to="/airbnbs-for-sale" className="back-link">
        &larr; Back to search
      </Link>
      <div className="listing-header">
        <div className="header-actions">
          <button type="button" className="action-button">
            Save
          </button>
          <button type="button" className="action-button" onClick={handleShare}>
            {isShared ? "Shared!" : "Share"}
          </button>
          {/* <button type="button" className="action-button">
            More
          </button> */}
        </div>
      </div>
      <div className="image-gallery">{renderGalleryImages()}</div>
      <div className="listing-details">
        <div className="main-info">
          <h2>${listing.price.toLocaleString()}</h2>
          <div className="property-stats">
            <span>{listing.bedrooms} beds</span>
            <span>{listing.bathrooms} baths</span>
            <span>{listing.sqft.toLocaleString()} sqft</span>
          </div>
          <p className="location">{listing.address}</p>
          {/* <div className="property-features">
            <div className="feature">
              <span>House</span>
            </div>
            <div className="feature">
              <span>Built in {listing.yearBuilt}</span>
            </div>
            <div className="feature">
              <span>${listing.pricePerSqft}/sqft</span>
            </div>
            <div className="feature">
              <span>${listing.hoa} HOA</span>
            </div>
          </div> */}
          <div className="projected-returns">
            <h3>Projected returns</h3>
            <div className="returns-info">
              <div className="annual-revenue">
                <span>Annual Revenue</span>
                <span>
                  ${Math.round(listing.annualRevenue).toLocaleString()}
                </span>
              </div>
              <div className="cash-on-cash">
                <span>Occupancy Rate</span>
                <span>{listing.occupancyRate.toFixed(0)}%</span>
              </div>

              <div className="cash-on-cash">
                <span>Cap Rate</span>
                <span>
                  {" "}
                  {((listing.annualRevenue / listing.price) * 60).toFixed(2)}%
                </span>
              </div>
            </div>
          </div>
          {!hideAnalyzeMarketButton && (
            <button
              type="button"
              className="analyze-market-button"
              onClick={handleAnalyzeMarket}
            >
              Analyze Market
            </button>
          )}
          <div className="listing-description">
            <h3>What&apos;s special</h3>
            {listing.description
              .split(". ")
              .reduce((acc: string[], sentence, index, array) => {
                acc[Math.floor(index / Math.ceil(array.length / 3))] = `${
                  (acc[Math.floor(index / Math.ceil(array.length / 3))] || "") +
                  sentence
                }. `;
                return acc;
              }, [])
              .map((paragraph, index) => (
                <p key={`paragraph-${paragraph}`}>{paragraph.trim()}</p>
              ))}
          </div>
          <div className="map-container">
            <h3>Location</h3>
            <LoadScript
              googleMapsApiKey={`${process.env.REACT_APP_PLACES_API_KEY}`}
            >
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={14}
              >
                <Marker position={center} />
              </GoogleMap>
            </LoadScript>
          </div>
          {/* <div className="lender-cta">
            <h3>Ready to make this property yours?</h3>
            <p>
              Get pre-approved in minutes and unlock exclusive financing options
              for short-term rentals!
            </p>
            <PopupButton
              id="lMhk4Fej"
              hidden={typeformHiddenFields}
              style={{
                backgroundColor: "var(--quinary)",
                color: "white",
                padding: "10px 20px",
                border: "none",
                borderRadius: "4px",
                textTransform: "uppercase",
                fontSize: "16px",
                cursor: "pointer",
                width: "100%"
              }}
              className="cta-button"
              // hidden={{
              //   property_address: listing.address,
              //   property_price: listing.price.toString(),
              //   source_page: window.location.href
              // }}
            >
              Get Your Personalized Quote Now
            </PopupButton>
            <p className="cta-disclaimer">
              No impact on your credit score. Competitive rates for investors.
            </p>
          </div> */}
        </div>
        <div className="contact-form-container">
          <div className="contact-form">
            <h3>Contact the seller</h3>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="fullName"
                placeholder="Full Name"
                required
              />
              <input type="email" name="email" placeholder="Email" required />
              <input type="tel" name="phone" placeholder="Phone" />
              <textarea
                name="message"
                placeholder="What would you like to know?"
                defaultValue={`I am interested in ${listing.address}.`}
              />
              <button type="submit" className="submit-button">
                Submit
              </button>
            </form>
            <p className="form-disclaimer">
              By pressing Submit, you agree that GetChalet, Inc. and its
              affiliates may contact you via phone/text about your inquiry,
              which may involve use of automated means. You don&apos;t need to
              consent as a condition of buying any property, goods or services.
              Message/data rates may apply. You also agree to our Terms of Use.
              GetChalet, Inc. is committed to protecting your privacy.
            </p>
          </div>
          <div>
            <button
              type="button"
              className="contact-seller-button"
              onClick={() => setShowContactModal(true)}
            >
              Connect with an expert
            </button>
          </div>
        </div>
        {showContactModal && (
          <div className="modal-overlay">
            <div className="modal-content">
              <button
                type="button"
                className="close-modal"
                onClick={() => setShowContactModal(false)}
              >
                &times;
              </button>
              {renderContactForm()}
            </div>
          </div>
        )}
      </div>

      <ImageGalleryModal
        isOpen={showModal}
        onClose={closeModal}
        images={listing.images}
        currentIndex={currentImageIndex}
        onPrev={prevImage}
        onNext={nextImage}
      />

      {showThankYouModal && <ThankYouModal />}
      <Section
        style={{
          boxShadow: "none",
          background: "var(--sixth)",
          maxWidth: "100%",
          padding: "4rem 0"
        }}
      >
        <div className="get-started-container">
          <div className="get-started-content">
            <h2>Ready to Secure Financing for Your STR Investment?</h2>
            <p>
              Connecting with our DSCR and conventional lending partners is
              completely free and comes with no obligation.
            </p>
            <div className="get-started-buttons">
              {/* <Button onClick={() => history.push("/buying-an-airbnb")}>
                I am buying an Airbnb
              </Button> */}
              <PopupButton
                id="M700RBmq"
                shareGaInstance="true"
                hidden={{
                  ...typeformHiddenFields,
                  source_page: "/(cta-bottom-homepage)",
                  start_point: "selling"
                }}
                data-id="connect-with-str-lender_button"
                className="connect-with-str-lender_button_top btn-consult"
                style={{ flex: mobileSize ? "1 0 100%" : "0 1 auto" }}
              >
                Get Started
              </PopupButton>
              {/* <PopupButton
                id="M700RBmq"
                shareGaInstance="true"
                hidden={{
                  source_page: "/(cta-bottom-homepage)",
                  start_point: "ancillary"
                }}
                data-id="talk-to-str-expert"
                className="talk-to-str-expert_button_top btn-consult"
                style={{ flex: mobileSize ? "1 0 100%" : "0 1 auto" }}
              >
                I am looking for financing
              </PopupButton> */}
            </div>
          </div>
          <div className="get-started-image">
            <Image
              src={`${process.env.PUBLIC_URL}/images/house-with-palm-trees.svg`}
              alt="House with palm trees illustration"
            />
          </div>
        </div>
      </Section>
    </div>
  );
};

export default IndividualListingPage;
