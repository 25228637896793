import React, {
  useContext,
  useRef,
  useEffect,
  lazy,
  Suspense,
  useState
} from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { useParams, Link } from "react-router-dom";
import { PopupButton } from "@typeform/embed-react";
import Slider from "react-slick";
import { AppContext } from "../../components/AppContext";
import Text from "../../components/Text";
import Header from "../../components/Header";
import useCookies from "../../hooks/useCookies";
import Flex from "../../components/Flex";
import SectionFooter from "../../components/SectionFooter";
import SplitView from "../../components/SplitView";
import Group from "../../components/Group";
import Section from "../../components/Section";
import Card from "../../components/Card";
import List from "../../components/List";
// Import the JSON file
import listingsData from "../ForSaleListings/2024-10-01_combined.json";
import HR from "../../components/HR";
import { Color } from "../../types";
import LiveMarkets from "../../liveMarkets";
import Image from "../../components/Image";
import { VerticalDivider, HorizontalDivider } from "../../components/Divider";

interface Listing {
  id: number;
  title: string;
  location: string;
  price: number;
  annualRevenue: number;
  image: string;
  // hoa: number;
  // lotSize: number;
  bedrooms: number;
  bathrooms: number;
  // availability: string;
  sqft: number;
  capRate: string;
}
const LazyImage = lazy(() => import("../../components/LazyImage"));

const ListingCard: React.FC<{ listing: Listing }> = ({ listing }) => (
  <Link
    to={`/airbnbs-for-sale-listings/${listing.location}`}
    target="_blank"
    style={{
      display: "block",
      textAlign: "center",
      background: "var(--white)",
      color: "white",
      // padding: "8px",
      borderRadius: "4px",
      textDecoration: "none"
    }}
    className="listing-card-link"
  >
    <div
      style={{
        // border: "1px solid #ddd",
        borderRadius: "0.5rem",
        padding: "16px",
        // width: "300px",
        height: "500px", // Increased height
        cursor: "pointer",
        display: "flex",
        // margin: "10px",
        flexDirection: "column",
        justifyContent: "space-between", // Distribute content evenly
        border: "1px solid var(--lightPurple)",
        boxShadow: "0px 1.4rem 6rem -2rem rgba(108,129,171,0.3)"
      }}
      className="listing-card"
    >
      <div>
        <h2
          style={{
            fontSize: "1.3em",
            marginBottom: "8px",
            color: "var(--sixth)"
          }}
        >
          {listing.location}
        </h2>
        <Suspense fallback={<div>Loading...</div>}>
          <LazyImage
            src={listing.image}
            alt={listing.title}
            style={{
              width: "100%",
              height: "200px",
              objectFit: "cover",
              marginBottom: "12px",
              borderRadius: "4px"
            }}
          />
        </Suspense>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "8px"
          }}
        >
          {/* <span>Price:</span> */}
          <span style={{ fontWeight: "bold", color: "var(--primary)" }}>
            ${listing.price.toLocaleString()}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: "12px",
            fontSize: "1em",
            color: "var(--quaternary)"
          }}
        >
          <span style={{ color: "var(--secondary)", fontWeight: "500" }}>
            {listing.bedrooms} beds
          </span>
          <span style={{ margin: "0 8px" }}>|</span>
          <span style={{ color: "var(--secondary)", fontWeight: "500" }}>
            {listing.bathrooms} baths
          </span>
          <span style={{ margin: "0 8px" }}>|</span>
          <span style={{ color: "var(--secondary)", fontWeight: "500" }}>
            {listing.sqft} sq ft
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "8px"
          }}
        >
          <span>Projected Revenue:</span>
          <span style={{ fontWeight: "500", color: "var(--primary)" }}>
            ${Math.round(listing.annualRevenue).toLocaleString()}/year
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "8px"
          }}
        >
          <span>Cap Rate:</span>
          <span style={{ fontWeight: "500", color: "var(--primary)" }}>
            {((listing.annualRevenue / listing.price) * 60).toFixed(2)}%
          </span>
        </div>
      </div>
    </div>
  </Link>
);

const Container = styled(Flex)`
  // flex-direction: column;
  font-size: 2.5rem;
  height: 100vh;
  // width: 50%;
  justify-content: center;
  line-height: 1;
  h1 {
    font-weight: 300;
    font-size: 3.5rem;
    // text-align: center;
    margin-bottom: 0;
    text-align: left;
    // padding-bottom: 1rem;
  }
  h3 {
    font-weight: 300;
    font-size: 1.6rem;
    // text-align: center;
    margin: 0;
  }
  video {
    margin: 2rem;
    max-width: 450px;
    border-radius: 0.5rem;
  }
  padding: 5rem 0;
  .first ${Text} {
    white-space: nowrap;
    font-size: 3.5rem;
  }
  .second h1 {
    font-size: 1.8rem;
    font-weight: 300;
    margin: 0;
    color: var(--primary);
  }
  .third ${Text} {
    font-size: 1.5rem;
  }
  .first {
    padding: 1rem;
    padding-left: 1rem;
    padding-bottom: 0;
    // width: 50%;
  }
  .second,
  .third {
    align-items: center;
    display: flex;
    font-size: 1rem;
    padding-top: 0.75rem;
    padding: 1rem;
    padding-left: 1rem;
    // width: 50%;
  }
  .second {
    padding-top: 0;
  }
  .third {
    // display: block;
    // text-align: left;
    padding: 0rem;
  }
  ${Group} {
    :first-of-type {
      margin-top: 6rem;
    }
  }

  @media (max-width: 810px) {
    // justify-content: start;
    align-items: flex-start;
    margin-top: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    h3 {
      font-size: 2rem;
    }
    .first {
      text-align: center;
      h1 {
        font-size: 2.2rem;
        text-align: left;
        ${Text} {
          font-size: 2.2rem;
          text-align: left;
          line-height: 2.2rem;
        }
      }
    }
    .second {
      // display: none;
      h3 {
        font-size: 1.2rem;
        text-align: left;
        ${Text} {
          font-size: 1.2rem !important;
        }
      }
    }
    video {
      display: none;
    }
    .second {
      padding-bottom: 1rem;
    }
    .third ${Text} {
      padding-bottom: 1rem;
    }
    .second,
    .third {
      text-align: center;
    }
    .third {
      flex-direction: column;
    }
  }
`;

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  // centerMode: true,
  // centerPadding: "60px",
  rows: 1,
  // variableWidth: true,

  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true
        // centerMode: true,
        // centerPadding: "60px"
      }
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        centerMode: false
        // centerPadding: "60px"
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        arrows: false,
        centerPadding: "60px"
      }
    }
  ]
};

const FAQSection = styled.section`
  background: var(--white);
  padding: 4rem 2rem;
  max-width: 95vw;
  margin: 0 auto;

  h2 {
    color: var(--primary);
    text-align: center;
    margin-bottom: 2rem;
  }

  .faq-item {
    margin-bottom: 2rem;
    border-radius: 0.5rem;
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    h3 {
      color: var(--primary);
      margin-bottom: 1rem;
    }

    p {
      color: var(--secondary);
      line-height: 1.6;
    }

    ol {
      padding-left: 1.5rem;
      margin-top: 0.5rem;
    }

    li {
      margin-bottom: 0.5rem;
      font-weight: 300;
    }
  }
`;

const SellingYourSTR = () => {
  const { getAllCookies } = useCookies();
  const { marketPathParam } = useParams<any>();

  const { mobileSize } = useContext(AppContext);

  const bodyRef = useRef<HTMLDivElement>();

  const cookies: Record<string, string> = {};
  const [listings, setListings] = useState<Listing[]>([]);
  useEffect(() => {
    // Load the data from the imported JSON file
    setListings(
      listingsData.map((listing) => ({
        ...listing,
        title: listing.address,
        location: listing.address,
        annualRevenue: listing.annualRevenue,
        image: listing.images[0],
        sqft: listing.sqft ?? 0, // Provide a default value of 0 if sqft is undefined
        capRate: `${listing.capRate.toFixed(2)}%`
      }))
    );
  }, []);

  getAllCookies().forEach(([cookieName, val]) => {
    cookies[cookieName] = val;
  });

  const titleString = `Sell Your Airbnb Fast ${marketPathParam ? "in" : ""} ${
    LiveMarkets[marketPathParam] || ""
  } | Get the Best Offers`;
  // const descriptionString = `Airbnb Realtors. Find the perfect short-term rental investment in the ${
  //   LiveMarkets[marketPathParam] || ""
  // } market with the help of a local experts. Our top Airbnb realtors
  //  know the market inside and out and can guide you to the best
  //  investment with the highest ROI. Connect with Chalet today`;
  const descriptionString = `Sell your Airbnb rental ${
    marketPathParam ? "in" : ""
  } ${
    LiveMarkets[marketPathParam] || ""
  } with our expert short-term rental agents. Get your listing in front of the largest network of Airbnb rental buyers. Work with Chalet to sell your (Airbnb) short-term rental property fast and for top dollar.`;

  const urlSlug = marketPathParam
    ? `https://www.getchalet.com/sell-your-str/${marketPathParam}`
    : "https://www.getchalet.com/sell-your-str";

  return (
    <>
      <Helmet>
        <link rel="canonical" href={urlSlug} />
        <title>{titleString}</title>
        <meta property="og:type" content="website" />
        <meta name="description" content={descriptionString} />
        <meta property="og:title" content={titleString} />
        <meta property="og:description" content={descriptionString} />
        <meta property="og:image" content="" />
        <meta property="og:url" content={urlSlug} />
        <meta property="og:site_name" content="Chalet" />
      </Helmet>
      <Container ref={bodyRef as any}>
        <div
          style={{
            // maxWidth: mobileSize ? "100%" : "50%",
            paddingRight: mobileSize ? "0" : "4rem"
          }}
        >
          <div className="first">
            <h1 style={{ lineHeight: mobileSize ? "2.6rem" : "4.8rem" }}>
              Real Estate Agents With{" "}
              <Text
                style={{
                  display: "block",
                  // fontSize: mobileSize ? "2rem" : "initial",
                  fontWeight: 400,
                  paddingBottom: mobileSize ? "1rem" : "2rem",
                  whiteSpace: "initial"
                }}
              >
                {" "}
                Expertise in Selling Airbnb Rentals
              </Text>
            </h1>
          </div>
          <div className="second">
            <h3>
              <Text style={{ fontSize: mobileSize ? "2rem" : "1.6rem" }}>
                <strong style={{ color: "var(--quinary)", fontWeight: 400 }}>
                  Your {LiveMarkets[marketPathParam]} Airbnb Rental
                </strong>{" "}
                Sold Fast with Our Top Agents
              </Text>{" "}
              <Text
                style={{
                  color: "var(--primary)",
                  fontWeight: "500",
                  // textDecoration: "underline",
                  fontSize: mobileSize ? "2rem" : "1.6rem"
                }}
              />
              <Text
                // block
                style={{
                  fontSize: mobileSize ? "2rem" : "1.6rem",
                  fontWeight: 500
                  // color: "var(--quaternary)",
                  // background: "var(--primary)",
                  // padding: "1rem",
                  // marginTop: "-1.75rem"
                }}
              >
                {/* {!marketPathParam && "in "}{" "} */}
                {!marketPathParam && <a href="#locations">in top US markets</a>}
              </Text>
            </h3>
          </div>
          <div className="third">
            <PopupButton
              id="lMhk4Fej"
              // open="load"
              // data-tf-lazy="false"
              shareGaInstance="true"
              hidden={{
                market_of_interest:
                  LiveMarkets[marketPathParam] || "Not Specified",
                source_page: urlSlug,
                interested_in: "typeform:selling-your-str",
                start_point: "selling"
              }}
              // data-id="connect-with-realtor"
              style={{
                // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                backgroundColor: mobileSize
                  ? "var(--quinary)"
                  : "var(--quinary)",
                textTransform: "uppercase",
                color: "var(--white)",
                padding: "1rem",
                border: 0,
                fontWeight: 400,
                width: mobileSize ? "100%" : "initial",
                textDecoration: "none",
                display: mobileSize ? "block" : "initial",
                fontSize: "1rem",
                cursor: "pointer",
                textAlign: "center",
                boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                borderRadius: "0.25rem",
                // display: mobileSize ? "block" : "initial",
                margin: mobileSize ? "1rem auto" : "1rem"
              }}
              className="connect-with-realtor_popup_top"
            >
              Sell Your Airbnb Rental Now
            </PopupButton>
            {/* <PopupButton
              id="lMhk4Fej"
              shareGaInstance="true"
              hidden={{
                // market_of_interest: selectedMarket,
                // services: selectedService,
                source_page: "/(relator-page-chat-with-str-expert)"
              }}
              style={{
                marginLeft: mobileSize ? 0 : "1rem",
                background: mobileSize ? "none" : "#dadaeb",
                color: "var(--primary)",
                border: mobileSize ? "none" : "1px solid var(--primary)",
                padding: "1rem",
                textDecoration: mobileSize ? "underline" : "underline",
                fontSize: mobileSize ? "1.2rem" : "1rem",
                width: mobileSize ? "100%" : "initial",
                borderRadius: "0.25rem",
                cursor: "pointer",
                textTransform: "uppercase",
                marginTop: mobileSize ? "-1rem" : "initial"
              }}
              data-id="talk-to-str-expert"
              className="talk-to-str-expert_button_top btn-consult"
            >
              Talk to STR Expert First
            </PopupButton> */}
            {/* <PopupButton
              id="DcwsVbrt"
              // open="load"
              // data-tf-lazy="false"
              shareGaInstance="true"
              hidden={{
                market_of_interest:
                  LiveMarkets[marketPathParam] || "Not Specified",
                source_page: urlSlug
              }}
              // data-id="connect-with-realtor"
              style={{
                // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                backgroundColor: mobileSize
                  ? "var(--primary)"
                  : "var(--primary)",
                textTransform: "uppercase",
                color: "var(--white)",
                padding: "1rem",
                border: 0,
                width: mobileSize ? "100%" : "initial",
                textDecoration: "none",
                display: mobileSize ? "block" : "initial",
                fontSize: "1rem",
                cursor: "pointer",
                textAlign: "center",
                boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                borderRadius: "0.25rem",
                // display: mobileSize ? "block" : "initial",
                margin: mobileSize ? "1rem auto" : "1rem"
              }}
              className="connect-with-realtor_popup_top"
            >
              Talk To A STR Expert First
            </PopupButton> */}
            {/* <a
              style={{
                // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                backgroundColor: mobileSize
                  ? "var(--primary)"
                  : "var(--primary)",
                textTransform: "uppercase",
                color: "var(--white)",
                padding: "1rem",
                width: mobileSize ? "100%" : "initial",
                textDecoration: "none",
                fontSize: "1rem",

                cursor: "pointer",
                display: mobileSize ? "block" : "initial",
                textAlign: "center",
                boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                borderRadius: "0.25rem",
                // display: mobileSize ? "block" : "initial",
                margin: mobileSize ? "0rem auto" : "1rem"
              }}
              data-id="learn-more_button_top"
              href="#section1"
              title="/buy"
            >
              Learn More
            </a> */}
          </div>
          <div
            className="fourth"
            style={{
              display: mobileSize ? "none" : "none",
              marginTop: "5rem"
            }}
          >
            <h3 style={{ color: "var(--white)", textAlign: "center" }}>
              Buy
              <Text
                style={{
                  fontSize: mobileSize ? "2rem" : "1.6rem",
                  fontWeight: 400,
                  color: "var(--white)"
                }}
              >
                &nbsp;the best Short-Term Rental investments
                <Text
                  style={{
                    fontSize: mobileSize ? "2rem" : "1.6rem",
                    textAlign: "center"
                  }}
                >
                  {marketPathParam && " in"}
                </Text>{" "}
                <Text
                  style={{
                    color: "var(--white)",
                    fontWeight: "500",
                    // textDecoration: "underline",
                    textAlign: "center",
                    fontSize: mobileSize ? "2rem" : "1.6rem"
                  }}
                >
                  {LiveMarkets[marketPathParam]}
                </Text>
              </Text>
              &nbsp;with top real estate agents
              <Text
                // block
                style={{
                  fontSize: mobileSize ? "2rem" : "1.6rem",
                  fontWeight: 500,
                  color: "var(--white)",
                  textAlign: "center"
                  // background: "var(--primary)",
                  // padding: "1rem",
                  // marginTop: "-1.75rem"
                }}
              >
                {!marketPathParam && " in "}{" "}
                {!marketPathParam && (
                  <a
                    style={{
                      textAlign: "center",
                      color: "var(--quaternary)",
                      background: "rgba(255,255,255,0.3)",
                      padding: "0.5rem",
                      marginTop: "0.2rem",
                      borderRadius: "0.5rem",
                      display: "block"
                    }}
                    href="#locations"
                  >
                    most US markets
                  </a>
                )}
              </Text>
            </h3>
          </div>
        </div>
        <div style={{ display: "block", maxWidth: "50%" }}>
          <Image
            src={`${process.env.PUBLIC_URL}/images/partner-realtor-musculine.png`}
            alt="Chalet Partner Real Estate Agents With STR Investing Expertise"
            $mobileSize={mobileSize}
            style={{
              gridRowStart: mobileSize ? "1" : "inherit",
              maxWidth: mobileSize ? "100%" : "300px",
              display: mobileSize ? "none" : "block",
              borderRadius: "0.25rem"
            }}
          />
        </div>
      </Container>
      <Group id="howitworks">
        <Section
          style={{
            boxShadow: mobileSize ? "none" : "inherit"
            // maxWidth: "100%"
          }}
        >
          <Header size={2}>How It Works</Header>
          <Flex
            style={{
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: "1rem"
            }}
          >
            {[
              // {
              //   icon: "analyze-your-property.webp",
              //   heading: "1. Analyze Market Readiness",
              //   subheading: (
              //     <>
              //       Use our{" "}
              //       <Link to="/analytics/str-calculator" target="_blank">
              //         AI tools
              //       </Link>{" "}
              //       to assess if it&apos;s the right time to sell and highlight
              //       key aspects of your property.
              //     </>
              //   )
              // },
              {
                icon: "match-with-chalet-realtor.webp",
                heading: "1. Match with an STR Realtor",
                subheading: `We connect you with a top-rated realtor specializing in short-term rentals ${
                  marketPathParam ? "in" : "in your area"
                } ${LiveMarkets[marketPathParam] || ""}.`
              },
              {
                icon: "maximize-listing-views.webp",
                heading: "2. Maximize Exposure",
                subheading: (
                  <>
                    Get free promotion across{" "}
                    <Link to="/airbnbs-for-sale" target="_blank">
                      our platform
                    </Link>{" "}
                    social media, and our large network of STR investors.
                  </>
                )
              },
              {
                icon: "acquisition.webp",
                heading: "3. Attract Buyers",
                subheading: `Targeted exposure ensures your property reaches qualified buyers ${
                  marketPathParam ? "in" : ""
                } ${LiveMarkets[marketPathParam] || ""}.`
              },
              {
                icon: "offer.webp",
                heading: "4. Reinvest",
                subheading:
                  "Looking to reinvest? We'll help you with 1031 exchanges and your next STR purchase."
              }
            ].map((card) => (
              <div
                key={card.heading}
                style={{
                  flex: mobileSize ? "1 1 300px" : "1 1 200px",
                  maxWidth: "400px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Card
                  icon={card.icon}
                  heading={card.heading}
                  subheading={card.subheading}
                />
              </div>
            ))}
          </Flex>
          <SectionFooter $mobileSize={mobileSize}>
            <PopupButton
              id="ih3Ukxa2"
              // open="load"
              // data-tf-lazy="false"
              shareGaInstance="true"
              hidden={{
                market_of_interest:
                  LiveMarkets[marketPathParam] || "Not Specified",
                source_page: urlSlug,
                device_type: mobileSize ? "mobile" : "desktop",
                interested_in: "typeform:selling-your-str"
              }}
              // data-id="connect-with-realtor"
              style={{
                // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                background: mobileSize
                  ? "var(--linearGradient)"
                  : "var(--linearGradient)",
                textTransform: "uppercase",
                color: "var(--white)",
                padding: "1rem 4rem",
                border: 0,
                fontWeight: 400,
                width: mobileSize ? "100%" : "initial",
                textDecoration: "none",
                display: mobileSize ? "block" : "block",
                fontSize: "1rem",
                cursor: "pointer",
                textAlign: "center",
                boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                borderRadius: "0.25rem",
                // display: mobileSize ? "block" : "initial",
                margin: mobileSize ? "1rem auto" : "1rem auto"
              }}
              className="connect-with-realtor_popup_top"
            >
              Sell Your{" "}
              {marketPathParam
                ? LiveMarkets[marketPathParam].split(",")[0]
                : ""}{" "}
              Airbnb Rental Now
            </PopupButton>
          </SectionFooter>
        </Section>
      </Group>
      <Section
        id="home-statistics"
        style={{
          // backgroundImage: `url('${process.env.PUBLIC_URL}/images/radial-gradient.webp')`,
          background: "var(--sixth)",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat"
        }}
      >
        <div className="stats">
          <span id="alt-red">Connected Investors With</span>
          <span id="highlight">100M+</span>
          <span id="alt-black">in Short-Term Rental Assets</span>
        </div>
        <VerticalDivider name="verdiv" />
        <HorizontalDivider name="hordiv" />
        <div className="stats">
          <span id="alt-black">Short-Term Rental Partners</span>
          <span id="highlight">All 50 states</span>
          <span id="alt-black">200+ Markets</span>
        </div>
        <VerticalDivider name="verdiv" />
        <HorizontalDivider name="hordiv" />
        <div className="stats">
          <span id="alt-red">Analyzing</span>
          <span id="highlight">1M+</span>
          <span id="alt-red">short-term rentals</span>
        </div>
      </Section>
      <Group id="section1">
        <Section
          style={{
            boxShadow: mobileSize ? "none" : "inherit",
            maxWidth: "100%"
          }}
        >
          <Header size={2}>Selling Your Short-Term Rental Property</Header>

          <SplitView
            split="2:3"
            style={{
              alignItems: "start",
              maxWidth: mobileSize ? "100%" : "85vw",
              margin: "0 auto"
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "right",
                gridRowStart: mobileSize ? "1" : "inherit"
              }}
            >
              <Image
                src={`${process.env.PUBLIC_URL}/images/invest-with-experts.webp`}
                alt="Buy your Airbnb rental with Chalet"
                style={{
                  borderRadius: "0.25rem",
                  maxWidth: mobileSize ? "100%" : "300px",
                  width: "100%"
                }}
                $mobileSize={mobileSize}
              />
            </div>
            <div
              style={{
                gridRowStart: mobileSize ? "2" : "inherit"
              }}
            >
              <Text
                block
                align="left"

                // style={{ lineHeight: "2rem" }}
                // lineHeight="4.5rem"
              >
                As the vacation rental market has evolved, so have the needs and
                strategies for selling a short-term rental property{" "}
                {marketPathParam && "in"} {LiveMarkets[marketPathParam]}
              </Text>
              <Text block align="left">
                With Chalet, you&apos;re not just selling a property you&apos;re
                selling a business. Our extensive network of vetted short-term
                rental specialists spans over 100 markets in the US, ensuring
                that you find the right Realtor to maximize your property&apos;s
                value.
              </Text>
              {marketPathParam && (
                <a
                  href={`/investor-guides/${marketPathParam}/`}
                  target="_blank"
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "var(--quinary)"
                  }}
                  rel="noreferrer"
                >
                  Read our Investor Guide To Airbnb Rentals In{" "}
                  {LiveMarkets[marketPathParam]}
                </a>
                // <Text
                //   align="left"
                //   style={{
                //     cursor: "pointer",
                //     textDecoration: "underline",
                //     color: "var(--quinary)"
                //   }}
                //   onClick={handleInvestorGuideClick}
                // >
                //   Read our Investor Guide To Airbnb Rentals In{" "}
                //   {LiveMarkets[marketPathParam]}
                // </Text>
              )}
            </div>
          </SplitView>
          <SectionFooter $mobileSize={mobileSize} />
        </Section>
      </Group>
      <Group
        style={{
          background: `url("${process.env.PUBLIC_URL}/images/blue-fade.webp")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover"
        }}
      >
        <Section
          style={{
            maxWidth: "100%",
            background: "none",
            boxShadow: "none"
          }}
        >
          <Header
            // style={{ fontWeight: 400, color: "var(--white)" }}
            size={2}
            color={Color.primary}
          >
            Our Results
          </Header>
          <SplitView
            split="2:3"
            style={{
              //   alignItems: "start",
              maxWidth: mobileSize ? "100%" : "95vw",
              margin: "0 auto",
              alignItems: "center"
              // margin: "0 auto"
            }}
          >
            <div
              style={{
                gridRowStart: mobileSize ? "2" : "inherit",
                paddingBottom: mobileSize ? "inherit" : "0",
                paddingTop: mobileSize ? "inherit" : "0"
              }}
            >
              <Text
                block
                style={{ fontSize: "1.4rem" }}
                align="left"
                color={Color.primary}
              >
                With a proven track record of quickly matching sellers with
                qualified buyers, Chalet helps properties close faster and at
                competitive prices.
              </Text>
              <HR $color={Color.quaternary} />
              <Text
                block
                style={{ fontSize: "1.4rem" }}
                align="left"
                color={Color.primary}
              >
                We&apos;ve partnered with short-term rental experts in over{" "}
                <span className="bold" style={{ fontWeight: 500 }}>
                  200 markets
                </span>{" "}
                across all{" "}
                <span className="bold" style={{ fontWeight: 500 }}>
                  50 states
                </span>
                , analyzing more than{" "}
                <span className="bold" style={{ fontWeight: 500 }}>
                  1 million rentals{" "}
                </span>
                and connecting investors with over{" "}
                <span className="bold" style={{ fontWeight: 500 }}>
                  $100 million in STR assets
                </span>
                , making Chalet the{" "}
                <span className="bold" style={{ fontWeight: 500 }}>
                  #1 partner for selling your rental property
                </span>
                .
              </Text>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: mobileSize ? "center" : "left",
                gridRowStart: mobileSize ? "1" : "inherit"
              }}
            >
              <Image
                src={`${process.env.PUBLIC_URL}/images/our-results.webp`}
                alt="$100M in STR Assets Connected with Investors, 1M+ Short-Term Rentals Analyzed, 200+ Airbnb Rental Markets"
                style={{
                  padding: "0rem",
                  borderRadius: "0.25rem",
                  maxWidth: mobileSize ? "100%" : "100%",
                  width: "100%"
                }}
                $mobileSize={mobileSize}
              />
            </div>
          </SplitView>
          <SectionFooter $mobileSize={mobileSize}>
            <PopupButton
              id="ih3Ukxa2"
              // open="load"
              // data-tf-lazy="false"
              shareGaInstance="true"
              hidden={{
                market_of_interest:
                  LiveMarkets[marketPathParam] || "Not Specified",
                source_page: urlSlug,
                device_type: mobileSize ? "mobile" : "desktop",
                interested_in: "typeform:selling-your-str"
              }}
              // data-id="connect-with-realtor"
              style={{
                // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                background: mobileSize
                  ? "var(--linearGradient)"
                  : "var(--linearGradient)",
                textTransform: "uppercase",
                color: "var(--white)",
                padding: "1rem 4rem",
                border: 0,
                fontWeight: 400,
                width: mobileSize ? "100%" : "initial",
                textDecoration: "none",
                display: mobileSize ? "block" : "block",
                fontSize: "1rem",
                cursor: "pointer",
                textAlign: "center",
                boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                borderRadius: "0.25rem",
                // display: mobileSize ? "block" : "initial",
                margin: mobileSize ? "1rem auto" : "1rem auto"
              }}
              className="connect-with-realtor_popup_top"
            >
              Get Started Now
            </PopupButton>
          </SectionFooter>
        </Section>
      </Group>
      <Group
        style={{
          // background:
          //   "radial-gradient(4291.36% 46161.46% at 2886.22% -7976.07%, #DB005F 36.46%, #2F354B 67.71%, #FB929D 71.35%)",
          // background: "var(--radialGradient)",
          background: "var(--sixth)",
          maxWidth: "100%"
        }}
      >
        <Section
          style={{
            boxShadow: "none",
            background: "none",
            maxWidth: "100%"
          }}
        >
          <Header size={2} color={Color.white}>
            List Your Short-Term Rental with Proven STR Experts
          </Header>
          <SplitView
            split="2:1"
            style={{
              alignItems: "start",
              maxWidth: mobileSize ? "100%" : "85vw",
              margin: "0 auto"
            }}
          >
            <div
              style={{
                gridRowStart: mobileSize ? "2" : "inherit"
              }}
            >
              <Text
                block
                align="left"
                style={{ lineHeight: "2.5rem" }}
                color={Color.white}
              >
                Understanding the local short-term rental market and regulations
                can be crucial when selling your property. Our agents are
                equipped to guide you through these complexities. Whether
                you&apos;re navigating market valuations, disclosure
                requirements, or tax implications of selling, our agents provide
                the expertise you need for a smooth and profitable sale
                {marketPathParam && "in"} {LiveMarkets[marketPathParam]}.
              </Text>
            </div>
            <Image
              src={`${process.env.PUBLIC_URL}/images/1031-exchange.webp`}
              alt="Local STR Experts With a Proven Track Record"
              $mobileSize={mobileSize}
              style={{
                display: "flex",
                gridRowStart: mobileSize ? "1" : "inherit",
                justifyContent: "right",
                margin: "0 auto",
                maxWidth: mobileSize ? "100vw" : "300px"
              }}
            />
          </SplitView>
          <SectionFooter $mobileSize={mobileSize} />
        </Section>
      </Group>
      <Group>
        <Section
          style={{
            boxShadow: mobileSize ? "none" : "inherit"
            // maxWidth: "100%"
          }}
        >
          <Header size={2}>
            Feature Your STR on Our Airbnb For Sale Platform
          </Header>
          <Text align="center" block>
            One of the key benefits of partnering with Chalet is exclusive
            access to our Airbnb For Sale platform. This section of our website
            is dedicated solely to active short-term rental properties,
            providing targeted exposure to serious STR investors.
          </Text>

          <Flex
            style={{
              flexWrap: mobileSize ? "wrap" : "nowrap",
              justifyContent: "space-between",
              gap: "0.5rem"
            }}
          >
            {listings.slice(0, 4).map((listing) => (
              <ListingCard key={listing.id} listing={listing} />
            ))}
          </Flex>
          <Text
            style={{ marginTop: "2rem", fontSize: "1.4rem" }}
            align="center"
            block
          >
            Every visitor to GetChalet.com has access to this platform, allowing
            us to connect your property directly with qualified buyers looking
            to invest in short-term rentals.
          </Text>
          <PopupButton
            id="ih3Ukxa2"
            // open="load"
            // data-tf-lazy="false"
            shareGaInstance="true"
            hidden={{
              market_of_interest:
                LiveMarkets[marketPathParam] || "Not Specified",
              source_page: urlSlug,
              device_type: mobileSize ? "mobile" : "desktop",
              interested_in: "typeform:selling-your-str"
            }}
            // data-id="connect-with-realtor"
            style={{
              // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
              background: mobileSize
                ? "var(--linearGradient)"
                : "var(--linearGradient)",
              textTransform: "uppercase",
              color: "var(--white)",
              padding: "1rem 4rem",
              border: 0,
              fontWeight: 400,
              width: mobileSize ? "100%" : "initial",
              textDecoration: "none",
              display: mobileSize ? "block" : "block",
              fontSize: "1rem",
              cursor: "pointer",
              textAlign: "center",
              boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
              borderRadius: "0.25rem",
              // display: mobileSize ? "block" : "initial",
              margin: mobileSize ? "1rem auto" : "1rem auto"
            }}
            className="connect-with-realtor_popup_top"
          >
            Sell Your{" "}
            {marketPathParam ? LiveMarkets[marketPathParam].split(",")[0] : ""}{" "}
            Airbnb Rental Now
          </PopupButton>
        </Section>
        <HR $color={Color.primary} />
      </Group>
      <Group
        style={{
          //   paddingBottom: "0rem",
          maxWidth: "100%",
          //   paddingTop: "0rem",
          //   backgroundImage: `url("${process.env.PUBLIC_URL}/images/market-bg.webp")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover"
        }}
      >
        <Section
          style={{
            boxShadow: "none",
            // maxWidth: "100%",
            background: "none",
            marginBottom: mobileSize ? "initial" : "4rem"
          }}
        >
          <Header
            size={2}
            style={{ marginBottom: "0rem", paddingBottom: "0rem" }}
          >
            What Our Partners Are Saying About Chalet
          </Header>
          <Header
            size={3}
            color={Color.eighth}
            style={{ display: mobileSize ? "none" : "block", paddingTop: 0 }}
          >
            Here&apos;s what recent clients and realtors have to say about their
            experiences working with Chalet
          </Header>
          <Flex
            style={{
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: "4rem",
              marginTop: "2rem"
            }}
          >
            <div
              style={{
                flex: "1 1 200px",
                maxWidth: "400px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Image
                src={`${process.env.PUBLIC_URL}/images/review-1.webp`}
                alt="Review from Jimmy O, Short-term rental Owner"
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "8px"
                }}
              />
            </div>
            <div
              style={{
                flex: "1 1 200px",
                maxWidth: "400px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Image
                src={`${process.env.PUBLIC_URL}/images/review-2.webp`}
                alt="Review from Ben S, STR Investor"
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "8px"
                }}
              />
            </div>
            <div
              style={{
                flex: "1 1 200px",
                maxWidth: "400px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Image
                src={`${process.env.PUBLIC_URL}/images/review-3.webp`}
                alt="Review from Justin B, STR Investor"
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "8px"
                }}
              />
            </div>
          </Flex>
          <SectionFooter $mobileSize={mobileSize}>
            <PopupButton
              id="ih3Ukxa2"
              // open="load"
              // data-tf-lazy="false"
              shareGaInstance="true"
              hidden={{
                market_of_interest:
                  LiveMarkets[marketPathParam] || "Not Specified",
                source_page: urlSlug,
                device_type: mobileSize ? "mobile" : "desktop",
                interested_in: "typeform:selling-your-str"
              }}
              // data-id="connect-with-realtor"
              style={{
                // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                background: mobileSize
                  ? "var(--linearGradient)"
                  : "var(--linearGradient)",
                textTransform: "uppercase",
                color: "var(--white)",
                padding: "1rem 4rem",
                border: 0,
                fontWeight: 400,
                width: mobileSize ? "100%" : "initial",
                textDecoration: "none",
                display: mobileSize ? "block" : "block",
                fontSize: "1rem",
                cursor: "pointer",
                textAlign: "center",
                boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                borderRadius: "0.25rem",
                // display: mobileSize ? "block" : "initial",
                margin: mobileSize ? "1rem auto" : "1rem auto"
              }}
              className="connect-with-realtor_popup_top"
            >
              Get Started Now
            </PopupButton>
          </SectionFooter>
        </Section>
      </Group>
      {/* <Group style={{ background: "var(--primary)" }}>
        <Section
          style={{
            maxWidth: "100%",
            background: "none",
            boxShadow: "none"
          }}
        >
          <Header
            // style={{ fontWeight: 400, color: "var(--white)" }}
            size={2}
            color={Color.white}
          >
            Market to Other Investors
          </Header>
          <SplitView
            split="2:1"
            style={{
              alignItems: "start",
              maxWidth: mobileSize ? "100%" : "85vw",
              margin: "0 auto"
              // margin: "0 auto"
            }}
          >
            <div
              style={{
                gridRowStart: mobileSize ? "2" : "inherit",
                paddingBottom: mobileSize ? "inherit" : "0",
                paddingTop: mobileSize ? "inherit" : "0"
              }}
            >
              <Text block align="left" color={Color.white}>
                There are numerous platforms where short-term rental deals can
                be promoted specifically to investors in the{" "}
                {LiveMarkets[marketPathParam]} market. There are still many
                investors hungry for the right opportunity. A strong Realtor
                will understand this and meet investors where they are at.
              </Text>
              <Text block align="left" color={Color.white}>
                Deciding to sell your short-term rental property is a
                significant decision, but you don&apos;t have to navigate it
                alone. Chalet connects you with the best Realtors who specialize
                in short-term rentals, ensuring you get the most out of your
                investment. Contact us today to find the perfect agent in your
                market and take the first step toward a successful sale.
              </Text>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: mobileSize ? "center" : "left",
                gridRowStart: mobileSize ? "1" : "inherit"
              }}
            >
              <Image
                src={`${process.env.PUBLIC_URL}/images/chalet-partner-vendors.webp`}
                alt="Airbnb Rental Investor Guides. Free Market Reports. Median Revenue, ADR, Occupacny rates"
                style={{
                  padding: "0rem",
                  borderRadius: "0.25rem",
                  maxWidth: mobileSize ? "100%" : "300px",
                  width: "100%"
                }}
                $mobileSize={mobileSize}
              />
            </div>
          </SplitView>
          <SectionFooter $mobileSize={mobileSize} />
        </Section>
      </Group> */}
      <Group
        style={{
          position: "relative"
        }}
      >
        <Section
          style={{
            maxWidth: "100%",
            background: "var(--sixth)",
            boxShadow: "none"
          }}
        >
          <Header
            // style={{ fontWeight: 400, color: "var(--white)" }}
            size={2}
            color={Color.white}
            style={{ zIndex: 200, position: "relative" }}
          >
            Connect with a Short-Term Rental Seller Agent Now
          </Header>
          {/* <Image
            src={`${process.env.PUBLIC_URL}/images/vacation_home.webp`}
            alt="Airbnb Rental Investor Guides. Free Market Reports. Median Revenue, ADR, Occupacny rates"
            style={{
              padding: "0rem",
              position: "absolute",
              left: 0,
              top: 0,
              opacity: "0.1",
              // zIndex: "0",
              // right: 0,
              // borderRadius: "2rem",
              maxWidth: mobileSize ? "100%" : "100%",
              width: "100%"
            }}
            $mobileSize={mobileSize}
          /> */}
          <div
            style={{
              gridRowStart: mobileSize ? "2" : "inherit",
              paddingBottom: mobileSize ? "inherit" : "0",
              paddingTop: mobileSize ? "inherit" : "0",
              position: "relative",
              zIndex: 1
            }}
          >
            <Text block align="center" color={Color.white}>
              Instantly connect with an Airbnb real estate agent and start
              realizing your investment goals in the{" "}
              {LiveMarkets[marketPathParam]} short-term rental market.
            </Text>
            <PopupButton
              id="ih3Ukxa2"
              hidden={{
                market_of_interest:
                  LiveMarkets[marketPathParam] || "Not Specified",
                source_page: urlSlug,
                interested_in: "typeform:selling-your-str",
                device_type: mobileSize ? "mobile" : "desktop"
              }}
              shareGaInstance="true"
              className="connect-with-realtor_popup_bottom"
              style={{
                // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                // backgroundColor: "var(--primary)",
                background: "var(--white)",
                textTransform: "uppercase",
                display: "block",
                color: "var(--quinary)",
                padding: "1rem",
                border: 0,
                width: mobileSize ? "100%" : "initial",
                textDecoration: "none",
                // display: mobileSize ? "block" : "initial",
                fontSize: "1rem",
                cursor: "pointer",
                textAlign: "center",
                boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                borderRadius: "0.25rem",
                // display: mobileSize ? "block" : "initial",
                margin: mobileSize ? "1rem auto" : "1rem auto"
              }}
            >
              Connect With An Airbnb Realtor
            </PopupButton>
          </div>
        </Section>
      </Group>
      <Group
        style={{
          background: `url("${process.env.PUBLIC_URL}/images/markets-bg.webp")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover"
        }}
      >
        <Section
          style={{
            // maxWidth: "100%",
            background: "none",
            boxShadow: "none"
          }}
        >
          <Header
            // style={{ fontWeight: 400, color: "var(--white)" }}
            size={2}
            color={Color.primary}
          >
            Additional Resources for Sellers
          </Header>
          <Header size={3} style={{ paddingTop: 0 }} color={Color.primary}>
            Looking to sell your short-term rental? Chalet offers a suite of
            resources to help you every step of the way.
          </Header>
          <List
            type="disclosure-closed"
            items={[
              <Link
                to="/blog/the-ultimate-guide-to-selling-your-short-term-rental/"
                target="_blank"
              >
                Guide to Selling STR
              </Link>,
              <Link
                to="/blog/looking-to-sell-heres-10-ways-to-maximize-views-to-your-listing-and-improve-the-quality-of-your-buyer-leads/"
                target="_blank"
              >
                Looking to sell? Here&apos;s how to maximize views to your
                listing and guarantee qualified buyers
              </Link>,
              <Link
                to="/blog/the-fed-cut-interest-rates-by-0-5-heres-what-it-means-for-airbnb-sellers/"
                target="_blank"
              >
                The fed cut interest rates by 0.5%! Here&apos;s what it means
                for sellers
              </Link>
            ]}
          />
          <SectionFooter $mobileSize={mobileSize} />
        </Section>
      </Group>
      <FAQSection>
        <Header size={2}>Frequently Asked Questions</Header>
        <div className="faq-item">
          <Header size={3}>
            🔄 How does our process work {marketPathParam ? " in " : ""}
            {LiveMarkets[marketPathParam] || ""}?
          </Header>
          <Text style={{ fontSize: "1.2rem" }}>
            Our process is simple and effective, consisting of 3 main steps:
          </Text>
          <ol>
            <li style={{ fontSize: "1.2rem" }}>
              List your Airbnb and get qualified views from Chalet&apos;s
              audience
            </li>
            <li style={{ fontSize: "1.2rem" }}>
              Partner with an STR expert realtor to find qualified buyers{" "}
              {marketPathParam ? " in " : ""}
              {LiveMarkets[marketPathParam] || ""}.
            </li>
            <li style={{ fontSize: "1.2rem" }}>Close quickly and earn $$$$</li>
          </ol>
        </div>
        <div className="faq-item">
          <Header size={3}>
            🤝 Why should they trust us sell your rental
            {marketPathParam ? " in " : ""}
            {LiveMarkets[marketPathParam] || ""}?
          </Header>
          <Text style={{ fontSize: "1.2rem" }}>
            Our track record speaks for itself. We&apos;ve helped numerous
            property owners successfully sell their short-term rentals.
            Don&apos;t just take our word for it - check out our testimonials
            from satisfied clients who have experienced our expertise firsthand.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={3}>
            💰 How much does it cost to sell a rental
            {marketPathParam ? " in " : ""}
            {LiveMarkets[marketPathParam] || ""}?
          </Header>
          <Text style={{ fontSize: "1.2rem" }}>
            It is completely free to use our service. We make money through
            referrals to our partner real estate agents, so there&apos;s no cost
            to you for connecting with our expert STR realtors.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={3}>
            ⏱️ How long does it take to sell a rental
            {marketPathParam ? " in " : ""}
            {LiveMarkets[marketPathParam] || ""}?
          </Header>
          <Text style={{ fontSize: "1.2rem" }}>
            On average, it usually takes 30-60 days to sell a short-term rental
            property. However, this can vary depending on market conditions and
            the specific characteristics of your property.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={3}>
            🏠 What makes Chalet&apos;s agents different from regular real
            estate agents?
          </Header>
          <Text style={{ fontSize: "1.2rem" }}>
            Our agents specialize in short-term rental properties. They
            understand the unique aspects of valuing and marketing these
            properties, including factors like rental income potential and local
            STR regulations. This expertise allows them to maximize the value of
            your property and find qualified buyers who understand the STR
            market.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={3}>
            📅 Is there an optimal time to sell my short-term rental{" "}
            {marketPathParam ? "in " : ""}
            {LiveMarkets[marketPathParam] || ""}?
          </Header>
          <Text style={{ fontSize: "1.2rem" }}>
            The best time to sell can vary depending on your location and local
            market conditions. Generally, it&apos;s advantageous to list your
            property as early in the peak season as possible. This allows
            potential buyers to see the property&apos;s full earning potential.
            Our expert agents can help you determine the optimal timing for your
            specific situation.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={3}>
            📣 How do you market my property to other investors?
          </Header>
          <Text block>
            We use a multi-channel approach to ensure your property gets maximum
            exposure to the right audience. We share your listing with one of
            the largest proprietary email lists of Airbnb rental investors in
            the country.
          </Text>
          <Text block>
            Additionally, your property will be featured on our dedicated
            section for{" "}
            <a
              href="/airbnbs-for-sale"
              target="_blank"
              rel="noopener noreferrer"
            >
              Airbnb properties for sale on our website
            </a>
            . We also promote the listing through targeted social media
            campaigns and collaborate with our network of experienced real
            estate agents and their partners to find interested buyers.
          </Text>
          <Text block>
            This version flows smoothly and emphasizes the different channels
            you&apos;re using to reach potential buyers.
          </Text>
        </div>
      </FAQSection>
    </>
  );
};

export default SellingYourSTR;
